import { render, staticRenderFns } from "./MainBookCompPSList.vue?vue&type=template&id=66b77c8b&scoped=true"
import script from "./MainBookCompPSList.vue?vue&type=script&lang=js"
export * from "./MainBookCompPSList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b77c8b",
  null
  
)

export default component.exports