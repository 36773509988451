<template>
 <v-container fluid grid-list-md>
    <v-row no-gutters>
      <v-col xs="12" sm="12" md="8" lg="8" ex="8">
        <v-card ref="form">
          <v-card-text>

              <v-row no-gutters>
                <v-flex xs="12" class="py-0 px-2">                
                    <h2>{{lang.MainLedgerAnalysis}} </h2>
                </v-flex>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.CompanyName}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{$store.state.companyName}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.BusinessYear}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainledgerAna.BusinessYear}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                  {{lang.User}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{ this.$store.getters.NeedFullUser }}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <br>

          <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" >
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-2" >
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
 
                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2"  >
                  <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Mjesec"
                  clearable
                ></v-select>
              </v-col>

          </v-row>           

            <v-row no-gutters>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0">
                  {{lang.DocumentType}}
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                <v-select :label="lang.DocumentType" :items="privitems" v-model="mainledgerAna.bookTypeSelected" value="mainledgerAna.bookTypeSelected" clearable></v-select>
              </v-col>
            </v-row>
             <v-row no-gutters>
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0">
                    {{langC.Partner}}
                  </v-col>
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="py-0">
                     <v-autocomplete
                      :items="partners"
                      v-model="selectPa"
                      :label="lang.SelectPartner"
                      single-line
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                  </v-col>
              </v-row>

              <div class="pink darken-2" v-html="error" />

          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>
            <v-btn
              class="primary"
              @click="vatstatements">
              {{lang.VATStatement}}
            </v-btn>
            <v-btn
              class="primary"
              @click="test">
              {{lang.TestInvoice}}
            </v-btn>
            <v-btn
              class="primary"
              @click="openStatement(1)">
              {{lang.OpenStateSupp2}}
            </v-btn>
            <v-btn
              class="primary"
              @click="openStatement(2)">
              {{lang.OpenStateBuyers2}}
            </v-btn>
            <v-spacer></v-spacer>
             <v-btn
              class="primary"
              @click="reset">
              Reset
            </v-btn>
            <v-btn
              class="primary"
              @click="register">
              {{langC.Analysis}}
            </v-btn>

            <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
            ></v-progress-circular>

          </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" sm="10" md="6" lg="6" ex="6">
        <v-row v-if="showCard5">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <MainLedgerLatest />
          </v-col>
        </v-row>
       </v-col>
    </v-row>

    <div v-if="this.stateBooks">
      <v-row class="pt-2">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <MainLedgerBookIn :mainledgerAna="mainledgerAna" :source="1" />
        </v-col>
      </v-row>
    </div>
    <div v-if="this.stateOutBooks">
      <v-row class="pt-2">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <MainLedgerBookOut :mainledgerAna="mainledgerAna" :source="1" />
        </v-col>
      </v-row>
    </div>
    <div v-if="this.testinvoice">
      <v-row class="pt-2">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <MainLedgerCard6 />
        </v-col>
      </v-row>
    </div>
   
     <v-row v-if="showCard5">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerCard5 :items="pItems" :head="mainledgerAna"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import MainLedgerBookIn from './MainLedgerBookIn'
import MainLedgerBookOut from './MainLedgerBookOut'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerCard5 from './MainLedgerCard5'
import MainLedgerCard6 from './MainLedgerCard6'
import MainLedgerLatest from './MainLedgerLatest'
import dateFormat from '@/snippets/dateFormat'
import { testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerreports',
  data () {
    return {
      comp: {},
      startDate: null,
      endDate: null,
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      account: { },
      error: null,
      localError: false,
      privGroups: [],
      saving: false,
      lang: {},
      langC: {},
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      mainledgerAna: {},
      privitems: [],
      partners: [],
      selectPa: {},
      rules: {
        required: value => !!value || '*'
      },
      required: (value) => !!value || 'Required.',
      stateBooks: false,
      stateOutBooks: false,
      testinvoice: false,
      showCard5: false,
      pItems: [],
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      selectedMonth: null,
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.comp = this.$store.state.companyActiv
    this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
    this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
    
    this.privitems = []
    if (this.$store.state.booktype) {
      this.privitems = this.$store.state.booktype.filter(book => {
            return book.side < 3
          })
    }
    this.startDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    
    //this.partners = (await PartnerService.index()).data.partners
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async vatstatements () {
      this.$store.dispatch('seteditItem', 0)
      await this.$router.push({
        name: 'mainledgervat'
      })
    },
    print () {
      this.$htmlToPaper('printArea')
    },
    reset() {
      if (parseInt(this.mainledgerAna.bookTypeSelected) < 10) {          
        this.stateBooks = !this.stateBooks
      }
      if (parseInt(this.mainledgerAna.bookTypeSelected) > 9) {          
        this.stateOutBooks = !this.stateOutBooks
      }
      this.startDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      this.mainledgerAna.partnerId = null
      this.mainledgerAna.bookTypeSelected = null
      this.$store.dispatch('setSearchState', null)
      this.stateOutBooks = false
      this.testinvoice = false
      this.stateBooks = false
      this.showCard5 = false
      this.pItems = []
    },
    test() {
      this.testinvoice = !this.testinvoice
    },
    async register () {
      try {
        // do analysis from database
        this.localError = false
        this.mainledgerAna.partnerId = null
        if (this.selectPa.id) {
          this.mainledgerAna.partnerId = this.selectPa.id
        }
        if (this.startDate) {
          this.mainledgerAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.mainledgerAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
       
        if (parseInt(this.mainledgerAna.bookTypeSelected) < 10) {          
          this.stateBooks = true
        }
        if (parseInt(this.mainledgerAna.bookTypeSelected) > 9) {          
          this.stateOutBooks = true
        }
  
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
        this.stateBooks = false
      }
    },
    async openStatement (type) {
      try {
        this.showCard5 = false
        this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
        this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
        this.mainledgerAna.type = 5
        this.mainledgerAna.subtype = 5

        if (type === 1) {
          this.mainledgerAna.fromAccount = '2200'
          this.mainledgerAna.toAccount = '2200'
        }
        if (type === 2) {
          this.mainledgerAna.fromAccount = '1200'
          this.mainledgerAna.toAccount = '1200'
        }
        if (this.startDate) {
          this.mainledgerAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.mainledgerAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        this.$store.dispatch('setSearchState', this.mainledgerAna)
        this.saving = true
        await MainLedgerDetailService.card(this.mainledgerAna)
        .then(res => {
          this.pItems = res.data.documents ? res.data.documents : []
          this.showCard5 = true
        })
        .catch(err => {
           console.log('Error ', err)
           this.error = 'Error  ' + err
        })
        this.saving = false
  
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
        this.stateBooks = false
      }
    },

  },
  computed: {
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  watch: {
    'mainledgerAna.bookTypeSelected'() {
      if (!this.mainledgerAna.bookTypeSelected) {
        this.stateBooks = null
        this.stateOutBooks = null
      }
    },
    'selectedMonth' () {
     if  (this.selectedMonth) {
       let date = new Date(), y = date.getFullYear(), m = this.selectedMonth - 1;
       let firstDay = new Date(y, m, 1);
       let lastDay = new Date(y, m + 1, 0);

      this.startDate = dayjs(firstDay).format('YYYY-MM-DD')
      this.endDate = dayjs(lastDay).format('YYYY-MM-DD')

     }
    if (!this.selectedMonth) {
      this.startDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
     }
   },
  },
  components: {
    MainLedgerBookIn,
    MainLedgerBookOut,
    MainLedgerCard5,
    MainLedgerCard6,
    MainLedgerLatest
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
