<template>
  <v-container>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

          <v-form
            name="DocumentForm"
            autocomplete="off">
            <v-row no-gutters>
                <h2>
                  <span v-if="this.$store.state.editItem  === 0">
                    {{langC.CreateNew}} {{ documentNameLoc }}
                  </span>
                  <span v-if="this.$store.state.editItem === 1">
                    {{langC.Edit}} {{ documentNameLoc }} {{mainledger.BookID ? locBookID : 0}}
                  </span>                  
                </h2>
            </v-row>
            <br>

            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-0 px-2">
                  
              </v-col>
              <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="py-0 px-2">
                  <span style="font-weight:bold">{{mainledger.CompanyNameDatabase}}, {{mainledger.BusinessYear}}, {{mainledger.First}} {{mainledger.Last}}, {{mainledger.email}} </span>
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-2"></v-divider>

          <v-row class="pt-3">
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                 <span v-if="haveNew === true">
                  <v-text-field
                      label="Vrsta knjige"
                      v-model="mainledger.BookName"
                      readonly
                    ></v-text-field>
                </span>

                <span v-if="haveNew === false">
                  <v-select
                    label="Vrsta knjige"
                    :items="bookType"
                    v-model="bookTypeSelected"
                    value="bookType.value"
                    single-line
                    return-object
                    persistent-hint
                    clearable
                    dense
                  ></v-select>
                </span>

              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                {{lang.BookingDate}}: <input type="date" ref="bookingd" v-model="mainledger.BookingDate" />
             </v-col>

             <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
              {{lang.InvoiceDate}}: <input type="date" v-model="mainledger.InvoiceDate" />
             </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                {{lang.InvoicePaymentDeadlineDate}}: <input type="date" v-model="mainledger.InvoicePaymentDeadlineDate" />
             </v-col>
             <v-col cols="12" sm="12" md="12" xs="12" lg="2" xl="2">
                <p class="text-left" v-if="show2Dcode">
                  <v-btn text color="primary" class="pt-3" @click="load2Dcode()">2Dcode</v-btn>
                </p>
                <p v-if="!show2Dcode  && !showUploadERacun">
                  <v-textarea
                    class="pt-0"
                    label="2Dcode"
                    v-model="txt2Dcode"
                  ></v-textarea> 
                   <v-text-field
                  class="pt-0"
                  label="Poziv"
                  v-model="mainledger.Pay1"
                  required
                  ></v-text-field> 
                  <v-text-field
                  class="pt-0"
                  label="Poziv NA"
                  v-model="mainledger.Pay2"
                  required
                  ></v-text-field> 
                <v-text-field
                  class="pt-0"
                  label="Opis"
                  v-model="mainledger.PayDesc"
                  required
                  ></v-text-field> 
                </p>
                <p class="text-left" v-if="showUploadERacun">
                    <v-file-input v-model="somefile" single label="File input"></v-file-input>
                    <v-btn
                      class="primary mr-1"
                      @click="onUpload">
                      Upload
                    </v-btn>
                </p>
            </v-col>
            <v-col cols="12" sm="12" md="12" xs="12" lg="1" xl="1">
              <p class="text-right" v-if="showERacun">
                <v-btn text color="primary" class="pt-3" @click="loadeInvoice()">{{lang.eInvoice}}</v-btn>
              </p>
              <p class="text-right" v-if="!show2Dcode && !showUploadERacun">
                <v-btn text color="primary" class="pt-3" @click="write2Dcode()">Ispuni</v-btn>
              </p>

            </v-col>

           </v-row>

           <v-row>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">

                  <span v-if="$store.state.documentSide === 2">
                    <v-select
                      label="Podvrsta"
                      :items="bookSubType"
                      item-text="descHR"
                      v-model="bookSubTypeSelected"
                      value="bookSubType.value"
                      single-line
                      return-object
                      persistent-hint
                      clearable
                      dense
                    ></v-select>
                  </span>

                </v-col>


                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right mt-2">
                  {{langC.Partner}}:
                </v-col>

                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pt-3 px-0 pr-2">
                  <v-autocomplete
                    ref="selpartner"
                    :items="partners"
                    v-model="selectPa"
                    :label="langC.SelectPartner"
                    item-text="partnerName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field
                    class="pt-0"
                      label="OIB *"
                      v-model="mainledger.partnerVATID"
                      required
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <p class="text-left">
                      <v-btn text color="primary" class="pt-3" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <p class="text-right">
                     <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                  </p>
                </v-col>

              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                    <v-text-field class="pr-2"
                      label="Naziv partnera"
                      v-model="mainledger.partnerName"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                      label="IBAN"
                      v-model="mainledger.partnerBankIBAN"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-text-field class="pr-2"
                    :label="lang.Partneremail"
                    v-model="mainledger.partneremail"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Ulica"
                      v-model="mainledger.partnerStreet"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                    <v-text-field
                      label="Br"
                      v-model="mainledger.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                    <v-text-field
                      label="Mjesto"
                      v-model="mainledger.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      label="Pošta"
                      v-model="mainledger.partnerPostcode"
                    ></v-text-field> 
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Broj računa:
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                  <v-text-field class="headline"
                      ref="innr"
                      label="Broj računa"
                      v-model="mainledger.InvoiceNumber"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" xs="12" lg="1" xl="1" class="pr-2">
                  <v-text-field class="headline"
                      label="Broj otpremnice"
                      v-model="mainledger.DeliveryNoteNumber"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" xs="12" lg="3" xl="3" class="pr-2">
                  <v-text-field
                      :label="lang.ConnName"
                      v-model="mainledger.ConnName"
                    ></v-text-field>
                </v-col>

                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
                  <v-text-field
                      :label="lang.ConnId"
                      v-model="mainledger.ConnId"
                    ></v-text-field>
                </v-col>


              </v-row> 

             <v-row no-gutters align="center">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2 headline">
                    Iznos računa:
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field
                      class="headline pr-4"
                      label="Primarni iznos"
                      v-model.number="mainledger.InvoiceAmount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2">
                    <v-select label="Primarna valuta" :items="currnecyType" v-model="mainledger.HomeCurrency" value="mainledger.HomeCurrency"></v-select>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pt-2 mr-1" v-if="BusinessYear <=2022">
                  <v-text-field
                      dense
                      label="Tecaj:"
                      v-model.number="mainledger.ExRate"
                      type="number"
                  ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2" v-if="BusinessYear <=2022">
                    <v-select label="Sekundarna valuta" :items="currnecyType" v-model="mainledger.ForeignCurrency" value="mainledger.ForeignCurrency"></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pt-2 ml-3 mr-2" v-if="BusinessYear <=2022">
                  <v-text-field
                      dense
                      label="Sekun.iznos"
                      v-model.number="mainledger.InvoiceForeginAmount"
                      type="number"
                  ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="ml-2">
                  <v-checkbox
                    :label="lang.importDOC"
                    v-model="mainledger.MLBool1"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="2" xl="2" class="ml-2">
                  <v-checkbox
                    :label="lang.PayedVAT"
                    v-model="mainledger.PayedVAT"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="2" xl="2" class="ml-2">
                  <v-checkbox
                    :label="lang.partnerNotInVAT"
                    v-model="mainledger.partnerNotInVAT"
                  ></v-checkbox>
                </v-col>
            </v-row>                           


            <v-divider class="mb-4"></v-divider>
            <div v-if="showVAT">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="3"  lg="3" xl="3" class="px-2" >
                
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2" class="pr-3">
                <small>PDV iznos</small>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>%PDV može se odbbiti</small>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>Izn. Može se odbiti</small>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <small>Izn.NE može se odbiti</small>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                <small>Osnovica</small>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="3"  lg="3" xl="3" class="px-2" >
                PDV 25%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                <v-text-field
                      dense
                      v-model.number="mainledger.VAT1PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT1Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="px-2" >
                PDV 13%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT2Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="px-2" >
                PDV 5%:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3Amount"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3PerCanUse"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3CanUse"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3CanTUse"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1">
                  <v-text-field
                      dense
                      v-model.number="mainledger.VAT3Base"
                      type="number"
                  ></v-text-field>
              </v-col>
            </v-row>             

            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="6" md="4"  lg="3" xl="3" class="py-4 px-2" >
                Ostalo:
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Neoporezivi iznos"
                      v-model.number="mainledger.VAT4Base"
                      type="number"
                  ></v-text-field>
              </v-col>
              
              <v-col cols="12" xs="12" sm="6" md="4"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="PDV ukupno (25+13+5)"
                      v-model.number="mainledger.AmountVAT"
                      type="number"
                      disabled
                  ></v-text-field>
 
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
 
              </v-col>
               <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="px-2" >
                PDV 0%:
              </v-col>
               <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="PDV 0%"
                      v-model.number="mainledger.VATZero"
                      type="number"
                  ></v-text-field>
              </v-col>

              <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="= Porezna osnovica:"
                      v-model.number="mainledger.AmountNetto"
                      type="number"
                      disabled
                  ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4" lg="1" xl="1" v-if="viewShowOut">
                <v-btn text color="primary" @click="showOut1()" class="pb-3 pl-0">Dodatno <v-icon v-if="!showOut">mdi-chevron-down</v-icon><v-icon v-if="showOut">mdi-chevron-up</v-icon></v-btn>
              </v-col>

            </v-row>

            </div>
            <v-divider class="pa-2"></v-divider>

            <v-row no-gutters v-if="showOut">
                <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" class="pr-3">

                </v-col>
                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Tuzemni prijenos por.ob"
                      v-model.number="mainledger.VAT4Per"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="1"  lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Isp.dob.u drugim drž.čl."
                      v-model.number="mainledger.VAT4Base"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Isp.dob. unutar EU"
                      v-model.number="mainledger.VAT4Amount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Obavljene usluge unutar EU"
                      v-model.number="mainledger.VAT4PerCanUse"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Obav.isp.os bez sjedišta u RH"
                      v-model.number="mainledger.VAT4CanUse"
                      type="number"
                  ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="showOut">
              <v-col cols="12" xs="0" sm="0" md="3" lg="3" xl="3" class="pr-3">

                </v-col>
                <v-col cols="12" xs="6" sm="3" md="3" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Sast.i post.dobara u drugoj drž.čl."
                      v-model.number="mainledger.VAT4CanTUse"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="pr-3">
                  <v-text-field
                      dense
                      label="Isp. NPS u EU "
                      v-model.number="mainledger.VAT5Per"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="U tuzem."
                      v-model.number="mainledger.VAT5Base"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="izv. isporuke"
                      v-model.number="mainledger.VAT5Amount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Ostala oslob."
                      v-model.number="mainledger.VAT5PerCanUse"
                      type="number"
                  ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="mainledger.BookTypeID === 3 || mainledger.BookTypeID === 4">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="py-4 px-2">
                  Dobra:
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Dobra (netto)"
                      v-model.number="mainledger.Goods"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Dobra PDV 25%"
                      v-model.number="mainledger.Goods25"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Dobra PDV 13%"
                      v-model.number="mainledger.Goods13"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Dobra PDV 5%"
                      v-model.number="mainledger.Goods5"
                      type="number"
                  ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="mainledger.BookTypeID === 3 || mainledger.BookTypeID === 4">
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="px-2 ">
                  Usluge:
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="4" lg="2" xl="2" class="pr-2">
                  <v-text-field
                      dense
                      label="usluge (netto)"
                      v-model.number="mainledger.Services"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="2" md="4"  lg="1" xl="1" class="pr-2">
                  <v-text-field
                      dense
                      label="25%"
                      v-model.number="mainledger.Services25"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="2" md="4" lg="1" xl="1" class="pr-2">
                  <v-text-field
                      dense
                      label="13%"
                      v-model.number="mainledger.Services13"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="4"  lg="1" xl="1" class="pr-2">
                  <v-text-field
                      dense
                      label="5%"
                      v-model.number="mainledger.Services5"
                      type="number"
                  ></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="mainledger.BookTypeID === 1">
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="py-4 px-2">
                  Ulaz na skladište:
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Broj skladista"
                      v-model.number="mainledger.WareHouseId"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Broj primke"
                      v-model.number="mainledger.WareHouseEntry"
                      type="number"
                  ></v-text-field>
                  
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2" class="pr-3">
                  <v-text-field
                      dense
                      label="Iznos primke"
                      v-model.number="mainledger.WareHouseEntryAmount"
                      type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6"  lg="2" xl="2" class="pr-3" >
                  <v-text-field
                      dense
                      label="PPO samo za TU"
                      v-model.number="mainledger.FreeF2"
                      type="number"
                  ></v-text-field>
                </v-col>
              </v-row>

            <v-row no-gutters>
                <v-col cols="12" xs="6" sm="6" md="3"  lg="3" xl="3" class="py-4 px-2">
                    Dokument:
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="2"  lg="2" xl="2" class="pr-3">
                   <v-text-field 
                      label="Broj"
                      v-model="mainledger.InvoiceNumber"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3"  lg="3" xl="3">
                  <v-text-field 
                      label="Skenirani dokument"
                      v-model="mainledger.MLFree2"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1">
                  <v-btn text small color="primary" @click="showPdf" class="mr-1" v-if="mainledger.MLFree2">
                      Prikaži
                  </v-btn>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="1" xl="1" class="mt-n4">
                    <v-checkbox
                      :label="lang.PaidClosed"
                      v-model="mainledger.paidClosed"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6"  lg="2" xl="2" >
                  <input type="date" v-model="mainledger.paidDate" /> 
             </v-col>
  
            </v-row>


            <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="px-2 headline" >
                Broj knjiženja:
              </v-col>
              
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" v-if="!admin">
                  <span class="headline">{{ mainledger.BookID ? locBookID : 0 }}
                  </span>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" v-if="admin">
                <v-text-field
                      dense
                      label="Broj knjiženja"
                      v-model.number="mainledger.BookID"
                      type="number"
                  ></v-text-field>
              </v-col>

            </v-row>

          </v-form>
          <div class="pink" v-html="error" />

          <v-btn 
            class="primary mr-1"
             :disabled="!formIsValid"
            @click="beforeRegister" >
            PROKNJIŽI
          </v-btn>
          <span v-if="this.$store.state.editItem === 0">
            <v-btn
              class="primary mr-1"
              @click="mainledgeragain">
              OTVORI NOVI
            </v-btn>
          </span>
          <v-btn
              v-if="!showUpload && mainledger.BookID"
              class="primary mr-1"
              @click="mainledgerupload">
              Upload
            </v-btn>
          <v-btn
            color="green"
            @click="mainledgerlist">
            <span v-if="this.$store.state.language == 'EN'">
            Back
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Natrag
            </span>
          </v-btn>

          <MainLedgerUpload v-if="showUpload" :mainledger="mainledger"></MainLedgerUpload>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import BankAccountService from '@/services/BankAccountService'
import BankOrderService from '@/services/BankOrderService'
import Api from '@/services/Api'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { defNew, defMainQuery, defMainLedger, defNewpartner, defMainLedgerDetailFill } from './mainledgerHelper'
import { testLastUpdate, newUpdates } from '@/services/TestLastUpdate'
import MainLedgerUpload from './MainLedgerUpload'
import {parseNum, round, dateFormat, createFloat} from '@/snippets/allSnippets'
import {defBankOrder, defNewBankOrder} from '../Salarie/salarieHelper'
import dayjs from 'dayjs'
//mport FileSaver from 'file-saver'

// import dateFormat from '@/snippets/dateFormat'

export default {
  name: 'mainledgercreate',
  data () {
    return {
      admin: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      mainledger: { },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      selectBank: {},
      selectCR: {},
      selectUs: {},
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        MLType: 0
      },
      currnecyType: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      localUser: '',
      bookType: [],
      bookTypeSelected: {
      },
      bookSubType: [],
      bookSubTypeSelected: {},
      showUpload: false,
      haveItem: null,
      selectId: null,
      haveNew: false,
      show: 1,
      mainQuery3: {},
      dog: 0,
      InvoiceAmount: 0,
      savedML: null,
      mldetails: [],
      BusinessYear: 2020,
      showOut: false,
      viewShowOut: false,
      show2Dcode: true,
      showERacun: true,
      txt2Dcode: '',
      fileEInvoice: '',
      show2DcodeName: false,
      showUploadERacun: false,
      somefile: null,
      MLFree2: null,
      MLFree3: null,
      MLFree4: null,
      MLFree5: null,
      showVAT: true,
      locBookID : 0
    }
  },
  components: {
   MainLedgerUpload
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    const d = new Date()
    this.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : d.getFullYear()

    if (this.$store.state.user.Admin) {
      this.admin = this.$store.state.user.Admin
    }
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainQuery3.CompanyId = this.$store.state.companyid
    }
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
    }
    this.show = 1
    if (this.$store.state.documentSide === 1 || this.$store.state.documentSide === 2) {
      this.show = 1
      if (this.$store.state.documentSide === 2) {
        this.viewShowOut = true
      }
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
    }
    this.bookType = this.$store.state.booktype ? this.$store.state.booktype : []
    this.bookSubType = this.$store.state.booksubtype ? this.$store.state.booksubtype : []

    if (this.$store.state.editItem === 0) {
      this.mainledger = defMainLedger()
      this.mainledger = {...this.mainledger, ...defNew()}
      this.mainQuery = {...defMainQuery()}
      this.localUser = this.mainledger.First + ' ' + this.mainledger.Last + ', ' + this.mainledger.email
      this.haveNew = true
      this.bookTypeSelected  = this.$store.state.bookTypeSelected
      this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
      this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
    }
    if (this.$store.state.editItem === 1) {
      const locDocument = await MainLedgerService.show(this.$store.state.mainledgerid)
      this.mainledger = locDocument.data.mainLedger
      this.InvoiceAmount = this.mainledger.InvoiceAmount ? parseFloat(this.mainledger.InvoiceAmount) : 0
      this.locBookID = parseInt(this.mainledger.BookID)
      let finded = false
      if (this.mainledger.partnerId) {
        this.selectPa = this.mainledger.partnerId ? this.partners.find(partner => (partner.id === parseInt(this.mainledger.partnerId))) : null
        if (this.selectPa) {
          finded = true
        }
      }
      if (!finded) {
        this.selectPa = this.mainledger.partnerVATID ? this.partners.find(partner => (partner.partnerVATID === this.mainledger.partnerVATID)) : null
        this.mainledger.partnerId = this.selectPa.id ? this.selectPa.id : null
      }
      this.bookTypeSelected = this.mainledger.BookTypeID ? this.$store.state.booktype.find(book => (parseInt(book.value) === this.mainledger.BookTypeID )) : null
      this.bookSubTypeSelected = this.mainledger.BookSubTypeID ? this.bookSubType.find(book => (parseInt(book.value) === this.mainledger.BookSubTypeID )) : null
      this.haveNew = false
      this.doParse()
    }
    
    // if (this.$store.state.booktype) {     
    //   this.bookType = this.$store.state.booktype.filter(book => {
    //         return book.side === this.$store.state.documentSide
    //       })
    //   this.bookTypeSelected = this.mainledger.BookTypeID ? this.bookType.find(book => (parseInt(book.value) === this.mainledger.BookTypeID )) : null
    // }
    this.setBookSubTypes()
    this.bookSubTypeSelected = this.mainledger.BookSubTypeID ? this.bookSubType.find(book => (parseInt(book.value) === this.mainledger.BookSubTypeID )) : null
    this.$refs.bookingd.focus()
  },
  computed: {
    formIsValid () {
      return this.mainledger.email !== '' &&
        this.mainledger.First !== '' &&
        this.mainledger.Last !== '' &&
        this.mainledger.InvoiceDate !== '' &&
        this.mainledger.BookingDate !== ''
    },
    formatDate1() {
      return this.mainledger.BookingDate
        ? dateFormat(this.mainledger.BookingDate)
        : "";
    },
    formatDate2() {
      return this.mainledger.InvoiceDate
        ? dateFormat(this.mainledger.InvoiceDate)
        : "";
    },
    formatDate3() {
      return this.mainledger.InvoicePaymentDeadlineDate
        ? dateFormat(this.mainledger.InvoicePaymentDeadlineDate)
        : "";
    },
    // formatDate4() {
    //   return this.mainledger.paidDate
    //     ? dateFormat(this.mainledger.paidDate)
    //     : "";
    // }
  },
  methods: {
    showOut1() {
      this.showOut = !this.showOut
    },
    setBookSubTypes() {
      
    },
    switchVAT() {
      this.showVAT = !this.showVAT
    },
    load2Dcode() {
      this.show2Dcode = false
      this.showERacun = false
      this.show2DcodeName = true
      this.showUploadERacun = false
    },
    loadeInvoice() {
      this.show2Dcode = false
      this.showERacun = false
      this.show2DcodeName = false
      this.showUploadERacun = true
    },
    onUpload() {
        this.MLFree2 = ''
        this.MLFree3 = ''
        this.MLFree4 = ''
        this.MLFree5 = ''
        if (this.somefile) {
          let fd = new FormData()
          fd.append('companyId', this.$store.state.companyid)
          fd.append('originalName', this.somefile.name)
          fd.append('typeDoc', 'BS')
          fd.append('myFile', this.somefile, this.somefile.name)
          this.somefile = null

          Api().post('bookin/invoice', fd).then(async(response) => {
              this.data = response.data
              if (this.data) {
                console.log('Ucitana datoteka this.data', this.data)
                this.mainQuery.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainQuery.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainQuery.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainQuery.MLFree5 = this.data.path ? this.data.path : ''
                if (this.data.mimetype ===  'text/xml') {
                  await MainLedgerService.readxmleracun(this.mainQuery)
                  .then (res => {
                    const data = res && res.data ? res.data : {}
                    const inv = data.jObj.Invoice ? data.jObj.Invoice : (data.jObj['inv:Invoice'] ? data.jObj['inv:Invoice'] : {})
                    console.log('inv 1 ', inv)
                    // ----------------------------------------------------  za KUPCA 
                    const aCp = inv['cac:AccountingCustomerParty']
                      const cParty = aCp['cac:Party']
                      let cName = null
                      let cName2 = null

                      const cPartyName = cParty['cac:PartyName'] ? cParty['cac:PartyName'] : null
                      if (cPartyName) {
                        cName = cPartyName['cbc:Name'] ? cPartyName['cbc:Name'] : null
                      }

                      const cPartyName2 = cParty['cac:PartyLegalEntity']
                      if (cPartyName2) {
                        cName2 = cPartyName2['cbc:RegistrationName']
                      }
                          const CompanyName = cName && cName.length > 0 ? cName : (cName2 && cName2.length > 0 ? cName2 :'')

                        const cPartyTaxScheme = cParty['cac:PartyTaxScheme']
                          const CompanyIDpartnerVATID = typeof cPartyTaxScheme['cbc:CompanyID'] === 'string' ? cPartyTaxScheme['cbc:CompanyID'].trim().substr(2,11) : cPartyTaxScheme['cbc:CompanyID']
                          console.log('CompanyName', CompanyName)
                          console.log('CompanyIDpartnerVATID', CompanyIDpartnerVATID)

                    // ----------------------------------------------------- Dobavljac 
                    const aSp = inv['cac:AccountingSupplierParty']
                      const Party = aSp['cac:Party']
                        const PartyName = Party['cac:PartyName'] ? Party['cac:PartyName'] : null
                        let Name = null
                        let Name2 = null
                        if (PartyName) {
                          Name = PartyName['cbc:Name'] ? PartyName['cbc:Name'] : null
                        }
                        const PartyName2 = Party['cac:PartyLegalEntity']
                        if (PartyName2) {
                          Name2 = PartyName2['cbc:RegistrationName']
                        }  
                        this.mainledger.partnerName = Name && Name.length > 0 ? Name : (Name2 && Name2.length > 0 ? Name2 :'')


                        const PartyTaxScheme = Party['cac:PartyTaxScheme']
                          this.mainledger.partnerVATID = typeof PartyTaxScheme['cbc:CompanyID'] === 'string' ? PartyTaxScheme['cbc:CompanyID'].trim().substr(2,11) : PartyTaxScheme['cbc:CompanyID']
                        const PostalAddress = Party['cac:PostalAddress']
                          this.mainledger.partnerCity = PostalAddress['cbc:CityName']
                          this.mainledger.partnerStreet = PostalAddress['cbc:StreetName']
                          this.mainledger.partnerPostcode = PostalAddress['cbc:PostalZone']

                    // ----------------------------------------------------- Novci
                    const LegalMonetaryTotal = inv['cac:LegalMonetaryTotal']

                      let haveAllAmount = LegalMonetaryTotal['cbc:PayableAmount'] ? LegalMonetaryTotal['cbc:PayableAmount'] : 0
                      if (haveAllAmount === 0) {
                        haveAllAmount = LegalMonetaryTotal['cbc:TaxInclusiveAmount'] ? LegalMonetaryTotal['cbc:TaxInclusiveAmount'] : 0
                      }
                      this.mainledger.InvoiceAmount = haveAllAmount

                    const tax = inv['cac:TaxTotal']
                      const taxSubtotal = tax['cac:TaxSubtotal']
                        const taxCategory = taxSubtotal['cac:TaxCategory']
                          const taxVATPer = taxCategory['cbc:Percent']
                        const TaxAmount = taxSubtotal['cbc:TaxAmount']
                        const TaxableAmount = taxSubtotal['cbc:TaxableAmount']
                        if (taxVATPer === 25) {
                          this.mainledger.VAT1Amount = round(TaxAmount,2)
                          this.mainledger.VAT1PerCanUse = 100
                          this.mainledger.VAT1CanUse = round(TaxAmount,2)
                          this.mainledger.VAT1CanTUse = 0
                          this.mainledger.VAT1Base = round(TaxableAmount ,2)
                        }
                        if (taxVATPer === 13) {
                          this.mainledger.VAT2Amount = round(TaxAmount,2)
                          this.mainledger.VAT2PerCanUse = 100
                          this.mainledger.VAT2CanUse = round(TaxAmount,2)
                          this.mainledger.VAT2CanTUse = 0
                          this.mainledger.VAT2Base = round(TaxableAmount ,2)
                        }
                        if (taxVATPer === 5) {
                          this.mainledger.VAT3Amount = round(TaxAmount,2)
                          this.mainledger.VAT3PerCanUse = 100
                          this.mainledger.VAT3CanUse = round(TaxAmount,2)
                          this.mainledger.VAT3CanTUse = 0
                          this.mainledger.VAT3Base = round(TaxableAmount ,2)
                        }

                     //console.log('Name', Name)

                    // ---------------------------------------------------------   Datumi
                    this.mainledger.InvoiceNumber = inv['cbc:ID'] ? inv['cbc:ID'] : ''
                    this.mainledger.BookingDate = inv['cbc:TaxPointDate'] ? inv['cbc:TaxPointDate'] : new Date()
                    this.mainledger.InvoiceDate = inv['cbc:IssueDate'] ? inv['cbc:IssueDate'] : new Date()
                    this.mainledger.InvoicePaymentDeadlineDate = inv['cbc:DueDate'] ? inv['cbc:DueDate'] : new Date()
                    const haveIt = this.findByVATID()
                    console.log('Imam ga haveIt  ? ', haveIt)
                    if (!haveIt) {
                      // add new partner
                      this.addeditPartner()
                    }


                  })
                  .catch(err => {
                    console.log('Greska ', err )
                  })
                }
              }
          }).catch(error => {
              console.log('Error uplodad ', error)
              alert(this.lang.alertError1)
              if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
              }
          });

        } else {
          this.text = this.lang.BSfileError
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
    },
    write2Dcode() {
      const lines = this.txt2Dcode.split('\n')
      console.log('lines', lines)
      if (lines[9] && lines[9].length > 0) {
        this.checkByIban2(lines[9].trim())
      }
      if(lines[1] && lines[2]) {
        this.mainledger.HomeCurrency = lines[1]
        this.mainledger.InvoiceAmount = createFloat(lines[2].trim())
        this.mainledger.VAT1Amount = round((this.mainledger.InvoiceAmount - round((this.mainledger.InvoiceAmount / 1.25) ,2)),2)
        this.mainledger.VAT1PerCanUse = 100
        this.mainledger.VAT1CanUse = round(this.mainledger.VAT1Amount,2)
        this.mainledger.VAT1CanTUse = 0
        this.mainledger.VAT1Base = round((this.mainledger.InvoiceAmount - this.mainledger.VAT1Amount) ,2)
        
      }
      // new fileds in mainledger for invoices. .. and new fields in bookorders
      if (lines[3]){
        this.mainledger.Pay1 = lines[10] ? lines[10] : ''
        this.mainledger.Pay2 = lines[11] ? lines[11] : ''
        this.mainledger.PayDesc = lines[13] ? lines[13] : ''
        
      }
    },
    async checkByIban2(iban) {
          if (iban){
            const cIban = {
              partnerBankIBAN: iban
            }

            this.error = null
            this.showPr = {}
            await PartnerService.checkIBAN(cIban)
            .then(res => {
              if (res.data.partner) {
                const pr = res.data.partner
                this.mainledger.partnerId = pr.id ? pr.id : null
                this.mainledger.partnerName = pr.partnerName ? pr.partnerName : null
                this.mainledger.partnerNameShort = pr.partnerNameShort ? pr.partnerNameShort : null
                this.mainledger.partnerNameDatabase = pr.partnerNameDatabase ? pr.partnerNameDatabase : null
                this.mainledger.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
                this.mainledger.partnerStreet = pr.partnerStreet ? pr.partnerStreet : null
                this.mainledger.partnerCity = pr.partnerCity ? pr.partnerCity : null
                this.mainledger.partnerPostcode = pr.partnerPostcode ? pr.partnerPostcode : null
                this.mainledger.partnerVATID = pr.partnerVATID ? pr.partnerVATID : null
                this.mainledger.partneremail = pr.partneremail ? pr.partneremail : null
                this.mainledger.partnerPhones = pr.partnerPhones ? pr.partnerPhones : null
                this.mainledger.partnerBankName = pr.partnerBankName ? pr.partnerBankName : null
                this.mainledger.partnerBankAddress = pr.partnerBankAddress ? pr.partnerBankAddress : null
                this.mainledger.partnerBankIBAN = pr.partnerBankIBAN ? pr.partnerBankIBAN : null
                this.mainledger.partnerBankSWIFT = pr.partnerBankSWIFT ? pr.partnerBankSWIFT : null
                this.selectPa = this.mainledger.partnerId ? this.partners.find(partner => (partner.id === parseInt(this.mainledger.partnerId))) : null
                this.$store.dispatch('setPartnerid', this.mainledger.partnerId)
                this.$store.dispatch('setPartnerVATID', pr.partnerVATID)
              }
            })
            .catch(err => {
              // eslint-disable-next-line
              console.log('Partner not exist', err)
              this.text = 'Error! IBAN not exist. Partner sa tim IBANom nije upisan!'
              this.color = 'red'
              this.snackbar = true
            })
          }
      },
    async createBankOrder(from1, onDate, desc1, desc2, amount, recepient, iban, call1, call2) {
      const newB = defBankOrder()
      const newBO = {...newB, ...defNewBankOrder()}
      newBO.PlatiteljIBAN = this.selectBank.bankAccountNr
      newBO.PlatiteljModel = 'HR99'
      newBO.PlatiteljPoziv = from1
      newBO.bankAccountName = this.selectBank.bankAccountName
      newBO.bankAccountNr = this.selectBank.bankAccountNr
      newBO.DatumIzvrsenja = onDate
      newBO.Opis1 = desc1
      newBO.Opis2 = desc2
      newBO.Opis3 = ''
      newBO.Iznos = amount
      newBO.Primatelj1 = recepient
      newBO.PrimateljIBAN = iban
      newBO.PrimateljModel = call1
      newBO.PrimateljPoziv = call2
      newBO.Grupa = 2
      newBO.Hitno = true
      newBO.FreeF1 = from1
      newBO.FreeF5 = 'URA'
      await BankOrderService.post(newBO)
    },
    async findByVATID() {
      if (this.mainledger.partnerVATID) {
        this.mainQuery = {}
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.mainQuery.partnerVATID = this.mainledger.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        const id = data && data.partner && data.partner.id ? data.partner.id : null
        if (id) {
          this.selectPa =  this.partners.find(partner => (partner.id === parseInt(id)))
          await this.findPartner(id)
          this.text = 'OK!'
          this.color = 'green'
          this.snackbar = true
          return true
        }
        if (!id) {
          this.text = 'Error! Partner with this VATid not exist! Partner sa tim OIBom ne postoji!'
          this.color = 'red'
          this.snackbar = true
          return false
        }
      }
    },
    async showPdf() {
      if (this.mainledger.MLFree5) {
        const showFile ={
          showFile: this.mainledger.MLFree5
        }
        await MainLedgerService.showPdf(showFile)
        .then(res => {
          const data = res.data
          var byteCharacters = atob(data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "application/pdf" });

          //FileSaver.saveAs(blob, "invoice.pdf")

          // // const zoveSe = this.mainledger.MLFree2 ? this.mainledger.MLFree2 : 'Book'
          //const header = res.headers
          //const fileType = header['content-type']
          //const file = new Blob([data], {type: fileType})
          // eslint-disable-next-line
          //console.log(' have file ', file)
          const fileurl = window.URL.createObjectURL(blob)
          window.open(fileurl)
          //FileSaver.saveAs(file, 'invoice.pdf');
        }).catch(error => {
          this.text = 'Error ' + error
          this.color = 'red'
          this.snackbar = true
        })
      } else {
        alert('Sorry, document is not defined! Can not open.')
      }
    },
    async register () {
      try {
         this.localError = false
          const haveYear = dayjs(this.mainledger.BookingDate).year()
          if (haveYear !== this.$store.state.businessYear) {
            this.text = 'Greška!! Datum temeljnice je u krivoj poslovnoj godini !!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }

         if (!this.mainledger.InvoiceAmount || this.mainledger.InvoiceAmount === 0){
          this.mainledger.paidClosed = false
          this.mainledger.paidDate = null
          this.mainledger.paidHome = 0
          this.mainledger.paidFore = 0
          this.text = 'Greska! Iznos je 0!! Error!! Invoice Amount is zero '
          this.color = 'red'
          this.snackbar = true
          return
         }

         if (this.selectPa.partnerVATID) {
            const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
            this.mainledger.partnerId = locPartner.id
            this.mainledger.partnerName = locPartner.partnerName
            this.mainledger.partnerNameShort = locPartner.partnerNameShort
            this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
            this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
            this.mainledger.partnerStreet = locPartner.partnerStreet
            this.mainledger.partnerCity = locPartner.partnerCity
            this.mainledger.partnerPostcode = locPartner.partnerPostcode
            this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
            this.mainledger.partnerState = locPartner.partnerState
            this.mainledger.partnerVATID = locPartner.partnerVATID
            this.mainledger.partneremail = locPartner.partneremail
            this.mainledger.partnerPhones = locPartner.partnerPhones
            this.mainledger.partnerBankName = locPartner.partnerBankName
            this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
            this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
            this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
        }

        if (!this.mainledger.partnerName || this.mainledger.partnerName.trim().length === 0){
          this.text = 'Greska! Nema naziva partnera!! Error!! Partner name is empty!! '
          this.color = 'red'
          this.snackbar = true
          return
        }
        if (!this.mainledger.partnerVATID || (typeof this.mainledger.partnerVATID === 'string' && this.mainledger.partnerVATID.trim().length === 0)){
          this.text = 'Greska! Nema OIBa partnera!! Error!! Partner VATid is empty!! '
          this.color = 'red'
          this.snackbar = true
          return
        }
        if (!this.mainledger.MLBool1) {
          const partVatLeght = typeof this.mainledger.partnerVATID === 'string' ? this.mainledger.partnerVATID.trim().length : this.mainledger.partnerVATID.toString().length()
          if (partVatLeght !== 11) {
            this.text = this.lang.PartnerErrorVAT3
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
        }
        
        await this.checkNotNull()
        if (!this.admin) {
          if (this.$store.state.editItem === 0 && this.mainledger.BookID !== 0) {   
            this.text = 'Greska! Dokument je vec snimljen!! '
            this.color = 'red'
            this.snackbar = true
            return
          }
        }
        this.mainQuery = {...defMainQuery()}
        
        // when enter NEW invoice check if already exist with these number
        if (this.$store.state.editItem === 0 && this.mainledger.MLType === 1) {
          const findWhat = {}
          findWhat.CompanyId  = this.$store.state.companyid ? this.$store.state.companyid : 1
          findWhat.BusinessYear =  this.$store.state.businessYear ? this.$store.state.businessYear : 2021
          findWhat.MLType = 1
          findWhat.InvoiceNumber = this.mainledger.InvoiceNumber ? this.mainledger.InvoiceNumber : 9999999999
          findWhat.partnerVATID = this.mainledger.partnerVATID ? this.mainledger.partnerVATID : 9999999999
          const res = await MainLedgerService.find(findWhat)
          if (res.data && res.data.mainLedger && res.data.mainLedger.length > 0) {
            this.text = 'Greska! URA sa tim brojem već postoji u knjizi URA. Error! Invoice with this number already exist in books! '
            this.color = 'red'
            this.snackbar = true
            return
          }
        }

        if (this.bookSubTypeSelected && this.bookSubTypeSelected.value) {
            this.mainledger.BookSubTypeID = parseInt(this.bookSubTypeSelected.value)
            this.mainledger.BookSubName = this.bookSubTypeSelected.text


        } else {
          this.mainledger.BookSubTypeID = null
          this.mainledger.BookSubName = null
        }

        if (this.$store.state.editItem === 0) {

          if (this.$store.state.bookTypeSelected && this.$store.state.bookTypeSelected.value) {

            if (this.mainledger.BookID === 0) {
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
            }

            this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID 

            await MainLedgerService.post(this.mainledger)
            .then(res => {
              this.savedML = res.data.mainledger ? res.data.mainledger : {}
              this.locBookID = parseInt(this.savedML.BookID)
              if (this.mainledger.MLType === 1) {
                this.createBankOrder((this.mainledger.BookID ? parseInt(this.mainledger.BookID) : 0), this.mainledger.InvoicePaymentDeadlineDate, this.mainledger.PayDesc, '', this.mainledger.InvoiceAmount, this.mainledger.partnerName, this.mainledger.partnerBankIBAN, this.mainledger.Pay1, this.mainledger.Pay2)
              }
              if (this.$store.state.language === 'EN') {
                this.text = 'New mainledger doc has been saved.'
                this.color = 'green'
                this.snackbar = true
              }
              if (this.$store.state.language === 'HR') {
                this.text = this.$store.state.documentSide === 1 ? 'Nova ura je snimljena u bazu.' : 'Nova ira je snimljena u bazu.'
                this.color = 'green'
                this.snackbar = true
              }
            })
            .catch(err => {
              this.error = err.data
              this.text = 'Error1! Cannot save document to database. ' + err
              this.color = 'red'
              this.snackbar = true
            })
          } else {
            this.text = 'Greska! Nije odabrana vrsta knjige!!'
            this.color = 'red'
            this.snackbar = true
          }
        }        
        if (this.$store.state.editItem === 1) {
          if (this.bookTypeSelected.value) {
            this.mainledger.BookTypeID = parseInt(this.bookTypeSelected.value)
            this.mainledger.BookName = this.bookTypeSelected.text
          }
          await this.$store.dispatch('setMainLedgerActiv', this.mainledger ? this.mainledger : {})

          await MainLedgerService.put(this.mainledger)
         
          // update items
          this.mldetails = (await MainLedgerDetailService.index(this.mainledger.id)).data.mainledgerDetails
          const updateHead = defMainLedgerDetailFill()
          const updatedItems = []
          if (this.mldetails) {
          
            this.mldetails.map(detail => {
              const newItem = {...detail, ...updateHead}
              updatedItems.push(newItem)
            })
          }
          if (updatedItems && updatedItems.length >0) {
            await MainLedgerDetailService.saveitems(updatedItems)
          }

          this.$router.push({
            name: 'mainledger'
          })
        }

      } catch (error) {
        this.error = error.data
        this.text = 'Error2! Cannot save document to database. ' + error.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    close () {
      this.show = false
    },
    calculateSecAmount () {
      this.mainledger.InvoiceForeginAmount = (this.mainledger.InvoiceAmount) / (this.mainledger.ExRate)
      // this.mainledger.VAT1Base = this.round((((this.mainledger.InvoiceAmount) / (1 + (this.mainledger.VAT1Per) / 100))),2)
      // this.mainledger.VAT1Amount = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
      // this.mainledger.VAT1CanUse = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
    },
    calculateFirstAmount () {
      if (round(this.mainledger.InvoiceAmount, 2) !== 0 && !this.showUploadERacun) {
        if (this.$store.state.editItem === 0) {
        this.mainledger.InvoiceForeginAmount = this.round(((this.mainledger.InvoiceAmount) / (this.mainledger.ExRate)),2)
        //this.mainledger.InvoiceAmount = this.round((this.mainledger.InvoiceForeginAmount * this.mainledger.ExRate),2)
          this.mainledger.VAT1Amount = round((this.mainledger.InvoiceAmount - round((this.mainledger.InvoiceAmount / 1.25) ,2)),2)
          this.mainledger.VAT1PerCanUse = 100
          this.mainledger.VAT1CanUse = round(this.mainledger.VAT1Amount,2)
          this.mainledger.VAT1CanTUse = 0
          this.mainledger.VAT1Base = round((this.mainledger.InvoiceAmount - this.mainledger.VAT1Amount) ,2)
          this.calculateFirstVAT()
        }
        // this.mainledger.VAT1Amount = round((this.mainledger.InvoiceAmount - round((this.mainledger.InvoiceAmount / 1.25) ,2)),2)
        // this.mainledger.VAT1Base = this.round((((this.mainledger.InvoiceAmount) / (1 + (this.mainledger.VAT1Per) / 100))),2)
        // this.mainledger.VAT1CanUse = this.round(((this.mainledger.InvoiceAmount) - this.mainledger.VAT1Base),2)
      }
    },
    calculateFirstVAT () {
      if (round(this.mainledger.VAT1PerCanUse,2) !== 100) {
        this.mainledger.VAT1CanUse = round((this.mainledger.VAT1Amount * (this.mainledger.VAT1PerCanUse / 100)) ,2)
        this.mainledger.VAT1CanTUse = this.mainledger.VAT1Amount - this.mainledger.VAT1CanUse
        this.mainledger.VAT1Base = round(((this.mainledger.VAT1CanUse + this.mainledger.VAT1CanTUse) / ( this.mainledger.VAT1Per / 100)),2)
      }
      if (round(this.mainledger.VAT1PerCanUse,2) === 100) {
        this.mainledger.VAT1CanUse = round(this.mainledger.VAT1Amount,2)
        this.mainledger.VAT1CanTUse = 0
        this.mainledger.VAT1Base = round((this.mainledger.InvoiceAmount - this.mainledger.VAT1Amount) ,2)
      }
      this.calculateVATAmount()
    },
    calculateSecondVAT () {
      this.mainledger.VAT2CanUse = round((this.mainledger.VAT2Amount * (this.mainledger.VAT2PerCanUse / 100)), 2)
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2Amount - this.mainledger.VAT2CanUse
      this.mainledger.VAT2Base = round(((this.mainledger.VAT2CanUse + this.mainledger.VAT2CanTUse) / ( this.mainledger.VAT2Per / 100)), 2)
      this.calculateVATAmount()
    },
    calculateThirdVAT () {
      this.mainledger.VAT3CanUse = round((this.mainledger.VAT3Amount * ((this.mainledger.VAT3PerCanUse) / 100)), 2)
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3Amount - this.mainledger.VAT3CanUse
      this.mainledger.VAT3Base = round(((this.mainledger.VAT3CanUse + this.mainledger.VAT3CanTUse) / (this.mainledger.VAT3Per / 100)), 2)
      this.calculateVATAmount()
    },
    beforeRegister() {
      this.checkNotNull()
      const haveDiff = round((this.mainledger.InvoiceAmount - this.mainledger.VAT1Base - this.mainledger.VAT1Amount - this.mainledger.VAT2Base - this.mainledger.VAT2Amount - this.mainledger.VAT3Base - this.mainledger.VAT3Amount - this.mainledger.VAT4Base), 2)
      if (haveDiff !== 0) {
        if (confirm('Razlika kod razrade računa u iznosu = ' + haveDiff + '.Da li ste sigurni da želite proknjižiti taj račun?')) {
          this.register()
        }
      } else {
        this.register()
      }
    },
    calculateVATAmount () {
      // this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? (this.mainledger.VAT1Amount) : 0
      // this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? (this.mainledger.VAT2Amount) : 0
      // this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? (this.mainledger.VAT3Amount) : 0
      // this.mainledger.VAT4Base = this.mainledger.VAT4Base ? (this.mainledger.VAT4Base) : 0
      // this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? (this.mainledger.InvoiceAmount) : 0
      this.mainledger.AmountVAT = ((this.mainledger.VAT1Amount + this.mainledger.VAT2Amount + this.mainledger.VAT3Amount))
      this.mainledger.AmountNetto = ((this.mainledger.InvoiceAmount - this.mainledger.AmountVAT - this.mainledger.VAT4Base))
    },
    focusBookingD() {
      this.$refs.bookingd.focus()
    },
    focusINNR() {
      // 👇 call custom "focus" method
      this.$refs.innr.focus()
    },
    async findPartner () {
       
      if (this.selectPa && this.selectPa.partnerVATID) {
        const locPartner = (await PartnerService.show(this.selectPa.partnerVATID)).data.partner
        this.mainledger.partnerId = locPartner.id
        this.mainledger.partnerName = locPartner.partnerName
        this.mainledger.partnerNameShort = locPartner.partnerNameShort
        this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
        this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.mainledger.partnerStreet = locPartner.partnerStreet
        this.mainledger.partnerCity = locPartner.partnerCity
        this.mainledger.partnerPostcode = locPartner.partnerPostcode
        this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
        this.mainledger.partnerState = locPartner.partnerState
        this.mainledger.partnerVATID = locPartner.partnerVATID
        this.mainledger.partneremail = locPartner.partneremail
        this.mainledger.partnerPhones = locPartner.partnerPhones
        this.mainledger.partnerBankName = locPartner.partnerBankName
        this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
        this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
        this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
        this.focusINNR()
      }
    },
    checkNotNull() {
      this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? (this.mainledger.InvoiceAmount) : 0
      this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceForeginAmount ? (this.mainledger.InvoiceForeginAmount) : 0
      //this.mainledger.VAT1Per = this.mainledger.VAT1Per ? (this.mainledger.VAT1Per) : 25
      this.mainledger.VAT1Base = this.mainledger.VAT1Base ? (this.mainledger.VAT1Base) : 0
      this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? (this.mainledger.VAT1Amount) : 0
      this.mainledger.VAT1PerCanUse = this.mainledger.VAT1PerCanUse ? (this.mainledger.VAT1PerCanUse) : 100
      this.mainledger.VAT1CanUse = this.mainledger.VAT1CanUse ? (this.mainledger.VAT1CanUse) : 0
      this.mainledger.VAT1CanTUse = this.mainledger.VAT1CanTUse ? (this.mainledger.VAT1CanTUse) : 0
      //this.mainledger.VAT2Per = this.mainledger.VAT2Per ? (this.mainledger.VAT2Per) : 13
      this.mainledger.VAT2Base = this.mainledger.VAT2Base ? (this.mainledger.VAT2Base) : 0
      this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? (this.mainledger.VAT2Amount) : 0
      this.mainledger.VAT2PerCanUse = this.mainledger.VAT2PerCanUse ? (this.mainledger.VAT2PerCanUse) : 100
      this.mainledger.VAT2CanUse = this.mainledger.VAT2CanUse ? (this.mainledger.VAT2CanUse) : 0
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2CanTUse ? (this.mainledger.VAT2CanTUse) : 0
      //this.mainledger.VAT3Per = this.mainledger.VAT3Per ? (this.mainledger.VAT3Per) : 5
      this.mainledger.VAT3Base = this.mainledger.VAT3Base ? (this.mainledger.VAT3Base) : 0
      this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? (this.mainledger.VAT3Amount) : 0
      this.mainledger.VAT3PerCanUse = this.mainledger.VAT3PerCanUse ? (this.mainledger.VAT3PerCanUse) : 100
      this.mainledger.VAT3CanUse = this.mainledger.VAT3CanUse ? (this.mainledger.VAT3CanUse) : 0
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3CanTUse ? (this.mainledger.VAT3CanTUse) : 0
      this.mainledger.VAT4Per = this.mainledger.VAT4Per ? (this.mainledger.VAT4Per) : 0
      this.mainledger.VAT4Base = this.mainledger.VAT4Base ? (this.mainledger.VAT4Base) : 0
      this.mainledger.VAT4Amount = this.mainledger.VAT4Amount ? (this.mainledger.VAT4Amount) : 0
      this.mainledger.VAT4PerCanUse = this.mainledger.VAT4PerCanUse ? (this.mainledger.VAT4PerCanUse) : 0
      this.mainledger.VAT4CanUse = this.mainledger.VAT4CanUse ? (this.mainledger.VAT4CanUse) : 0
      this.mainledger.VAT4CanTUse = this.mainledger.VAT4CanTUse ? (this.mainledger.VAT4CanTUse) : 0
      this.mainledger.VAT5Per = this.mainledger.VAT5Per ? (this.mainledger.VAT5Per) : 0
      this.mainledger.VAT5Base = this.mainledger.VAT5Base? (this.mainledger.VAT5Base) : 0
      this.mainledger.VAT5Amount = this.mainledger.VAT5Amount ? (this.mainledger.VAT5Amount) : 0
      this.mainledger.VAT5PerCanUse = this.mainledger.VAT5PerCanUse ? (this.mainledger.VAT5PerCanUse) : 0
      this.mainledger.VAT5CanUse = this.mainledger.VAT5CanUse  ? (this.mainledger.VAT5CanUse) : 0
      this.mainledger.VAT5CanTUse = this.mainledger.VAT5CanTUse ? (this.mainledger.VAT5CanTUse) : 0

      this.mainledger.AmountNetto = this.mainledger.AmountNetto  ? (this.mainledger.AmountNetto) : 0
      this.mainledger.AmountVAT = this.mainledger.AmountVAT ? (this.mainledger.AmountVAT) : 0
      this.mainledger.AmountBrutto = this.mainledger.AmountBrutto ? (this.mainledger.AmountBrutto) : 0
      this.mainledger.AmountPayInAdvance = this.mainledger.AmountPayInAdvance ? (this.mainledger.AmountPayInAdvance) : 0
      this.mainledger.AmountToPay = this.mainledger.AmountToPay ? (this.mainledger.AmountToPay) : 0
      this.mainledger.AmountNettoForeign = this.mainledger.AmountNettoForeign? (this.mainledger.AmountNettoForeign) : 0
      this.mainledger.AmountVATForeign = this.mainledger.AmountVATForeign ? (this.mainledger.AmountVATForeign) : 0
      this.mainledger.AmountBruttoForeign = this.mainledger.AmountBruttoForeign ? (this.mainledger.AmountBruttoForeign) : 0
      this.mainledger.AmountPayInAdvanceForeign = this.mainledger.AmountPayInAdvanceForeign ? (this.mainledger.AmountPayInAdvanceForeign): 0
      this.mainledger.AmountToPayForeign = this.mainledger.AmountToPayForeign ? (this.mainledger.AmountToPayForeign) : 0
      this.mainledger.Debit = this.mainledger.Debit? (this.mainledger.Debit) : 0,
      this.mainledger.Credit = this.mainledger.Credit ? (this.mainledger.Credit) : 0
      this.mainledger.DebitForegin = this.mainledger.DebitForegin ? (this.mainledger.DebitForegin) : 0
      this.mainledger.CreditForegin = this.mainledger.CreditForegin ? (this.mainledger.CreditForegin) : 0
      this.mainledger.DebitTotal = this.mainledger.DebitTotal ? (this.mainledger.DebitTotal) : 0
      this.mainledger.CreditTotal = this.mainledger.CreditTotal ? (this.mainledger.CreditTotal): 0
      this.mainledger.DebitForeginTotal = this.mainledger.DebitForeginTotal ? (this.mainledger.DebitForeginTotal) : 0
      this.mainledger.CreditForeginTotal = this.mainledger.CreditForeginTotal? (this.mainledger.CreditForeginTotal) : 0

      this.mainledger.WareHouseId = this.mainledger.WareHouseId ? this.mainledger.WareHouseId : 0
      this.mainledger.WareHouseEntry = this.mainledger.WareHouseEntry ? this.mainledger.WareHouseEntry : 0
      this.mainledger.WareHouseEntryAmount = this.mainledger.WareHouseEntryAmount? this.mainledger.WareHouseEntryAmount : 0
    },
    doParse() {
      this.mainledger.InvoiceAmount = this.mainledger.InvoiceAmount ? parseNum(this.mainledger.InvoiceAmount) : 0
      this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceForeginAmount ? parseNum(this.mainledger.InvoiceForeginAmount) : 0
      //this.mainledger.VAT1Per = this.mainledger.VAT1Per ? parseNum(this.mainledger.VAT1Per) : 25
      this.mainledger.VAT1Base = this.mainledger.VAT1Base ? parseNum(this.mainledger.VAT1Base) : 0
      this.mainledger.VAT1Amount = this.mainledger.VAT1Amount ? parseNum(this.mainledger.VAT1Amount) : 0
      this.mainledger.VAT1PerCanUse = this.mainledger.VAT1PerCanUse ? parseNum(this.mainledger.VAT1PerCanUse) : 100
      this.mainledger.VAT1CanUse = this.mainledger.VAT1CanUse ? parseNum(this.mainledger.VAT1CanUse) : 0
      this.mainledger.VAT1CanTUse = this.mainledger.VAT1CanTUse ? parseNum(this.mainledger.VAT1CanTUse) : 0
      //this.mainledger.VAT2Per = this.mainledger.VAT2Per ? parseNum(this.mainledger.VAT2Per) : 13
      this.mainledger.VAT2Base = this.mainledger.VAT2Base ? parseNum(this.mainledger.VAT2Base) : 0
      this.mainledger.VAT2Amount = this.mainledger.VAT2Amount ? parseNum(this.mainledger.VAT2Amount) : 0
      this.mainledger.VAT2PerCanUse = this.mainledger.VAT2PerCanUse ? parseNum(this.mainledger.VAT2PerCanUse) : 100
      this.mainledger.VAT2CanUse = this.mainledger.VAT2CanUse ? parseNum(this.mainledger.VAT2CanUse) : 0
      this.mainledger.VAT2CanTUse = this.mainledger.VAT2CanTUse ? parseNum(this.mainledger.VAT2CanTUse) : 0
      //this.mainledger.VAT3Per = this.mainledger.VAT3Per ? parseNum(this.mainledger.VAT3Per) : 5

      this.mainledger.VAT3Base = this.mainledger.VAT3Base ? parseNum(this.mainledger.VAT3Base) : 0
      this.mainledger.VAT3Amount = this.mainledger.VAT3Amount ? parseNum(this.mainledger.VAT3Amount) : 0
      this.mainledger.VAT3PerCanUse = this.mainledger.VAT3PerCanUse ? parseNum(this.mainledger.VAT3PerCanUse) : 100
      this.mainledger.VAT3CanUse = this.mainledger.VAT3CanUse ? parseNum(this.mainledger.VAT3CanUse) : 0
      this.mainledger.VAT3CanTUse = this.mainledger.VAT3CanTUse ? parseNum(this.mainledger.VAT3CanTUse) : 0
      this.mainledger.VAT4Per = this.mainledger.VAT4Per ? parseNum(this.mainledger.VAT4Per) : 0
      this.mainledger.VAT4Base = this.mainledger.VAT4Base ? parseNum(this.mainledger.VAT4Base) : 0
      this.mainledger.VAT4Amount = this.mainledger.VAT4Amount ? parseNum(this.mainledger.VAT4Amount) : 0
      this.mainledger.VAT4PerCanUse = this.mainledger.VAT4PerCanUse ? parseNum(this.mainledger.VAT4PerCanUse) : 0
      this.mainledger.VAT4CanUse = this.mainledger.VAT4CanUse ? parseNum(this.mainledger.VAT4CanUse) : 0
      this.mainledger.VAT4CanTUse = this.mainledger.VAT4CanTUse ? parseNum(this.mainledger.VAT4CanTUse) : 0
      this.mainledger.VAT5Per = this.mainledger.VAT5Per ? parseNum(this.mainledger.VAT5Per) : 0
      this.mainledger.VAT5Base = this.mainledger.VAT5Base ? parseNum(this.mainledger.VAT5Base) : 0
      this.mainledger.VAT5Amount = this.mainledger.VAT5Amount ? parseNum(this.mainledger.VAT5Amount) : 0
      this.mainledger.VAT5PerCanUse = this.mainledger.VAT5PerCanUse ? parseNum(this.mainledger.VAT5PerCanUse) : 0
      this.mainledger.VAT5CanUse = this.mainledger.VAT5CanUse ? parseNum(this.mainledger.VAT5CanUse) : 0
      this.mainledger.VAT5CanTUse = this.mainledger.VAT5CanTUse ? parseNum(this.mainledger.VAT5CanTUse) : 0
 
      this.mainledger.AmountNetto = this.mainledger.AmountNetto ? parseNum(this.mainledger.AmountNetto) : 0
      this.mainledger.AmountVAT = this.mainledger.AmountVAT ? parseNum(this.mainledger.AmountVAT) : 0
      this.mainledger.AmountBrutto = this.mainledger.AmountBrutto ? parseNum(this.mainledger.AmountBrutto) : 0
      this.mainledger.AmountPayInAdvance = this.mainledger.AmountPayInAdvance ? parseNum(this.mainledger.AmountPayInAdvance) : 0
      this.mainledger.AmountToPay = this.mainledger.AmountToPay ? parseNum(this.mainledger.AmountToPay) : 0
      this.mainledger.AmountNettoForeign = this.mainledger.AmountNettoForeign ? parseNum(this.mainledger.AmountNettoForeign) : 0
      this.mainledger.AmountVATForeign = this.mainledger.AmountVATForeign ? parseNum(this.mainledger.AmountVATForeign) : 0
      this.mainledger.AmountBruttoForeign = this.mainledger.AmountBruttoForeign ? parseNum(this.mainledger.AmountBruttoForeign) : 0
      this.mainledger.AmountPayInAdvanceForeign = this.mainledger.AmountPayInAdvanceForeign ? parseNum(this.mainledger.AmountPayInAdvanceForeign): 0
      this.mainledger.AmountToPayForeign = this.mainledger.AmountToPayForeign ? parseNum(this.mainledger.AmountToPayForeign) : 0
      this.mainledger.Debit = this.mainledger.Debit ? parseNum(this.mainledger.Debit) : 0
      this.mainledger.Credit = this.mainledger.Credit ? parseNum(this.mainledger.Credit) : 0
      this.mainledger.DebitForegin = this.mainledger.DebitForegin ? parseNum(this.mainledger.DebitForegin) : 0
      this.mainledger.CreditForegin = this.mainledger.CreditForegin ? parseNum(this.mainledger.CreditForegin) : 0
      this.mainledger.DebitTotal = this.mainledger.DebitTotal ? parseNum(this.mainledger.DebitTotal) : 0
      this.mainledger.CreditTotal = this.mainledger.CreditTotal ? parseNum(this.mainledger.CreditTotal): 0
      this.mainledger.DebitForeginTotal = this.mainledger.DebitForeginTotal ? parseNum(this.mainledger.DebitForeginTotal) : 0
      this.mainledger.CreditForeginTotal = this.mainledger.CreditForeginTotal ? parseNum(this.mainledger.CreditForeginTotal) : 0
      this.mainledger.WareHouseId = this.mainledger.WareHouseId ? this.mainledger.WareHouseId : 0
      this.mainledger.WareHouseEntry = this.mainledger.WareHouseEntry ? this.mainledger.WareHouseEntry : 0
      this.mainledger.WareHouseEntryAmount = this.mainledger.WareHouseEntryAmount ? this.mainledger.WareHouseEntryAmount : 0
      this.mainledger.Goods = this.mainledger.Goods ? parseNum(this.mainledger.Goods) : 0
      this.mainledger.Services = this.mainledger.Services ? parseNum(this.mainledger.Services) : 0
      this.mainledger.GoodsEU = this.mainledger.GoodsEU ? parseNum(this.mainledger.GoodsEU) : 0
      this.mainledger.ServicesEU = this.mainledger.ServicesEU ? parseNum(this.mainledger.ServicesEU) : 0
    },
    async mainledgerlist () {
      try {
        await this.$router.push({
          name: 'mainledger'
        })
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    mainledgerupload() {
      this.showUpload = !this.showUpload
    },
    async mainledgeragain () {
      try {
        this.$store.dispatch('setpathAgain', 'mainledgercreate')
        await this.$router.push({
          name: 'mainledger'
        })
        // Object.assign(this.$data, this.$options.data.call(this))
        // location.reload()
        // this.$forceUpdate()
      } catch (err) {
        this.error = err.data
        // eslint-disable-next-line
        console.log(err)
      }
    },
    clearPartner() {
      this.selectPa = null
      this.mainledger.partnerId = null
      this.mainledger.partnerName = null
      this.mainledger.partnerNameShort = null
      this.mainledger.partnerNameDatabase = null
      this.mainledger.partnerBuildingNameNumber = null
      this.mainledger.partnerStreet = null
      this.mainledger.partnerCity = null
      this.mainledger.partnerPostcode = null
      this.mainledger.partnerCountyRegion = null
      this.mainledger.partnerState = null
      this.mainledger.partnerVATID = null
      this.mainledger.partneremail = null
      this.mainledger.partnerPhones = null
      this.mainledger.partnerBankName = null
      this.mainledger.partnerBankAddress = null
      this.mainledger.partnerBankIBAN = null
      this.mainledger.partnerBankSWIFT = null
      this.mainledger.partnerContactTime = null
      this.mainledger.partnerSecretaryTitle = null
      this.mainledger.partnerSecretaryFirst = null
      this.mainledger.partnerSecretaryLast = null
      this.mainledger.partnerSecretaryEmail = null
      this.mainledger.partnerSecretaryPhone = null
      this.mainledger.partnerSecretaryContactOption = null
      this.mainledger.privatePerson = false
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    async addeditPartner () {
        this.mainledger.partnerVATID = this.mainledger.partnerVATID && typeof this.mainledger.partnerVATID === 'string' ? this.mainledger.partnerVATID.trim() : this.mainledger.partnerVATID.toString().trim()
        this.mainledger.partnerName = this.mainledger.partnerName ? this.mainledger.partnerName.trim() : null
        if (this.mainledger.partnerName.length === 0) {
          this.text = 'Partner nema ime! Partner name is not defined!'
          this.color = 'red'
          this.snackbar = true
          return
        }
        if ((typeof this.mainledger.partnerVATID === 'string' && this.mainledger.partnerVATID.trim().length === 0)) {
          this.text = 'Partner nema OIB! Partner VAT ID is not defined!'
          this.color = 'red'
          this.snackbar = true
          return
        }
        const addeditPartner = {...defNewpartner()}
        addeditPartner.CompanyId = this.mainledger.CompanyId ? this.mainledger.CompanyId : ''
        addeditPartner.CompanyNameDatabase = this.mainledger.CompanyNameDatabase
        addeditPartner.BusinessYear = this.mainledger.BusinessYear
        addeditPartner.UserId = this.mainledger.UserId
        addeditPartner.email = this.mainledger.email
        addeditPartner.First = this.mainledger.First
        addeditPartner.Last = this.mainledger.Last
        addeditPartner.partnerName = this.mainledger.partnerName
        addeditPartner.partnerNameShort = this.mainledger.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.mainledger.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.mainledger.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.mainledger.partnerStreet
        addeditPartner.partnerCity = this.mainledger.partnerCity
        addeditPartner.partnerPostcode = this.mainledger.partnerPostcode
        addeditPartner.partnerCountyRegion = this.mainledger.partnerCountyRegion
        addeditPartner.partnerState = this.mainledger.partnerState
        addeditPartner.partnerVATID = this.mainledger.partnerVATID
        addeditPartner.partneremail = this.mainledger.partneremail
        addeditPartner.partnerPhones = this.mainledger.partnerPhones
        addeditPartner.partnerBankName = this.mainledger.partnerBankName
        addeditPartner.partnerBankAddress = this.mainledger.partnerBankAddress
        addeditPartner.partnerBankIBAN = this.mainledger.partnerBankIBAN
        addeditPartner.partnerBankSWIFT = this.mainledger.partnerBankSWIFT
        addeditPartner.partnerContactTime = this.mainledger.partnerContactTime
        addeditPartner.partnerSecretaryTitle = this.mainledger.partnerSecretaryTitle
        addeditPartner.partnerSecretaryFirst = this.mainledger.partnerSecretaryFirst
        addeditPartner.partnerSecretaryLast = this.mainledger.partnerSecretaryLast
        addeditPartner.partnerSecretaryEmail = this.mainledger.partnerSecretaryEmail
        addeditPartner.partnerSecretaryPhone = this.mainledger.partnerSecretaryPhone
        addeditPartner.partnerSecretaryContactOption = this.mainledger.partnerSecretaryContactOption
        addeditPartner.privatePerson = this.mainledger.privatePerson
        this.saving = true
        this.mainQuery2 = {}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.partnerVATID = this.mainledger.partnerVATID
        let toDo = null

        await PartnerService.check(this.mainQuery2)
            .then(res => {
              console.log('res', res)
              if (res.data && res.data.partner) {
                toDo = 'update'
                addeditPartner.id = res.data.partner.id
                this.mainledger.partnerId = res.data.partner.id
              }
              if (res.data && !res.data.partner) {
                toDo = 'addNew'
              }             
            })
            .catch(err => {
              toDo = 'addNew'
              this.error = err.data
              this.localError = false
            })

        if (toDo === 'update') {
            const updatedPartner = await PartnerService.put(addeditPartner)
            console.log('Update partner ', updatedPartner)
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
            this.selectId = this.mainledger.partnerId ? parseInt(this.mainledger.partnerId) : null    
            this.selectPa = this.selectId ? this.partners.find(partner => (partner.id === this.selectId)) : null
        }
        
        if (toDo === 'addNew') {
         
            const newPartner = await PartnerService.post(addeditPartner)
            this.selectPa = {}
            this.selectPa.id = newPartner.data.partner.id ? newPartner.data.partner.id : null
            this.selectPa.partnerName = newPartner.data.partner.partnerName ? newPartner.data.partner.partnerName : null
            this.mainledger.partnerId = newPartner.data.partner.id ? newPartner.data.partner.id : null
            this.text = this.lang.Partneradded
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
            this.findPartner()
           
            // this.selectId = this.mainledger.partnerId ? parseInt(this.mainledger.partnerId) : null
            //this.selectPa = this.selectId ? this.partners.find(partner => (partner.partnerVATID === newPartner.data.partner.partnerVATID)) : null
        }
            
        this.saving = false
    },
    copyDate() {
      if ((this.mainledger.BookingDate !== this.mainledger.InvoiceDate) && this.$store.state.editItem === 0) {
        this.mainledger.InvoiceDate = this.mainledger.BookingDate ? this.mainledger.BookingDate : dayjs(new Date()).format('YYYY-MM-DD')
        const haveYear = parseInt(this.mainledger.InvoiceDate.toString().substr(0,4))
        const haveMonth = parseInt(this.mainledger.InvoiceDate.toString().substr(5,2)) - 1
        const haveDay = parseInt(this.mainledger.InvoiceDate.toString().substr(8,2))
        this.mainledger.InvoicePaymentDeadlineDate = dayjs(new Date(haveYear, haveMonth, haveDay)).add(15, 'days').format('YYYY-MM-DD')
      }
    },
    paidCheck() {
      if (this.mainledger.paidClosed) {
        if (round(parseFloat(this.mainledger.InvoiceAmount),2) !== 0 && !this.mainledger.paidDate) {
          this.mainledger.paidHome = this.mainledger.InvoiceAmount
          this.mainledger.paidDate = new Date().toISOString().substr(0, 10) 
        }
      }
      if (!this.mainledger.paidClosed) {
          this.mainledger.paidAmount = 0
          this.mainledger.paidFore = 0
          this.mainledger.paidDate = null
      }
    }
  },
  watch: {
    'mainledger.BookingDate' () {
      this.copyDate()
    },
    'mainledger.paidClosed' () {
      this.paidCheck()
    },
    'mainledger.VAT1Amount' () {
      this.calculateFirstVAT()
    },
    'mainledger.VAT1PerCanUse' () {
      this.calculateFirstVAT()
    },
    'mainledger.VAT2Amount' () {
      this.calculateSecondVAT()
    },
    'mainledger.VAT2PerCanUse' () {
      this.calculateSecondVAT()
    },
    'mainledger.VAT3Amount' () {
     this.calculateThirdVAT()
    },
    'mainledger.VAT3PerCanUse' () {
      this.calculateThirdVAT()
    },
    'mainledger.InvoiceAmount' () {
      if (this.mainledger.InvoiceAmount && this.mainledger.InvoiceAmount !==0) {
        this.mainledger.InvoiceForeginAmount = this.round((this.mainledger.InvoiceAmount / this.mainledger.ExRate),2)

        this.calculateFirstAmount()
      }
    },
    'mainledger.partnerNotInVAT' () {
      this.showVAT= this.mainledger.partnerNotInVAT ? false : true
    },
    // 'mainledger.InvoiceForeginAmount' () {
    //   this.calculateFirstAmount()
    // },
    'mainledger.VAT4Base' () {
      this.calculateVATAmount()
    },
    'selectPa' () {
      if (this.selectPa) {
        this.findPartner()
      }
      if (!this.selectPa) {
        this.mainledger.partnerId = null
        this.mainledger.partnerName = null
        this.mainledger.partnerNameShort = null
        this.mainledger.partnerNameDatabase = null
        this.mainledger.partnerBuildingNameNumber = null
        this.mainledger.partnerStreet = null
        this.mainledger.partnerCity = null
        this.mainledger.partnerPostcode = null
        this.mainledger.partnerCountyRegion = null
        this.mainledger.partnerState = null
        this.mainledger.partnerVATID = null
        this.mainledger.partneremail = null
        this.mainledger.partnerPhones = null
        this.mainledger.partnerBankName = null
        this.mainledger.partnerBankAddress = null
        this.mainledger.partnerBankIBAN = null
        this.mainledger.partnerBankSWIFT = null
      }
    },
    'bookTypeSelected' () {
      if (this.bookTypeSelected && this.bookTypeSelected.value) {
        this.mainledger.BookTypeID = parseInt(this.bookTypeSelected.value)
        this.mainledger.BookName = this.bookTypeSelected.text
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
