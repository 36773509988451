<template>
  <div>
    <v-container fluid grid-list-md>
    <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
    <v-card>
      <v-card-title>
        <span v-if="this.$store.state.language == 'EN'">
          Employee list
        </span>
        <span v-if="this.$store.state.language == 'HR'">
          Radnici
        </span>
        <v-spacer></v-spacer>
        <span v-if="this.$store.state.language == 'EN'">
         Employee total: {{totals.total}}  Activ: <strong>{{totals.totalActiv}}</strong>  Full time/day: <strong>{{totals.totalFullTime}} (hours:{{totals.totalFullTimeWH}})</strong>  Part time/day: <strong>{{totals.totalPartTime}} (hours:{{totals.totalPartTimeWH}})</strong>
        </span>
        <span v-if="this.$store.state.language == 'HR'">
          Radnici ukupno: {{totals.total}}  Aktivnih: <strong>{{totals.totalActiv}}</strong>  Dnevno puno r.v.: <strong>{{totals.totalFullTime}} (sati:{{totals.totalFullTimeWH}})</strong>   Dnevno nepuno r.v.: <strong>{{totals.totalPartTime}} (sati:{{totals.totalPartTimeWH}})</strong>
        </span>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        item-key="id"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :items-per-page="50"
        @click:row="navigateTo"
        :mobile-breakpoint="0" 
      >
        <template v-slot:[`item.Activ`]="{ item }">
          <span v-if="item.Activ"><v-icon>mdi-check</v-icon></span>
        </template>
        <template v-slot:[`item.Black`]="{ item }">
          <span v-if="item.Black"><v-icon>mdi-check</v-icon></span>
        </template>
        <template v-slot:[`item.DateOfBirht`]="{ item }">
              <span>{{ localDate(item.DateOfBirht) }}</span>
          </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
          From {{ pageStart }} to {{ pageStop }}
        </template>
      </v-data-table>
    </v-card>
    </v-container>
  </div>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import dateFormat from '@/snippets/dateFormat'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'employeelist',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [],
      mainQuery: {
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      totals: {}
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      // if (this.$store.state.businessYear) {
      //   this.mainQuery.businessYear = this.$store.state.businessYear
      // }
      this.$store.dispatch("seteditItem", 0)

      this.saving = true
      await EmployeeService.index(this.mainQuery)
      .then(res => {
        if (res.data && res.data.employees) {
          this.items = res.data.employees
        }
      })
      .catch (err => {
        // eslint-disable-next-line
        console.log(err)
      })
      await EmployeeService.choose2(this.mainQuery)
      .then(res => {
        if (res.data && res.data.totalEmp) {
          this.totals = res.data.totalEmp
        }
      })
      .catch (err => {
        // eslint-disable-next-line
        console.log(err)
      })
      this.saving = false

      if (this.$store.state.language === 'EN') {
        // console.log(' radim push 1 naziva', this.$store.state.language)
        this.headers.push(
          {text: 'Last name', value: 'eLast', width: '150px'},
          {text: 'First name', value: 'eFirst', width: '150px'},
          {text: 'email', value: 'eemail', width: '150px'},
          {text: 'Phone', value: 'phone', width: '150px'},
          {text: 'VAT id', value: 'VatID', width: '150px'},
          {text: 'Birth date', value: 'DateOfBirht', width: '50px'},
          {text: 'Activ', value: 'Activ', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Offline', value: 'Black', type: 'boolean', formatFn: this.formatFn, width: '50px'},
          {text: 'Company ID', value: 'CompanyId', type: 'number', width: '50px'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'}
        )
      }
      if (this.$store.state.language === 'HR') {
        // console.log(' radim push 2 hr naziva ', this.$store.state.language)
        this.headers.push(
          {text: 'Prezime i ime', value: 'employeeFullName', width: '300px'},
          {text: 'email', value: 'eemail', width: '150px'},
          {text: 'Telefon', value: 'phone', width: '150px'},
          {text: 'OIB', value: 'VatID', width: '150px'},
          {text: 'Datum rođenja', value: 'DateOfBirht', width: '50px'},
          {text: 'Aktivan', value: 'Activ', type: 'boolean', formatFn: this.formatFnHR, width: '50px'},
          {text: 'Honorar', value: 'Black', type: 'boolean', formatFn: this.formatFnHR, width: '50px'},
          {text: 'Company ID', value: 'CompanyId', type: 'number', width: '50px'},
          {text: 'DbId', value: 'id', type: 'number', width: '50px'}
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    navigateTo (params) {
      // eslint-disable-next-line
      console.log('params', params)
      this.$store.dispatch('setActionType', 1)
      this.$store.dispatch('setEmployeeid', params.id)
      const head = {}
      head.documentId = params.id
      head.documentName = this.$store.state.documentActiv
      head.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + params.id.toString() + '-' + params.eemail 
      this.$store.dispatch('setDocumentActivHead', head)
      this.$router.push({
        name: 'employeeview'
      })
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    formatFn (value) {
      if (value === true) {
        return 'Yes'
      } else {
        return ''
      }
    },
    formatFnHR (value) {
      if (value === true) {
        return 'Da'
      } else {
        return ''
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
