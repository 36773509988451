<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                     Rb.  Vrsta
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Broj dok.
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Orig.račun
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum knjiženja
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dokumenta
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dospijeća
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Konto
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Potražuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                    </v-flex>

              </v-layout>

                <v-layout row v-for="subitem in detItems" :key="subitem.subLine">
                    <v-flex xs1 class="text-left" >
                      {{ subitem.itBookName }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ subitem.itInvoice ? subitem.itInvoice : '' }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ subitem.InvoiceNumber ? subitem.InvoiceNumber : '' }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(subitem.BookingDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(subitem.InvoiceDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(subitem.InvoicePaymentDeadlineDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-center" style="font-size:12px;">
                      {{ subitem.Description }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ subitem.account4 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseNum2(subitem.totalDebit), 2) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseNum2(subitem.totalCredit), 2) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseNum2(subitem.CreditDebit), 2) }}
                    </v-flex>
                    

            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs8 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total5) }}
              </v-flex>

            </v-layout>

      </v-flex>

    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import { dateFormat, round, parseNum } from '@/snippets/allSnippets'

export default {
  name: 'mainledgercard10detail',
  props: ['detItems', 'total1', 'total2', 'total5'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      printType: 1,
      Total1: 0,
      Total2: 0,
      Total5: 0
    }
  },
  components: {

  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    this.Total1 = round(parseNum(this.total1),2)
    this.Total2 = round(parseNum(this.total2),2)
    this.Total5 = round(parseNum(this.total5),2)
  },
  computed: {

  },
  methods: {
    print(type){
      this.printType = type
      this.$refs.prnt.print()
    },
    round2(param) {
      return round(param)
    },
    parseNum2(param) {
      return parseNum(param)
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
