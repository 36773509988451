<template>
  <v-container fluid>
    <v-row>
       <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
          <h2>
          {{lang.EmployeeDetails}}
        </h2>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" class="pr-3">
        <span v-if="this.$store.state.user.AccessLevel == 1">
            <v-btn text color="red" @click="employeeDelete" class="mr-2">
                {{langC.Delete}}
            </v-btn>
          </span>
         <span v-if="this.$store.state.user.AccessLevel <= 3">
            <v-btn color="primary" @click="employeeEdit3" class="mr-2">
                {{lang.Edit2}}
            </v-btn>
          </span>
          <span v-if="this.$store.state.user.AccessLevel <= 3">
            <v-btn color="primary" @click="employeeEdit2" class="mr-2">
                {{lang.Edit3}}
            </v-btn>
          </span>
              
          <span v-if="this.$store.state.user.AccessLevel <= 3">
            <v-btn color="primary" @click="employeeEdit" class="mr-2">
                {{langC.Edit}}
            </v-btn>
          </span>
          <span v-if="this.$store.state.user.AccessLevel <= 3">
            <v-btn color="primary" @click="empPrint" class="mr-2">
                {{langC.Print}}
            </v-btn>
          </span>
          
          <v-btn color="green" @click="employeeList">
              {{langC.Back}}
          </v-btn>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-card >
          <v-card-title primary class="title py-2">{{lang.BasicData}}</v-card-title>
            <v-card-text>


              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.CompanyName}} 
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.CompanyNameDatabase}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    <span v-if="this.$store.state.language == 'EN'">
                    Activ:
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Aktivno zaposlen:
                    </span>  
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                  <span v-if="employee.Activ">
                    <v-icon>mdi-check</v-icon>
                  </span>

                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    Hashtag:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.hashtag}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.First}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.First}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.Last}} 
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Last}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    email:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.eemail}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.phone}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.phone}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">                    
                   {{lang.Gender}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Gender}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.VatID}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.VatID}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.PersonalID1}}    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PersonalID1}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.PersonalID2}}    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PersonalID2}}</span>
                </v-flex>
              </v-row>

              <v-row>
               <v-flex xs4 class="py-0 px-2">
                    {{lang.DateOfBirht}}                       
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locDateOfBirht}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.PlaceOfBirht}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PlaceOfBirht}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                   {{lang.PlaceOfBirthState}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PlaceOfBirthState}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.Foriner}}                    
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Foriner}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{lang.ForinerLicense}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.ForinerLicense}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                   {{lang.PlaceOfLive}}
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PlaceOfLive}}</span>
                </v-flex>
              </v-row>
            </v-card-text>
            <DigitalDocShortList />
          </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-card>
          <v-card-title primary class="title">          
              {{lang.WorkEnvironment}}                 
          </v-card-title>
          <v-card-text>
              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.WorkBefore}}
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{lang.WorkYearsBefore}}:{{employee.WorkYearsBefore}}-
                        {{lang.WorkMonthsBefore}}:{{employee.WorkMonthsBefore}}-
                        {{lang.WorkDaysBefore}}:{{employee.WorkDaysBefore}}
                    </span>
                </v-flex>
              </v-row>            

              <v-row >
                <v-flex xs6 class="py-0 px-2">
                    {{lang.WorkInCityCodeID}}
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkInCityCode}}, 
                      {{employee.WorkInCityName}} 
                    </span>
                </v-flex>
              </v-row>           

              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.Pregnancy}}                    
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Pregnancy}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.Maternity}}                     
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Maternity}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.Breastfeeding}}                     
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Breastfeeding}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.OnlyParent}}                      
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.OnlyParent}}</span>
                </v-flex>
              </v-row>


              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.AdoptionStatus}}                     
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.AdoptionStatus}}</span>
                </v-flex>
              </v-row>


              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.ProfessionalIllnes}}                     
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.ProfessionalIllnes}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.HurtOnWork}}                     
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.HurtOnWork}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.ProfessionalCantWork}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.ProfessionalCantWork}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.ProfessionalLowerAbbilities}}                    
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.ProfessionalLowerAbbilities}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.ProfessionalLoseWrokAbbility}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.ProfessionalLoseWrokAbbility}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.DangerOfLosingProfAbbilityInjury}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.DangerOfLosingProfAbbilityInjury}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.DangerOfHaveDisability}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.DangerOfHaveDisability}}</span>
                </v-flex>
              </v-row>


              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.Disability}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Disability}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.DisabilityPension}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.DisabilityPension}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.DisabilityWorkTIme}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.DisabilityWorkTIme}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs10 class="py-0 px-2">
                    {{lang.WorkForOtherEmployee}}                     
                </v-flex>
                <v-flex xs2 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkForOtherEmployee}}</span>
                </v-flex>
              </v-row>


              <v-row>
                <v-flex xs6 class="py-0 px-2">
                    {{lang.PersonalData2}}                      
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.PersonalHeight}}, {{employee.PersonalSizeUp}}, {{employee.PersonalSizeDown}}, {{employee.PersonalShoes}}</span>
                </v-flex>
              </v-row>                                                                                                                                                                                                                                                                                                        
            </v-card-text>
          </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-card >
              <v-card-title primary class="title">
                  
                    <span v-if="this.$store.state.language == 'EN'">
                    Financial data
                    </span>
                    <span v-if="this.$store.state.language == 'HR'">
                    Financijski podaci
                    </span>                    
                  </v-card-title>
          <v-card-text>
              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    Bruto 1:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinBruto1}}</span>
                </v-flex>
              </v-layout>  

              <v-layout row  v-if="employee.FinKoef1 > 0">
                <v-flex xs4 class="py-0 px-2">
                    Koef 1:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinKoef1Desc}} {{employee.FinKoef1}}</span>
                </v-flex>
              </v-layout>  

              <v-layout row  v-if="employee.FinKoef2 > 0">
                <v-flex xs4 class="py-0 px-2">
                    Koef 2:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinKoef2Desc}} {{employee.FinKoef2}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinKoef3 > 0">
                <v-flex xs4 class="py-0 px-2">
                    Koef 3:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold"> {{employee.FinKoef3}}</span>
                </v-flex>
              </v-layout>        

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    Dohodak:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinBruto2}}</span>
                </v-flex>
              </v-layout>                        

              <v-layout row  v-if="employee.FinFrom1Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFrom1Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFrom1Per}}% = {{employee.FinFrom1Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinFrom2Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFrom2Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFrom2Per}}% = {{employee.FinFrom2Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinFrom3Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFrom3Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFrom3Per}}% = {{employee.FinFrom3Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinFrom4Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFrom4Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFrom4Per}}% = {{employee.FinFrom4Am}}</span>
                </v-flex>
              </v-layout>                                           

              <v-layout row  v-if="employee.FinFrom5Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFrom5Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFrom5Per}}% = {{employee.FinFrom5Am}}</span>
                </v-flex>
              </v-layout> 


              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    Neto1:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinNeto1}}</span>
                </v-flex>
              </v-layout>  

             <v-layout row  v-if="employee.FinFree1Indeks > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFree1Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFree1Indeks}} * {{employee.FinFree1OnAm}} = {{employee.FinFree1Am}}</span>
                </v-flex>
              </v-layout> 


             <v-layout row  v-if="employee.FinFree2Indeks > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFree2Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFree2Indeks}} * {{employee.FinFree2OnAm}} = {{employee.FinFree1Am}}</span>
                </v-flex>
              </v-layout> 


             <v-layout row  v-if="employee.FinFree3Indeks > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinFree3Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinFree3Indeks}} * {{employee.FinFree3OnAm}} = {{employee.FinFree1Am}}</span>
                </v-flex>
              </v-layout>      

             <v-layout row  v-if="employee.FinFreeTotalAm > 0">
                <v-flex xs4 class="py-0 px-2">
                    Total indeks = {{employee.FinFreeTotalIndeks}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">Total free amount  = {{employee.FinFreeTotalAm}}</span>
                </v-flex>
              </v-layout> 

             <v-layout row  v-if="employee.FinTax1Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    Tax from {{employee.FinTax1Am}} to {{employee.FinTax1Am}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTax1Per}} % = {{employee.FinTax1Am}}</span>
                </v-flex>
              </v-layout>

             <v-layout row  v-if="employee.FinTax2Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    Tax from {{employee.FinTax2Am}} to {{employee.FinTax2Am}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTax2Per}} % = {{employee.FinTax2Am}}</span>
                </v-flex>
              </v-layout> 

             <v-layout row  v-if="employee.FinTax3Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    Tax from {{employee.FinTax3Am}} to {{employee.FinTax3Am}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTax3Per}} % = {{employee.FinTax3Am}}</span>
                </v-flex>
              </v-layout>                             

             <v-layout row  v-if="employee.FinTaxOnTaxPer > 0">
                <v-flex xs4 class="py-0 px-2">
                    Tax on tax:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTaxOnTaxPer}} % = {{employee.FinTaxOnTaxAm}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row v-if="employee.FinTaxOnTaxPer > 0">
                <v-flex xs6 class="py-0 px-2">
                    Tax on tax for City/County:
                </v-flex>
                <v-flex xs6 class="py-0 px-2">
                    <span style="font-weight:bold">Code:{{employee.FinTaxOnTaxCityCode}} 
                      Name:{{employee.FinTaxOnTaxCityName}} 
                    </span>
                </v-flex>
              </v-layout>


              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    Neto2:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinNeto2}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinTravelAm > 0">
                <v-flex xs4 class="py-0 px-2">
                    Travel:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTravelAm}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinOther1Am > 0">
                <v-flex xs4 class="py-0 px-2">
                    Other bonus:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinOther1Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinOther2Am > 0">
                <v-flex xs4 class="py-0 px-2">
                    Other bonus:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinOther2Am}}</span>
                </v-flex>
              </v-layout>                             

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    Neto3:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinNeto3}}</span>
                </v-flex>
              </v-layout>               

              <v-layout row  v-if="employee.FinTo1Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinTo1Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTo1Per}}% = {{employee.FinTo1Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinTo2Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinTo2Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTo2Per}}% = {{employee.FinTo2Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinTo3Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinTo3Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTo3Per}}% = {{employee.FinTo3Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row  v-if="employee.FinTo4Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinTo4Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTo4Per}}% = {{employee.FinTo4Am}}</span>
                </v-flex>
              </v-layout>                                           

              <v-layout row  v-if="employee.FinTo5Per > 0">
                <v-flex xs4 class="py-0 px-2">
                    {{employee.FinTo5Desc}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinTo5Per}}% = {{employee.FinTo5Am}}</span>
                </v-flex>
              </v-layout> 

              <v-layout row >
                <v-flex xs4 class="py-0 px-2">
                    FinBruto3:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.FinBruto3}}</span>
                </v-flex>
              </v-layout>  


             <v-layout row  v-if="employee.Fin1bankAccountNr1 > 0">
                <v-flex xs4 class="py-0 px-2">
                    Bank - {{employee.Fin1bankAccountName}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Fin1bankAccountNr1}}</span>
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    call on:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Fin1bankAccountNr2}} ___ {{employee.Fin1bankAccountNr3}}</span>
                </v-flex>                
              </v-layout> 

             <v-layout row  v-if="employee.Fin2bankAccountNr1 > 0">
                <v-flex xs4 class="py-0 px-2">
                    Bank protected - {{employee.Fin2bankAccountName}}:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Fin2bankAccountNr1}}</span>
                </v-flex>
                <v-flex xs4 class="py-0 px-2">
                    call on:
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Fin2bankAccountNr2}} ___ {{employee.Fin2bankAccountNr3}}</span>
                </v-flex>                
              </v-layout> 

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-card >
              <v-card-title primary class="title">
                    {{lang.WorkEnvironment}}
                  </v-card-title>
            <v-card-text>      
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.Occupation}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Occupation}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.Degree}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.Degree}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.SpecialKnowledge}}
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.SpecialKnowledge}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.StartInCompany}}     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locStartInCompany}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.WorkForOtherCompany}}             
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkForOtherCompany}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.WorkForOtherCompanyAbroad}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkForOtherCompanyAbroad}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                   {{lang.WorkForOtherConnectedCompany}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkForOtherConnectedCompany}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.WorkHardConditions}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkHardConditions}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.WorkPlace}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WorkPlace}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                   {{lang.WeeklyWorkHours}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.WeeklyWorkHours}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                   {{lang.NotWorkingTime}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.NotWorkingTime}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.EndDateInCompany}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locEndDateInCompany}}</span>
                </v-flex>
              </v-row>
              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.EndReason}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.EndReason}}</span>
                </v-flex>
              </v-row>

              <v-row >
                <v-flex xs4 class="py-0 px-2">
                    {{lang.OtherData}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{employee.OtherData}}</span>
                </v-flex>
              </v-row>

              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{langC.CreatedAt}}                     
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locCreatedAt}}</span>
                </v-flex>
              </v-row>
              <v-row>
                <v-flex xs4 class="py-0 px-2">
                    {{langC.UpdatedAt}}                      
                </v-flex>
                <v-flex xs8 class="py-0 px-2">
                    <span style="font-weight:bold">{{locUpdatedAt}}</span>
                </v-flex>
              </v-row>              
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">
        <v-card >
              <v-card-title primary class="title">
                    {{lang.WorkStatus}}     
                </v-card-title>

                <v-data-table
                    :headers="headers2"
                    :items="items2"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    :items-per-page="50"
                >
                 <template v-slot:[`item.OpenDate`]="{ item }">
                    <span>{{ localDate(item.OpenDate) }}</span>
                </template>
                <template slot="pageText" slot-scope="{ pageStart, pageStop }">
                    From {{ pageStart }} to {{ pageStop }}
                </template>
                </v-data-table>

            </v-card>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pr-3">

      </v-col>
    </v-row>
    <EmployeePrint ref="prnt" />
  </v-container>

</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import EmplDayService from '@/services/EmplDayService'
import DigitalDocShortList from '../DigitalDoc/DigitalDocShortList.vue'
import EmployeePrint from './EmployeePrint'
import dateFormat from '@/snippets/dateFormat'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dayjs from 'dayjs'

export default {
  name: 'employeeview',
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      employee: { },
      employeeActivData: { },
      tasksEmp: { },
      locStartFrom: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locLastLogin: '',
      locForgotDate: '',
      haveRight: 4,
      error: '',
      items: [],
      items2: [],
      headers: [ ],
      headers2: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        employeeid: 0
      },
      locDateOfBirht: null,
      locStartInCompany: null,
      locEndDateInCompany: null,
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = langEn
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = langHr
      this.langC = commHr;
    }
    if (this.$store.state.employeeid) {
      try {
        if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.mainQuery.businessYear = this.$store.state.businessYear
        }
        if (this.$store.state.employeeid) {
          this.mainQuery.employeeid = this.$store.state.employeeid
        }
        const dbEmployee = await EmployeeService.show(this.$store.state.employeeid)
        this.employee = dbEmployee.data.employee
        this.items2 = (await EmplDayService.choose2(this.mainQuery)).data.employeOne
        this.locCreatedAt = dayjs(this.employee.createdAt).format('DD.MM.YYYY HH:MM')
        this.locUpdatedAt = dayjs(this.employee.updatedAt).format('DD.MM.YYYY HH:MM') + (this.employee.LastUpdateBy ? ', ' + this.employee.LastUpdateBy : '')
        this.locDateOfBirht = this.employee.DateOfBirht ? dayjs(this.employee.DateOfBirht).format('DD.MM.YYYY') : ''
        this.locStartInCompany = this.employee.StartInCompany ? dayjs(this.employee.StartInCompany).format('DD.MM.YYYY') : ''
        this.locEndDateInCompany =this.employee.EndDateInCompany ? dayjs(this.employee.EndDateInCompany).format('DD.MM.YYYY') : ''
        if (this.$store.state.language === 'EN') {
          this.headers2.push(
            {
              text: 'Date',
              align: 'left',
              sortable: true,
              value: 'OpenDate'
            },
            {
              text: 'Action Type Name',
              align: 'left',
              sortable: true,
              value: 'ActionTypeName'
            },
                { text: 'Working Hours Type Name', align: 'left', value: 'WorkingHoursTypeName' },
                { text: 'Working Hours', align: 'left', value: 'WorkingHours' },
                { text: 'hashtag', align: 'left', value: 'Employeehashtag' },
                { text: 'DbId', value: 'id' }
          )
        }
        if (this.$store.state.language === 'HR') {
          this.headers2.push(
            {
              text: 'Datum',
              align: 'left',
              sortable: true,
              value: 'OpenDate'
            },
            {
              text: 'Vrsta',
              align: 'left',
              sortable: true,
              value: 'ActionTypeName'
            },
                { text: 'Radni sati tip', align: 'left', value: 'WorkingHoursTypeName' },
                { text: 'Radni sati', align: 'left', value: 'WorkingHours' },
                { text: 'hashtag', align: 'left', value: 'Employeehashtag' },
                { text: 'DbId', value: 'id' }
          )
        }
      } catch (error) {
        this.error = error
      }
    }
  },
  methods: {
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    async employeeList () {
      try {
        await this.$router.push({
          name: 'employee'
        })
      } catch (err) {
        this.error = err
      }
    },
    async employeeDelete () {
      try {
        await this.$router.push({
          name: 'employeedelete'
        })
      } catch (error) {
        this.error = error
      }
    },
    async employeeEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'employeecreate'
        })
      } catch (error) {
        this.error = error
      }
    },
    async employeeEdit2 () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'employeedit2'
        })
      } catch (error) {
        this.error = error
      }
    },
    async employeeEdit3 () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'employeedit3'
        })
      } catch (error) {
        this.error = error
      }
    },
    async employeeAddTask () {
      try {
        this.employeeActivData = this.employee
        this.$store.dispatch('setEmployeeActiv', this.employeeActivData)
        await this.$router.push({
          name: 'taskcreateemployee'
        })
      } catch (error) {
        this.error = error
      }
    },
    empPrint() {
      this.$refs.prnt.print(this.employee)
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    DigitalDocShortList,
    EmployeePrint
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
