<template>
  <v-navigation-drawer
      v-if="$store.state.isUserLoggedIn"
      v-model="locDrawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
 
    >

   <v-btn block color="blue lighten-5" @click="dashboard"> {{mainDashboard}} </v-btn>

    <v-list nav dense>
      <v-list-group
        v-for="item in items"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.action"
        no-action
      >
        <template v-slot:activator>
        
          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subItem in item.items"
          :key="subItem.title"
          @click="markMe(subItem.path, subItem.doc, subItem.docLoc, subItem.docSide)"
        >
          <v-list-item-content>
            <v-list-item-title>{{subItem.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    </v-navigation-drawer>

</template>

<script>

import langEn from './components/mainDescEN'
import langHr from './components/mainDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { mapGetters } from 'vuex'

export default {
  name:'navigation',
  data() {
    return {
      clipped: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Roboot.net',
      mainDashboard: 'Dashboard',
      items: [],
      lang: {},
      langC: {},
      currentPath: '',
      currentDoc: '',
      Sales: {},
      Purchase: {},
      CRM: {},
      Finance: {},
      FinanceSmall: {},
      FinanceAdvanced: {},
      HumanResources: {},
      Administration: {},
      servicepath : '',
      locDrawer: null
    }
  },
  mounted() {
   this.initalize()
  },
  methods: {
    initalize() {
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }

      this.Sales =  {
            action: 'mdi-chart-timeline-variant-shimmer',
            title: this.lang.Sales,
            model: false,
            items: [
              { title: this.lang.Inquirys, path: '/crm/document', doc: 'InquirySales', docLoc: this.lang.Inquirys, docSide: 3 },
              { title: this.lang.Quotes, path: '/crm/document', doc: 'QuoteSales', docLoc: this.lang.Quotes, docSide: 3 },
              { title: this.lang.Order, path: '/crm/document', doc: 'OrderSales', docLoc: this.lang.Order, docSide: 3 },
              { title: this.lang.DeliveryNote, path: '/crm/document', doc: 'DeliveryNoteSales', docLoc: this.lang.DeliveryNote, docSide: 2 },
              { title: this.lang.InternalDeliveryNotes, path: '/crm/document', doc: 'InternalDeliveryNotes', docLoc: this.lang.InternalDeliveryNotes, docSide: 2 },
              { title: this.lang.ServiceNotes, path: '/crm/document' , doc: 'ServiceNotes', docLoc: this.lang.ServiceNotes, docSide: 2 },
              { title: this.lang.InvoicesAdvance, path: '/crm/document', doc: 'InvoiceAdvance', docLoc: this.lang.InvoicesAdvance, docSide: 3 },
              { title: this.lang.Invoices, path: '/crm/document', doc: 'InvoiceSales', docLoc: this.lang.Invoices, docSide: 3 },
              { title: this.lang.Contracts, path: '/crm/document', doc: 'ContractSales', docLoc: this.lang.Contracts, docSide: 3 },
              { title: this.lang.Production, path: '/crm/document/production', doc: 'WorkingOrder', docLoc: this.lang.Production, docSide: 3 },
              { title: this.lang.POS, path: '/crm/document/pos', doc: 'POS', docLoc: this.lang.POS, docSide: 2 },
              { title: this.lang.Analysis, path: '/crm/document/salesanalysis', doc: 'SaleAnalysis', docLoc: this.lang.Analysis, docSide: 3 },
              { title: this.lang.Serials, path: '/crm/serials', doc: 'Serials', docLoc: this.lang.Serials, docSide: 3 }
            ]
          }
      this.Purchase = {
            action: 'mdi-warehouse',
            title: this.lang.Purchase,
            items: [
              { title: this.lang.Inquirys, path: '/crm/document', doc: 'RequestPurchase', docLoc: this.lang.Inquirys, docSide: 3 },
              { title: this.lang.Requests, path: '/crm/document', doc: 'InquiryPurchase', docLoc: this.lang.Requests, docSide: 3 },
              { title: this.lang.Order, path: '/crm/document', doc: 'OrderPurchase', docLoc: this.lang.Order, docSide: 3 },
              { title: this.lang.CalculationsPurchase, path: '/crm/document', doc: 'ReceiptPurchase', docLoc: this.lang.CalculationsPurchase, docSide: 1 },
              { title: this.lang.Returns, path: '/crm/document', doc: 'ReturnPurchase', docLoc: this.lang.Returns, docSide: 2 },
              { title: this.lang.Inventory, path: '/crm/document', doc: 'Inventory', docLoc: this.lang.Inventory, docSide: 1 },
              { title: this.lang.PriceChange, path: '/crm/document', doc: 'PriceChange', docLoc: this.lang.PriceChange, docSide: 3 },
              { title: this.lang.Analysis, path: '/crm/document/purchaseanalysis', doc: 'PurchaseAnalysis', docLoc: this.lang.Analysis, docSide: 3 }
            ]
        }
      this.CRM = {
            action: 'mdi-cube-outline',
            title: this.lang.CRM,
            items: [
              { title: this.lang.Lead, path: '/crm/appinprogress', doc: 'LeadCRM', docLoc: this.lang.Lead, docSide: 3 },
              { title: this.lang.Prospect, path: '/crm/appinprogress', doc: 'ProspectCRM', docLoc: this.lang.Prospect, docSide: 3 },
              { title: this.lang.Opportunity, path: '/crm/appinprogress', doc: 'OpportunityCRM', docLoc: this.lang.Opportunity, docSide: 3 },
              { title: this.lang.ClosedWonOpportunity, path: '/crm/appinprogress', doc: 'ClosedWonCRM', docLoc: this.lang.ClosedWonOpportunity, docSide: 3 },
            ]
        }
        this.Finance = {
            action: 'mdi-finance',
            title: this.lang.Finance,
            items: [
              { title: this.lang.BookInInvoices, path: '/crm/mainledger', doc: 'BookInInvoices', docLoc: this.lang.BookInInvoices, docSide: 1 },
              { title: this.lang.BookOutInvoices, path: '/crm/mainledger', doc: 'BookOutInvoices', docLoc: this.lang.BookOutInvoices, docSide: 2 },
              { title: this.lang.Reports, path: '/crm/mainledger/reports', doc: 'Reports', docLoc: this.lang.Reports, docSide: 6 },
              { title: this.lang.CashDesk, path: '/crm/cashdesk', doc: 'CashDesk', docLoc: this.lang.CashDesk, docSide: 101 },
              { title: this.lang.MainBookComps, path: '/crm/mainbookcomp', doc: 'MainBookComps', docLoc: this.lang.MainBookComps, docSide: 100 },
            ]
        }
        this.FinanceSmall = {
            action: 'mdi-finance',
            title: this.lang.Finance,
            items: [
              { title: this.lang.BookInInvoices, path: '/crm/mainledger', doc: 'BookInInvoices', docLoc: this.lang.BookInInvoices, docSide: 1 },
              { title: this.lang.BookOutInvoices, path: '/crm/mainledger', doc: 'BookOutInvoices', docLoc: this.lang.BookOutInvoices, docSide: 2 },
              { title: this.lang.Reports, path: '/crm/mainledger/reports', doc: 'Reports', docLoc: this.lang.Reports, docSide: 6 },
              { title: this.lang.CashDesk, path: '/crm/cashdesk', doc: 'CashDesk', docLoc: this.lang.CashDesk, docSide: 101 },
              { title: this.lang.MainBooks, path: '/crm/mainbook', doc: 'MainBooks', docLoc: this.lang.MainBooks, docSide: 100 },
            ]
        }
        this.FinanceAdvanced = {
            action: 'mdi-notebook-edit-outline',
            title: this.lang.FinanceAdvanced,
            items: [
              { title: this.lang.Ledger, path: '/crm/mainledger3', doc: 'Ledger', docLoc: this.lang.Ledger, docSide: 3 },
              { title: this.lang.BankStatements, path: '/crm/mainledger4', doc: 'BankStatement', docLoc: this.lang.BankStatements, docSide: 4 },
              { title: this.lang.BalanceSheet, path: '/crm/mainledger/analysis', doc: 'BalanceSheet', docLoc: this.lang.BalanceSheet, docSide: 5 },
              { title: this.lang.OsnovnaSredstva, path: '/crm/mainledgerlla', doc: 'MainLedgerLLA', docLoc: this.lang.OsnovnaSredstva, docSide: 7 },
              { title: this.lang.SmallTools, path: '/crm/mainledgerlla', doc: 'MainLedgerLLA', docLoc: this.lang.SmallTools, docSide: 8 },
              { title: this.lang.Closing, path: '/crm/mainledger', doc: 'Closing', docLoc: this.lang.Closing, docSide: 9 },
              { title: this.lang.LedgerAccounts, path: '/crm/account4', doc: 'Accounts', docLoc: this.lang.LedgerAccounts, docSide: 10 },
              { title: this.lang.Forms, path: '/crm/formindex', doc: 'Form', docLoc: this.lang.Forms, docSide: 11 }
            ]
        }  
      this.HumanResources = {
            action: 'mdi-account-multiple',
            title: this.lang.HumanResources,
            items: [
              { title: this.lang.Emplyees, path: '/crm/employee', doc: 'Emplyee', docLoc: this.lang.Emplyees, docSide: 3 },
              { title: this.lang.WorkHours, path: '/crm/workhour', doc: 'WorkHours', docLoc: this.lang.WorkHours, docSide: 3 },
              { title: this.lang.Salaries, path: '/crm/salarie', doc: 'Salaries', docLoc: this.lang.Salaries, docSide: 3 },
              { title: this.lang.EmployeeDiary, path: '/crm/emplday', doc: 'EmplDay', docLoc: this.lang.EmployeeDiary, docSide: 3 },
              { title: this.lang.Travels, path: '/crm/travelorder', doc: 'Travel', docLoc: this.lang.Travels, docSide: 3 },
              { title: 'Joppd', path: '/crm/joppda', doc: 'Joppd', docLoc: 'Joppd', docSide: 3 }
            ]
        }
      this.Administration = {
            action: 'mdi-cog',
            title: this.lang.Administration,
            items: [
              { title: this.lang.Items, path: '/crm/item', doc: 'Item', docLoc: this.lang.Items, docSide: 3 },
              { title: this.lang.Groups, path: '/crm/group', doc: 'Group', docLoc: this.lang.Groups, docSide: 3 },
              { title: this.lang.Partner, path: '/crm/partner', doc: 'Partner', docLoc: this.lang.Partner, docSide: 3 },
              { title: this.lang.Tasks, path: '/crm/task', doc: 'Task', docLoc: this.lang.Tasks, docSide: 3 },
              { title: this.lang.City, path: '/crm/city', doc: 'City', docLoc: this.lang.City, docSide: 3 },
              { title: this.lang.Settings, path: '/crm/appsettings', doc: 'AppSettings', docLoc: this.lang.Settings, docSide: 3 }
            ]
        }
    },
    initUser() {
      //this.items.length = 0
      this.items.splice(0, this.items.length)
      if (this.$store.state.user) {
        if (this.$store.state.user.sales1 && this.$store.state.user.sales1 === true) {
          this.items.push(this.Sales)
        }
        if (this.$store.state.user.purchase1 && this.$store.state.user.purchase1 === true) {
          this.items.push(this.Purchase)
        }
        if (this.$store.state.user.crm1 && this.$store.state.user.crm1 === true) {
          this.items.push(this.CRM)
        }
        if (this.$store.state.user.finance1 === true && this.$store.state.user.beton === false) {

          const bs4 =   { title: this.lang.BankStatements, path: '/crm/mainledger4', doc: 'BankStatement', docLoc: this.lang.BankStatements, docSide: 4 }
          if (this.$store.state.user.finance4 === true) {
            this.Finance.items.push(bs4)
          }
          this.items.push(this.Finance)
        }
        if (this.$store.state.user.finance1 === true && this.$store.state.user.beton === true) {
          this.items.push(this.FinanceSmall)
        }
        if (this.$store.state.user.finance3 && this.$store.state.user.finance3 === true) {
          this.items.push(this.FinanceAdvanced)
        }
        if (this.$store.state.user.hr1 && this.$store.state.user.hr1 === true) {
          this.items.push(this.HumanResources)
        }
        if (this.$store.state.user.admin1 && this.$store.state.user.admin1 === true) {
          this.items.push(this.Administration)
        }
      }
    },
    login () {
      this.$router.push({
        path: '/'
      }).catch(err => {
        // eslint-disable-next-line
        console.log(err);
      })
    },
    async markMe (path, doc, docLoc, docSide) {
      await this.$store.dispatch('setDocumentActivHead', {})
      await this.$store.dispatch('setDocumentActivItems', [])
      await this.$store.dispatch('setActionType', 0)
      await this.$store.dispatch('setDocumentActiv', doc)
      await this.$store.dispatch('setDocumentActivLoc', docLoc)
      await this.$store.dispatch('setDocumentSide', docSide)
      await this.$store.dispatch('setbookTypeSelected', null)
      const query = {}
      if (this.$store.state.companyid) {
        query.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        query.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        query.documentName = this.$store.state.documentActiv
      }
      // on wrong data, return to logins
      if (!this.$store.state.companyid || !this.$store.state.businessYear) {
        this.$store.dispatch('setBusinessYear', null)
        this.$store.dispatch('setCompanyName', null)
        this.$store.dispatch('setCompanyid', null)
        this.$store.dispatch('setCompanyActiv', null)
        this.$store.dispatch('setToken', null)
        this.$store.dispatch('setUser', null)
        this.$store.dispatch('setDateItems', null)
        this.$store.dispatch('setDatePartners', null)
        this.$store.dispatch('setDateAccounts', null)
        this.$store.dispatch('setWareHouseMarkid', null)
        this.$store.dispatch('setWarehouseState', null)
        this.$store.dispatch('setDocumentSide', null)
        alert('Error! Please login again. Greska! Molim da se ponovno logirate!')
        this.login()
      }
      query.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 0
      query.searchName = null
      query.page =  0
      query.pageSize =  30
      query.searchItem =  null
      await this.$store.dispatch('setOnPage', query)
      this.currentPath = path
      this.currentDoc = doc
      this.$router.push({
        path,
        query: {
          doc
        }
      }).catch(err => {
          // eslint-disable-next-line
          console.log(err)
      })
      
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    dashboard () {
      if (this.$store.state.user) {
        this.$router.push({
          name: 'home'
        }).catch(err => {
          // eslint-disable-next-line
          console.log(err.data)
        })
        // ()=>{}        
      }
    },
  },
  computed: {
    ...mapGetters({
      myUser: 'NeedUserID',
      myLanguage: 'NeedLanguage',
      myMenuShow: 'NeedMenuShow'
    }),
  },
  watch: {
    'myUser' () {
     this.initUser()
    },
    'myLanguage' () {
      this.initalize()
      this.initUser()
    },
    'myMenuShow' () {
      this.locDrawer = this.myMenuShow
    }

  },
  components: {

  }  
}
</script>
