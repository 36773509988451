<template>
<v-form 
ref="form"
v-model="valid"
lazy-validation> 
  <v-container>
    <v-row no-gutters>
        <h2>
          <span v-if="this.$store.state.editItem  === 0">
            {{langC.CreateNew}} {{ documentNameLoc }}
          </span>
          <span v-if="this.$store.state.editItem === 1">
            {{langC.Edit}} {{ documentNameLoc }} {{document.documentId}}
          </span>                  
        </h2>
        <v-progress-circular v-if="this.saving"
          indeterminate
          color="green"
        ></v-progress-circular>
    </v-row>
    <v-divider class="mt-1"></v-divider>

    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="6" lg="9" xl="6"  class="pr-3">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-1">
            <v-text-field
              dense
              :label="langV.MarkType"
              v-model="defaultItem.MarkType"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-1">
            <v-text-field
              dense
              :label="langV.Chasis"
              v-model="defaultItem.Chasis"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"  class="pa-1">
            <v-text-field
              dense
              :label="langV.TypeVeichle"
              v-model="defaultItem.TypeVeichle"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-1">
            <v-text-field
              dense
              :label="langV.Serie"
              v-model="defaultItem.Serie"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pa-1">
            <v-text-field
              dense
              :label="langV.Engine"
              v-model="defaultItem.Engine"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
            <v-text-field
              dense
              :label="langV.ChasisModel"
              v-model="defaultItem.ChasisModel"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
            <v-text-field
              dense
              :label="langV.Registration"
              v-model="defaultItem.Registration"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
            <v-text-field
              dense
              :label="langV.Colour"
              v-model="defaultItem.Colour"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-1">
            <v-text-field
              dense
              :label="langV.Km"
              v-model="defaultItem.Km"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
            <v-text-field
              dense
              :label="langV.MonthManufacture"
              v-model="defaultItem.MonthManufacture"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
            <v-text-field
              dense
              :label="langV.YearManufacture"
              v-model="defaultItem.YearManufacture"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
            <v-text-field
              dense
              :label="langV.StateManufacture"
              v-model="defaultItem.StateManufacture"
              outlined
              :disabled = "!newVehicle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
            <v-checkbox
              :label="lang.dontHaveVehicle"
              v-model="defaultItem.noVehicle"
              outlined
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
         <v-row no-gutters>
            <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8" class="ma-0 pa-0">
            </v-col>
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
              <v-btn text color="primary" @click="showOn6()" class="pb-3 pl-0">{{vehicleAction}} </v-btn>
              <v-btn text color="primary" @click="showOn5()" class="pb-3 pl-0">{{lang.VehiclesShow}}<v-icon v-if="!show55">mdi-chevron-down</v-icon><v-icon v-if="show55">mdi-chevron-up</v-icon></v-btn>
            </v-col>
          </v-row>
          
      </v-col>


      <v-col cols="12" xl="6" lg="6" xs="12" sm="12" md="12" class="pt-2">

            <v-row cols="6" xl="3" lg="3" xs="6" sm="6" md="6"  class="pr-3">

              <v-col cols="6" xl="4" lg="4" xs="6" sm="6" md="6"  class="pr-3">
                <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"                   
                    width="290px"
                    :return-value.sync="document.documentDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      :value="formatDate1"
                      :label="lang.DocumentDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                      outlined
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="document.documentDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(document.documentDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
              </v-col>

              <v-col cols="6" xl="3" lg="3" xs="6" sm="6" md="6"  class="pr-3">
                     <v-dialog
                      ref="dialogT"
                      v-model="modalT"
                      :return-value.sync="document.documentTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="document.documentTime"
                          :label="lang.documentTime"
                          prepend-icon="mdi-clock-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modalT"
                        v-model="document.documentTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalT = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogT.save(document.documentTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
              </v-col>
              <v-col  cols="6" xl="4" lg="4" xs="6" sm="6" md="6" class="p3-0">
                <v-dialog
                
                    ref="dialog3"
                    persistent
                    v-model="modal3"
                    width="290px"
                    :return-value.sync="document.documentInvoiceEndDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      :value="formatDate2"
                      :label="lang.DeadLine"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="document.documentInvoiceEndDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal3 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog3.save(document.documentInvoiceEndDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
              </v-col>
              </v-row> 
              <v-row no-gutters>

              <v-col cols="6" xl="4" lg="4" xs="6" sm="6" md="6"  class="pr-3">
                <v-dialog
                    ref="dialog2"
                    persistent
                    v-model="modal2"
                    width="290px"
                    :return-value.sync="document.documentInvoiceStartDate"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      dense
                      :value="formatDate3"
                      label="Datum Servisa"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="document.documentInvoiceStartDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal2 = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog2.save(document.documentInvoiceStartDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
              </v-col>
              <v-col cols="6" xl="3" lg="3" xs="6" sm="6" md="6"  class="pr-3">
                     <v-dialog
                      ref="dialogT2"
                      v-model="modalT2"
                      :return-value.sync="document.serviceStartTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="document.serviceStartTime"
                          label="Početak servisa"
                          prepend-icon="mdi-clock-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modalT2"
                        v-model="document.serviceStartTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalT2 = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogT2.save(document.serviceStartTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
              </v-col>
              <v-col cols="6" xl="3" lg="3" xs="6" sm="6" md="6"  class="pr-3">
                     <v-dialog
                      ref="dialogT3"
                      v-model="modalT3"
                      :return-value.sync="document.serviceEndTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="document.serviceEndTime"
                          label="Kraj servisa"
                          prepend-icon="mdi-clock-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modalT3"
                        v-model="document.serviceEndTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalT3 = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialogT3.save(document.serviceEndTime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
              </v-col>
             
            </v-row> 

            <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5">
                      <v-autocomplete
                      class="pr-3"
                      :items="partners"
                      v-model="selectPa"
                      :label="lang.SelectPartner"
                      :search-input.sync="search"
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                      outlined
                      dense

                    ></v-autocomplete>                  
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                    <v-text-field class="pr-2"
                      :label="lang.VATID"
                      v-model="document.partnerVATID"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="4" xl="4">
                    <p class="text-right">
                      <v-btn text color="primary" class="pt-3" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                    </p>
                </v-col>
             </v-row>

            <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5" >
                    <v-text-field
                      class="pr-3"
                      :label="lang.PartnerName"
                      v-model="document.partnerName"
                      :rules="[rules.required]"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="5" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.Street"
                      v-model="document.partnerStreet"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      :label="lang.BuildingNameNumber"
                      v-model="document.partnerBuildingNameNumber"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">

                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-checkbox
                    :label="lang.privatePerson"
                    v-model="document.privatePerson"
                    outlined
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5">
                 <span v-if="!privateP">
                    <v-text-field class="pr-2"
                    :label="lang.CompanyEmail"
                    v-model="document.partneremail"
                    outlined
                    dense
                    ></v-text-field>
                  </span>
                </v-col>
                
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.City"
                      v-model="document.partnerCity"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      :label="lang.Postcode"
                      v-model="document.partnerPostcode"
                      outlined
                      dense
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <span v-if="!privateP">
                    <p class="text-right">
                      <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                    </p>
                  </span>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="!privateP">

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-text-field class="pr-2"
                    :label="lang.SecretaryTitle"
                    v-model="document.partnerSecretaryTitle"
                    outlined
                    dense
                    v-if="!document.privatePerson"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryFirst"
                    v-model="document.partnerSecretaryFirst"
                    outlined
                    dense
                    v-if="!document.privatePerson"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryLast"
                    v-model="document.partnerSecretaryLast"
                    dense
                    v-if="!document.privatePerson"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryEmail"
                    v-model="document.partnerSecretaryEmail"
                    dense
                    v-if="!document.privatePerson"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="6" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryPhone"
                    v-model="document.partnerPhones"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
 
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                   <v-btn text color="primary" class="pt-3" @click="showAllPartners()">{{langC.Partners}}</v-btn>
                </v-col>
              </v-row>         
              <v-divider class="mt-1"></v-divider>

              <v-row no-gutters v-if="have1"> 
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0" >
                  <v-btn text color="primary" @click="showOn1()" class="pb-3 pl-0">Predmet servisa<v-icon v-if="!show1">mdi-chevron-down</v-icon><v-icon v-if="show1">mdi-chevron-up</v-icon></v-btn>
                  <v-text-field
                      dense
                      :label="lang1"
                      v-model="document.documentDescription4"
                      v-if="show1"
                      outlined
                    ></v-text-field>
                </v-col>
              </v-row>

             <v-row no-gutters v-if="have2">
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0">
                  <v-btn text color="primary" @click="showOn2()" class="pb-3 pl-0">Opis problema<v-icon v-if="!show2">mdi-chevron-down</v-icon><v-icon v-if="show2">mdi-chevron-up</v-icon></v-btn>
                  <v-textarea
                      :label="lang2"
                      v-model="document.documentDescription1"
                      v-if="show2"
                      dense
                      outlined
                    ></v-textarea>  
                </v-col>
              </v-row>

              <v-row no-gutters v-if="have3">
                <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="ma-0 pa-0">
                  <v-btn text color="primary" @click="showOn3()" class="pb-3 pl-0">Opis servisa <v-icon v-if="!show3">mdi-chevron-down</v-icon><v-icon v-if="show3">mdi-chevron-up</v-icon></v-btn>
                  <v-textarea
                      :label="lang3"
                      v-model="document.documentDescription5"
                      v-if="show3"
                      dense
                      outlined
                    ></v-textarea>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-row no-gutters v-if="show3">
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" v-if="showDD" class="mt-9">

                      <v-select
                        v-model="selectDD"
                        :items="docDesc"
                        item-text="docsmall"
                        item-value="description"
                        label="Opis"
                        persistent-hint
                        return-object
                        single-line
                        clearable
                        dense
                        outlined
                      ></v-select>
                      
                      
                     
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="show3">
                    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                      <!-- <v-btn text color="primary" @click="addOpenStatement()" v-if="show3">{{lang.OpenStatement}}</v-btn> -->
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>


              <v-row no-gutters >
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-select :label="lang.ServiceStatus" :items="statusType" v-model="document.status" value="document.status" outlined dense></v-select>
               </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
                  <v-select :label="lang.ServiceDoneBy" :items="users" 
                    item-text="email"
                    item-value="email" 
                    v-model="document.deliveryCurrier"
                    outlined
                    dense
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-text-field class="pr-3"
                    :label="lang.vehicleHands"
                    v-model.number="document.vehicleHands"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-text-field class="pr-3"
                    :label="lang.vehicleParts"
                    v-model.number="document.vehicleParts"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  <v-text-field class="pr-3"
                    :label="lang.vehicleTotalService"
                    v-model.number="document.vehicleTotalService"
                    type="number"
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>          
              </v-row>
              
<!--   
          {{error}}
          <div class="pink darken-2" v-html="error" /> -->
          <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
          </v-snackbar>
          
          <v-btn
            class="primary mr-1"
            color="success"
             :disabled="!valid"
            @click="validate">
            <span v-if="this.$store.state.editItem  === 0">
              {{langC.CreateNew}} {{ documentNameLoc }}
            </span>
            <span v-if="this.$store.state.editItem === 1">
              {{langC.Save}} {{ documentNameLoc }} {{document.documentId}}
            </span>
          </v-btn>
          <v-btn
            color="green"
            @click="documentlist">
            {{langC.Back}}
          </v-btn>

      </v-col>
    </v-row>
     <v-divider class="mt-1"></v-divider>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.showPartners">
          <PartnerListFast />
        </div>
      </v-col>
    </v-row>
     <v-divider class="mt-1"></v-divider>
    
    <v-row no-gutters v-if="show5">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="ma-0 pa-0">
      <VehicleCRUD />
    </v-col>
  </v-row>
  </v-container>
</v-form>
</template>

<script>
import dayjs from 'dayjs'
import PartnerService from '@/services/PartnerService'
import BankAccountService from '@/services/BankAccountService'
import UserService from '@/services/UserService'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import DocumentStatusService from '@/services/DocumentStatusService'
import DocDescService from '@/services/DocDescService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import PartnerListFast from '../Partner/PartnerListFast'
import VehicleCRUD from '../Vehicle/VehicleCRUD.vue'
import langEnn from '../Vehicle/vehicleDescEn'
import langHrr from '../Vehicle/vehicleDescHR'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import { defNewpartner } from '../MainLedger/mainledgerHelper'
import {testLastUpdate, newUpdates} from '@/services/TestLastUpdate'
import { defDocument } from './documentHelper'
import { round, parseNum, moneyFormat } from '@/snippets/allSnippets'
import { mapGetters } from 'vuex'
import VehicleService from '../../services/VehicleService'
import TaskService from '../../services/TaskService'

export default {
  name: 'documentcreateservice',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      newVehicle: false,
      saving: false,
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      modalT: false,
      modalT2: false,
      modalT3: false,
      modalUCD: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dialogT: false,
      dialogT2: false,
      dialogT3: false,
      dialogUCD: false,
      have1: true, 
      have2: true,
      have3: true,
      have4: true,
      have5: false,
      have6: false,
      have7: false,
      have8: false,
      lang1: '',
      lang2: '',
      lang3: '',
      lang4: '',
      lang5: '',
      msg: '',
      documentName: '',
      documentNameLoc: '',
      defaultItem: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        UserId: null,
        EmployeeId: 0,
        email: '',
        First: '',
        Last: '',
        MarkType: '',
        Chasis: '',
        TypeVeichle: '',
        Serie: '',
        Engine: '',
        ChasisModel: '',
        Registration: '',
        Colour: '',
        Km: '',
        YearManufacture: '',
        MonthManufacture: '',
        StateManufacture: '',
        PartnerId: null,
        partnerName: '',
        partnerVATID: '',
        privatePerson: false,
        LastUpdateBy: '',
        LastUpdate: null,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
      },
      document: {},
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      productiontypes: [],
      docStatus: [],
      docDesc: [],
      CompanyId: 0,
      selectPa: {},
      selectPT: {},
      selectDS: {},
      selectDD: {},
      firstTimePa: true,
      selectId: 0,
      search: null,
      selectBank: {},
      selectBankId: 0,
      firstTimeBank: true,
      selectCR: {},
      selectUs: {},
      documentDate: null,
      invoiceStartDate: null,
      invoiceEndDate: null,
      supplierDocumentDate: null,
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: ''
      },
      mainQuery2 : {},
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      statusType: [
        { text: 'Prijavljeno', value: 'Reported' },
        { text: 'Zaprimljeno', value: 'Received' },
        { text: 'U tijeku', value: 'InProgress' },
        { text: 'Završeno', value: 'Done' },
        { text: 'Preuzeto', value: 'Delivered' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      docName: '',
      lang: {},
      langV: {},
      langC: {},
      mount: true,
      reqVATID: false,
      haveItem: null,
      privateP: true,
      mainledgerAna: {},
      showPartners: false,
      totalFullExp: '',
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show55:false,
      showDD: false,
      locUCDdate : null,
      importPurchase: false,
      searchItem: {
        CompanyId: null,
        MarkType: '',
        Chasis: '',
        TypeVeichle: '',
        Serie: '',
        Registration: '',
        partnerName: ''
      },
      dontHaveVehicle: false,
      vehicleAction: ''
    }
  },
  components: {
    PartnerListFast,
    VehicleCRUD
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langV = langEnn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langV = langHrr
      this.langC = commHr
    }
    this.privateP = this.$store.state.user ? this.$store.state.user.privatePartner : true
    this.lang1 = this.lang.Description4
    this.lang2 = this.lang.Description1
    this.lang3 = this.lang.Description5
    this.lang4 = this.lang.Description3
    if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
      this.searchItem.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.businessYear) {
      this.mainQuery.BusinessYear = this.$store.state.businessYear
    }
    if (this.$store.state.documentActiv) {
      this.mainQuery.documentName = this.$store.state.documentActiv
    }
    if (this.$store.state.documentSide) {
      this.document.documentSide = this.$store.state.documentSide
    }
    this.saving = true
    this.productiontypes = this.$store.state.productiontype
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
      
    }
    //this.partners = (await PartnerService.index()).data.partners
    this.banks = (await BankAccountService.choose(this.mainQuery)).data.bankAccounts
    this.users = (await UserService.choose(this.mainQuery)).data.users
    this.docStatus = (await DocumentStatusService.index(this.mainQuery)).data.documenttypes
    this.docDesc = (await DocDescService.choose(this.mainQuery)).data.docdescs
    this.showDD = this.docDesc && this.docDesc.length > 0 ? true : false

    this.saving = false
    // edit current record
     if (this.$store.state.editItem === 1) {
        this.vehicleAction = this.lang.VehicleEdit
        this.documentName = this.$store.state.documentActiv
        this.documentNameLoc = this.$store.state.documentActivLoc        
        if (this.$store.state.documentActivHead) {
          this.document = this.$store.state.documentActivHead
          if (this.document.partnerId && this.document.partnerId.trim().length > 0 ) {    
            this.selectId = parseInt(this.document.partnerId)
            this.selectPa = this.partners.find(partner => (partner.partnerVATID === (this.document.partnerVATID)))
          }
          this.selectBankId  =this.document.bankAccountId ? parseInt(this.document.bankAccountId) : null
          this.selectBank = this.selectBankId ? this.banks.find(bank => (bank.id === this.selectBankId)) : null
          this.document.documentTime = this.document.documentTime ? this.document.documentTime.substr(0,5) : null
          this.selectPT = this.document.productiontype ? this.document.productiontype : null
          this.show1 = this.document.documentDescription4 && this.document.documentDescription4.trim().length > 0 ? true : false
          this.show2 = this.document.documentDescription1 && this.document.documentDescription1.trim().length > 0 ? true : false
          this.show3 = this.document.documentDescription5 && this.document.documentDescription5.trim().length > 0 ? true : false
          this.show4 = this.document.documentDescription3 && this.document.documentDescription3.trim().length > 0 ? true : false
          this.locUCDdate = dayjs(this.document.UCDdate).format('YYYY-MM-DD')
          if (this.document.vehicleId) {
            this.findVehicle(this.document.vehicleId)
          }
        } else {
          // if in any case dont have all data in head, return to list
          this.text = 'Error! Sorry... some error happend. Need to return.'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
            this.$router.push({
              name: 'document'
            })
        }
     }   
    // create NEW
    if (this.$store.state.editItem === 0) {
      this.vehicleAction = this.lang.VehicleNew
      this.document = defDocument()
      if (this.$store.state.companyid) {
        this.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }

      this.document.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.document.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
      this.document.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
      this.document.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
      this.document.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.document.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.document.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.document.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
        this.docName = this.$store.state.documentActiv
        if (this.docName === 'InquirySales' || this.docName === 'QuoteSales' || this.docName === 'OrderSales' || this.docName === 'DeliveryNoteSales' || this.docName === 'InvoiceSales' || this.docName === 'ContractSales') {
          this.document.documentAnalysis = 1
          this.$store.dispatch('setDocumentAnalysis', 1)
        }
        if (this.docName === 'RequestPurchase' || this.docName === 'InquiryPurchase' || this.docName === 'OrderPurchase' || this.docName === 'ReceiptPurchase' || this.docName === 'ReturnPurchase') {
          this.document.documentAnalysis = 2
          this.$store.dispatch('setDocumentAnalysis', 2)
        }
        if (!this.document.documentAnalysis || this.document.documentAnalysis === 0) {
          this.document.documentAnalysis = 1
          this.mainQuery.documentAnalysis = 1
          this.$store.dispatch('setDocumentAnalysis', 1)
        }
      }
      this.document.documentSide = this.$store.state.documentSide ? this.$store.state.documentSide : 1  
      if (this.docName === 'InvoiceSales') {
        this.document.documentDescription5 = this.$store.state.companyActiv.CompanyInvoiceMemo
      }
      if (this.banks) {
        this.selectBank = this.banks ? this.banks.find(bank => (bank.DefaultAccount === true)) : {}
        this.selectBankId  = this.selectBank ? parseInt(this.selectBank.id) : null
      }
      let locDate1 = new Date()
      locDate1.getDate()
      locDate1.getMonth() + 1
      locDate1.getFullYear()
      const nrOfDays = this.$store.state.companyActiv.CompanyInvoiceDefDays ? parseInt(this.$store.state.companyActiv.CompanyInvoiceDefDays) : 15
      this.document.documentDate = dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentTime = dayjs(new Date()).format('HH:mm:ss')
      this.document.documentInvoiceStartDate = dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentInvoiceEndDate = dayjs(new Date()).add(nrOfDays, 'days').format('YYYY-MM-DD')
      this.document.documentHomeCurrency = this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
      this.document.documentForeignCurrency = 'EUR'
      this.document.documentExRate = this.$store.state.businessYear <= 2022 ? 7.5345 : 1
      this.locUCDdate = dayjs(new Date()).format('YYYY-MM-DD')
    }
     if (this.documentName === 'ServiceNotes') {
        this.have1 = true
        this.have2 = true
        this.have3 = true
        this.have4 = true
        this.have5 = true
        this.lang1 = this.lang.ServiceItem
        this.lang2 = this.lang.ServiceProblem
        this.lang3 = this.lang.ServiceDescription
        this.lang5 = this.lang.ServiceStatus
      }
      if (this.documentName === 'Inventory') {
        this.have1 = false
        this.have2 = false
        this.have3 = false
        this.have4 = true
        this.have5 = false
      }
      if (this.documentName === 'ReceiptPurchase' || this.documentName === 'ReturnPurchase') {
        this.have6 = true
      }
      if (this.documentName === 'WorkingOrder') {
        this.have7 = true
      }
      if (this.documentName === 'InvoiceSales') {
        this.have8 = true
      }
      if (this.have6) {
        if (this.document.exportDoc) {
          this.importPurchase = true
        }
      }
    this.mount = false
  },
  computed: {
    formIsValid () {
      return this.document.email !== '' &&
        this.document.First !== '' &&
        this.document.Last !== '' &&
        this.document.documentId !== '' &&
        this.document.documentDate !== ''
    },
    ...mapGetters({
      myPartnerid: 'NeedPartnerid',
      myVehicleid: 'NeedVehicleid',
    }),
    formatDate1() {
      return this.document.documentDate
        ? dateFormat(this.document.documentDate)
        : "";
    },
    formatDate2() {
      return this.document.documentInvoiceEndDate
        ? dateFormat(this.document.documentInvoiceEndDate)
        : "";
    },
    formatDate3() {
      return this.document.documentInvoiceStartDate
        ? dateFormat(this.document.documentInvoiceStartDate)
        : "";
    }
  },
  methods: {
    showOn1() {
      this.show1 = !this.show1
    },
    showOn2() {
      this.show2 = !this.show2
    },
    showOn3() {
      this.show3 = !this.show3
    },
    showOn4() {
      this.show4 = !this.show4
    },
    showOn5() {
      this.show5 = !this.show5
    },
    showOn55() {
      this.show55 = !this.show55
    },
    showOn6() {
      this.newVehicle = !this.newVehicle
    },
    showAllPartners() {
      this.showPartners = !this.showPartners
    },
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async register () {
      try {
        // write new document to database
        // this.localError = false   
        const haveYear = dayjs(this.document.documentDate).year()
        if (haveYear !== this.$store.state.businessYear) {
          this.text = this.lang.wrongBussinesYear
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }
        if (!this.document.partnerName) {
          this.text = 'Error! Greška! Naziv partnera nije upisan !'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 3000
          return
        }
        if (!this.document.privatePerson) {
          if (!this.document.partnerVATID) {
            this.text = 'Error! Greška! OIB partnera nije upisan !'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
        }

        if (!this.defaultItem.noVehicle) {
          if (!this.defaultItem.MarkType) {
            this.text = 'Error! Greška! Marka vozila je obavezno polje !'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
          if (!this.defaultItem.Registration) {
            this.text = 'Error! Greška! Registracija vozila je obavezno polje !'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 3000
            return
          }
          if (this.defaultItem.Registration) {
            let vehicleInDatabase = false
            this.searchItem.Registration = this.defaultItem.Registration
            const {data} = await VehicleService.choose(this.searchItem)
            if (data && data.vehicles !== undefined && data.vehicles.length > 0){
              const haveVehicle = data.vehicles[0]
              this.findVehicle(haveVehicle.id)
              vehicleInDatabase = true
            }
            if (this.$store.state.editItem === 1 && this.newVehicle) {
              // user edit something on vehicle data
              const {data} = await VehicleService.show(this.document.vehicleId)
              if (data && data.vehicle){
                const newData = {...data.vehicle, ... this.defaultItem}
                await VehicleService.put(newData)
                vehicleInDatabase = true
              }
            }
              
            //   this.document.vehicleDesc = (haveVehicle.MarkType ? haveVehicle.MarkType : '' ) + (haveVehicle.TypeVeichle ?  ' ' + haveVehicle.TypeVeichle : '' ) + (haveVehicle.Registration ? ' ' + haveVehicle.Registration : '' )
            //   this.text = 'Error! Greška! Registracija vec postoji ! ' + (haveVehicle.MarkType ? haveVehicle.MarkType : '' ) + (haveVehicle.TypeVeichle ?  ' ' + haveVehicle.TypeVeichle : '' ) + (haveVehicle.partnerName ? ' ' + haveVehicle.partnerName : '' )
            //   this.color = 'red'
            //   this.snackbar = true
            //   this.timeout = 3000
            //   return
            // }
            if (!vehicleInDatabase) {
              // add new vehicle
              this.defaultItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              this.defaultItem.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              this.defaultItem.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              this.defaultItem.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
              this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
              this.defaultItem.PartnerId = this.document.partnerId ? this.document.partnerId : 0
              this.defaultItem.partnerName = this.document.partnerNameShort ? this.document.partnerNameShort : 0
              this.defaultItem.partnerVATID = this.document.partnerVATID ? this.document.partnerVATID : 0
              this.defaultItem.privatePerson = this.document.privatePerson ? this.document.privatePerson : 0

              const {data} = await VehicleService.post(this.defaultItem)
              if (data && data.vehicle) {
                this.document.vehicleId = data.vehicle.id
                this.document.noVehicle = false
                this.document.vehicleDesc = (data.vehicle.MarkType ? data.vehicle.MarkType : '' ) + (data.vehicle.TypeVeichle ?  ' ' + data.vehicle.TypeVeichle : '' ) + (data.vehicle.Registration ? ' ' + data.vehicle.Registration : '' )
              }
            }
          }
        }


        // if document is different than service notes or quotesales then
        // must have VAT ID
        let noVATerror = true
        let checkVAT = true
        if (this.documentName === 'ServiceNotes' || this.documentName === 'QuoteSales' ) {
          noVATerror = true
          checkVAT = false
        } else {
          if (!this.document.partnerVATID || !this.document.partnerVATID.trim()) {
            alert(this.lang.Error2)
            noVATerror = false
          }
        }

        if (!this.document.exportDoc) {
          // on Quote sales and Service notes dont check VATID
          if (checkVAT){
            const partVatLeght = this.document.partnerVATID.trim().length
            if (partVatLeght !== 11) {
              this.text = this.lang.PartnerErrorVAT3
              this.color = 'red'
              this.snackbar = true
              this.timeout = 3000
              this.reqVATID = true
              return
            }
          }
        }

        if (this.selectPa) {
          //locPartner = this.partners.find(partner => (partner.partnerVATID === this.selectPa.partnerVATID))
          //const locPartner = (await PartnerService.show(this.selectPa.id)).data.partner
          //this.document.partnerId = this.selectPa.id
        }

        if (noVATerror === true) {
            this.document.documentTime = this.document.documentTime.substr(0,5)
            this.document.UCDdate = this.locUCDdate ?  dayjs(this.locUCDdate): null
            if (this.document.exportDoc && this.have6) {
              this.document.documentExRate = this.document.exRate && parseNum(this.document.exRate, 2) !== 0 ? this.document.exRate : 7.535
            }
            // create NEW
            if (this.$store.state.editItem === 0) {

              if (!this.document.partnerNameShort || (this.document.partnerNameShort.trim()).length < 1) {
                this.document.partnerNameShort = this.document.partnerName.replace(/[\W_]/g, '')
              }
              if (!this.document.partnerNameDatabase || (this.document.partnerNameDatabase.trim()).length < 1) {
                this.document.partnerNameDatabase = this.document.partnerName.replace(/[\W_]/g, '')
              }
              if (this.documentName === 'ServiceNotes' && !this.document.status) {
                this.document.status = 'Received'
              }
              const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
               if (side === 1 || side === 2) {
                this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
              }
              this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
              this.document.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.document.documentId + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
              this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
              const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
              this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
              
              //this.document.documentTime =  this.document.documentTime ? dayjs(this.document.documentTime).format('HH:mm:ss') : dayjs().format('HH:mm:ss')
              this.reqVATID = false
              if (this.documentName === 'DeliveryNoteSales' || this.documentName === 'InternalDeliveryNotes'|| this.documentName === 'InvoiceAdvance' || this.documentName === 'InvoiceSales' || this.documentName === 'ContractSales' ) {
                if (!this.document.partnerVATID) {
                  this.text = this.lang.PartnerErrorVAT2
                  this.color = 'red'
                  this.snackbar = true
                  this.timeout = 3000
                  this.reqVATID = true
                }
              }
              
              if (this.reqVATID === false) {
 
                await DocumentService.post(this.document)
                  .then(async(response) => {
                    this.text = this.lang.DocumentSavedOK + response.statusText
                    this.color = 'green'
                    this.snackbar = true
                    this.timeout = 2000
                    if (this.documentName === 'ServiceNotes' && this.document.documentInvoiceStartDate) {
                      const tDate = new Date()
                      const newTask = {}
                      newTask.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                      newTask.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
                      newTask.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
                      newTask.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
                      newTask.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
                      newTask.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                      newTask.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
                      newTask.taskOpenDate = this.document.documentDate ? this.document.documentDate : new Date()
                      newTask.taskOpenTime = this.document.documentTime ? this.document.documentTime : new Date()
                      
                      newTask.taskForDate = this.document.documentInvoiceStartDate ? this.document.documentInvoiceStartDate : new Date()
                      newTask.taskEndDate = this.document.documentInvoiceStartDate ? this.document.documentInvoiceStartDate : new Date()
                      newTask.taskForTime = this.document.serviceStartTime ? this.document.serviceStartTime : tDate.getHours() + ":" + tDate.getMinutes() + ":" + tDate.getSeconds()
                      newTask.taskClosedTime = this.document.serviceEndTime ? this.document.serviceEndTime : tDate.getHours() + ":" + tDate.getMinutes() + ":" + tDate.getSeconds()
                      newTask.taskShortDescription = this.document.documentDescription4 ? this.document.documentDescription4 : ''
                      newTask.taskDetailDescription = this.document.documentDescription5 ? this.document.documentDescription5 : ''
                      newTask.taskColor = 'blue'
                      newTask.newTask = false
                      newTask.taskClosedDate=  null
                      newTask.taskClosed = false
                      newTask.taskClosedSendEmail = false
                      newTask.taskSpentHours =  0
                      newTask.taskChargeHours = 0
                      newTask.taskCharged = false
                      newTask.taskChargedByUserId = 0
                      newTask.taskChargedByUseremail = ''
                      newTask.taskChargedDate = null
                      newTask.taskChargedTime = null
                      console.log('Snimam newTask', newTask)
                      await TaskService.post(newTask)
                    }
                    this.$store.dispatch('seteditItem', 0)
                    this.$store.dispatch('setDocumentActivHead', {})
                    this.$store.dispatch('setDocumentActivItems', [])
                    if (response.data && response.data.newDoc) {
                      this.navigateTo(response.data.newDoc)
                    }
                    
                    //this.documentlist()
                  })
                  .catch(err => {
                    // eslint-disable-next-line
                    console.log(err)
                    this.text = 'Error post!' + err
                    this.color = 'red'
                    this.snackbar = true
                    this.timeout = 4000
                  })
              }
            }
            // edit current
            if (this.$store.state.editItem === 1) {
              await DocumentService.put(this.document)
                .then(response => {
                  this.text = this.lang.DocumentSavedOK + response.statusText
                  this.color = 'green'
                  this.snackbar = true
                  this.timeout = 2000
                  this.$store.dispatch('seteditItem', 0)
                  this.$store.dispatch('setDocumentActivHead', {})
                  this.$store.dispatch('setDocumentActivItems', [])
                  if (response.data && response.data.newDoc) {
                    this.navigateTo(response.data.newDoc)
                  } else {
                    this.documentlist()
                  }
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  this.text = 'Error put!' + err
                  this.color = 'red'
                  this.snackbar = true
                  this.timeout = 4000
                })
            }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
        this.text = 'Error on save!' + error
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
        this.error = 'Error'
      }
    },
    close () {
      this.show = false
    },
    async navigateTo (params) {
      try {                
        
        const dbDocument = await DocumentService.show(params.id)
        if (dbDocument) {
          this.$store.dispatch('setDocumentActivHead', dbDocument.data.document)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
        this.$store.dispatch('setDocumentid', params.id)
        this.$store.dispatch('setDatabaseDocID', params.id)
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : null
        this.docSide = this.$store.state.documentSide ? this.docSide = this.$store.state.documentSide : null

        this.items = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
        if (this.items) {
          this.$store.dispatch('setDocumentActivItems', this.items)
        } else {
          this.$store.dispatch('setDocumentActivItems', [])
        }
        this.$store.dispatch('setprintSmall', false)
        this.$store.dispatch('setprintNoPrice', false)
        this.$store.dispatch('settypePOSPrint', null)
        if (this.$store.state.documentActiv !== 'WorkingOrder') {
            this.$router.push({
            name: 'documentview'
          })
          } else {
            this.$router.push({
            name: 'documentview3'
          })
        }
        
      } catch (err) {
        // eslint-disable-next-line
        console.log('error read from database', err)
      }      
    },
    async documentlist () {
      try {
          if (this.$store.state.documentActiv !== 'WorkingOrder') {
            this.$router.push({
            name: 'document'
          })
          } else {
            this.$router.push({
            name: 'documentproduction'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(' Error ' + err)
        this.text = 'Error!' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
    clearPartner() {
      this.selectPa = null
      this.document.partnerId = null
      this.document.partnerName = null
      this.document.partnerNameShort = null
      this.document.partnerNameDatabase = null
      this.document.partnerBuildingNameNumber = null
      this.document.partnerStreet = null
      this.document.partnerCity = null
      this.document.partnerPostcode = null
      this.document.partnerCountyRegion = null
      this.document.partnerState = null
      this.document.partnerVATID = null
      this.document.partneremail = null
      this.document.partnerPhones = null
      this.document.partnerBankName = null
      this.document.partnerBankAddress = null
      this.document.partnerBankIBAN = null
      this.document.partnerBankSWIFT = null
      this.document.partnerContactTime = null
      this.document.partnerSecretaryTitle = null
      this.document.partnerSecretaryFirst = null
      this.document.partnerSecretaryLast = null
      this.document.partnerSecretaryEmail = null
      this.document.partnerSecretaryPhone = null
      this.document.partnerSecretaryContactOption = null
      this.document.privatePerson = false
    },
    async findByVATID() {
      if (this.document.partnerVATID) {
        this.mainQuery.partnerVATID = this.document.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        const partnerVATID = data && data.partner && data.partner.id ? data.partner.id : null
        if (this.document.partnerVATID) {
          this.selectPa =  this.partners.find(partner => (partner.partnerVATID === (this.document.partnerVATID)))
          await this.findPartner(partnerVATID)
        }
      }
    },
    async findVehicle (vehicleId) {
      if (vehicleId) {
        // in partners array I dont have all required data, for faster serach 
        // const locPartner = this.partners.find(partner => (partner.id === vehicleId)) and then need to contact API
        // if (parseInt(vehicleId) !== parseInt(this.document.vehicleId)) {
          this.saving = true
          const locVehicle = (await VehicleService.show(vehicleId)).data.vehicle
          this.saving = false

          if (locVehicle ) {
            this.defaultItem = {...locVehicle}

            this.document.vehicleId = locVehicle.id
            this.document.noVehicle = false
            this.document.vehicleDesc = (locVehicle.MarkType ? locVehicle.MarkType : '' ) + (locVehicle.TypeVeichle ?  ' ' + locVehicle.TypeVeichle : '' ) + (locVehicle.Registration ? ' ' + locVehicle.Registration : '' )
              
            // if (this.defaultItem && this.defaultItem.partnerVATID.length > 0) {
            //   this.findPartner(this.defaultItem.partnerVATID)
            //   this.selectPa = this.partners.find(partner => (partner.partnerVATID === this.defaultItem.partnerVATID))
            //   this.show5 = false
            // }
            this.$store.dispatch('setVehicleid', null)
          }
        //}
      }
    },
    async findPartner (partnerVATID) {
      if (partnerVATID) {
        // in partners array I dont have all required data, for faster serach 
        // const locPartner = this.partners.find(partner => (partner.id === partnerVATID)) and then need to contact API
        //if (partnerVATID !== (this.document.partnerVATID)) {
          //let haveAlert = false
          this.saving = true
          const res = (await PartnerService.show(partnerVATID))
          const locPartner = res && res.data && res.data.partner ? res.data.partner : null
          this.saving = false
          
          // if (this.documentName === 'QuoteSales' || this.documentName === 'ServiceNotes') {
          //   haveAlert = false
          // } else {
          //   if (locPartner.privatePerson) {
          //     alert(this.lang.Error3)
          //     this.clearPartner()
          //     haveAlert = true
          //     return
          //   }
          //   if (!locPartner.privatePerson && (!locPartner.partnerVATID  || locPartner.partnerVATID.trim().length === 0)) {
          //     alert(this.lang.Error2)
          //     this.clearPartner()
          //     haveAlert = true
          //     return
          //   }
          // }

          if (locPartner) {
            this.document.partnerId = locPartner.id
            this.document.partnerName = locPartner.partnerName
            this.document.partnerNameShort = locPartner.partnerNameShort
            this.document.partnerNameDatabase = locPartner.partnerNameDatabase
            this.document.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
            this.document.partnerStreet = locPartner.partnerStreet
            this.document.partnerCity = locPartner.partnerCity
            this.document.partnerPostcode = locPartner.partnerPostcode
            this.document.partnerCountyRegion = locPartner.partnerCountyRegion
            this.document.partnerState = locPartner.partnerState
            this.document.partnerVATID = locPartner.partnerVATID
            this.document.partneremail = locPartner.partneremail
            this.document.partnerPhones = locPartner.partnerPhones
            this.document.partnerBankName = locPartner.partnerBankName
            this.document.partnerBankAddress = locPartner.partnerBankAddress
            this.document.partnerBankIBAN = locPartner.partnerBankIBAN
            this.document.partnerBankSWIFT = locPartner.partnerBankSWIFT
            this.document.partnerContactTime = locPartner.partnerContactTime
            this.document.partnerSecretaryTitle = locPartner.partnerSecretaryTitle
            this.document.partnerSecretaryFirst = locPartner.partnerSecretaryFirst
            this.document.partnerSecretaryLast = locPartner.partnerSecretaryLast
            this.document.partnerSecretaryEmail = locPartner.partnerSecretaryEmail
            this.document.partnerSecretaryPhone = locPartner.partnerSecretaryPhone
            this.document.partnerSecretaryContactOption = locPartner.partnerSecretaryContactOption
            this.document.privatePerson = locPartner.privatePerson
          }
       // }
      }
    },
    async addeditPartner () {
        const addeditPartner = {...defNewpartner()}
        addeditPartner.CompanyId = this.document.CompanyId ? this.document.CompanyId : ''
        addeditPartner.CompanyNameDatabase = this.document.CompanyNameDatabase
        addeditPartner.BusinessYear = this.document.BusinessYear
        addeditPartner.UserId = this.document.UserId
        addeditPartner.email = this.document.email
        addeditPartner.First = this.document.First
        addeditPartner.Last = this.document.Last
        addeditPartner.partnerName = this.document.partnerName
        addeditPartner.partnerNameShort = this.document.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.document.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.document.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.document.partnerStreet
        addeditPartner.partnerCity = this.document.partnerCity
        addeditPartner.partnerPostcode = this.document.partnerPostcode
        addeditPartner.partnerCountyRegion = this.document.partnerCountyRegion
        addeditPartner.partnerState = this.document.partnerState
        addeditPartner.partnerVATID = this.document.partnerVATID
        addeditPartner.partneremail = this.document.partneremail
        addeditPartner.partnerPhones = this.document.partnerPhones
        addeditPartner.partnerBankName = this.document.partnerBankName
        addeditPartner.partnerBankAddress = this.document.partnerBankAddress
        addeditPartner.partnerBankIBAN = this.document.partnerBankIBAN
        addeditPartner.partnerBankSWIFT = this.document.partnerBankSWIFT
        addeditPartner.partnerContactTime = this.document.partnerContactTime
        addeditPartner.partnerSecretaryTitle = this.document.partnerSecretaryTitle
        addeditPartner.partnerSecretaryFirst = this.document.partnerSecretaryFirst
        addeditPartner.partnerSecretaryLast = this.document.partnerSecretaryLast
        addeditPartner.partnerSecretaryEmail = this.document.partnerSecretaryEmail
        addeditPartner.partnerSecretaryPhone = this.document.partnerSecretaryPhone
        addeditPartner.partnerSecretaryContactOption = this.document.partnerSecretaryContactOption
        addeditPartner.privatePerson = this.document.privatePerson
        this.saving = true
        if (this.document.partnerId) {
            addeditPartner.id = this.document.partnerId
            this.saving = true
            await PartnerService.put(addeditPartner)
            this.saving = false
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            //await checkPartners(1)
            await newUpdates(2)
            await testLastUpdate()
            if (this.$store.state.partnerStore) {
              this.partners = this.$store.state.partnerStore
            }
        } else {
          if (addeditPartner.partnerName) {
            this.localError = null
            this.mainQuery2 = {}
            // if is private person then dont check
            if (addeditPartner.privatePerson === false) {
              // check if vatid exist and if exist then do not add partner
              if (!addeditPartner.partneremail) {
                this.localError = 'Error partner dot have email ! '
              }
              if (!addeditPartner.partnerVATID) {
                this.localError =  this.localError + ', Error partner dot have VAT ID or mark for private person '
              }
              if (addeditPartner.partnerVATID) {
                this.mainQuery2.partnerVATID = addeditPartner.partnerVATID
              }
              if (addeditPartner.partneremail) {
                this.mainQuery2.partneremail = addeditPartner.partneremail
              }
              if (!this.localError) {
                await PartnerService.check(this.mainQuery2)
                  .then(res => {
                    if (res.data.partner) {
                      this.haveItem = res.data.partner
                      this.localError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }
                    if (res.data.partner2) {
                      this.haveItem = res.data.partner2
                      this.localError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }                
                  })
                  .catch(err => {
                    this.error = err.data
                    this.localError = false
                  })
              }
            }
            // can save as private person ONLY in document ServiceNotes and QuoteSales
            if (!this.localError) {
              this.saving = true
              const newPartner = await PartnerService.post(addeditPartner)
              this.saving = false
              this.text = this.lang.addedPartner
              this.color = 'green'
              this.snackbar = true
              this.timeout = 2000
              
              //await checkPartners(0)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
              // this.partners = (await PartnerService.index()).data.partners
              this.document.partnerId = newPartner.data.partner.id ? newPartner.data.partner.id : null
              this.selectId = this.document.partnerId ? parseInt(this.document.partnerId) : null
              this.selectPa = this.selectId ? this.partners.find(partner => (partner.partnerVATID === this.selectId)) : null
            } else {
              this.text = this.localError
              this.color = 'red'
              this.snackbar = true
            }
            
          } else {
            // not enough data to save partner 
            this.text = 'Error! Partner name is empty. Can not save partner.'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          } 
        }
        this.saving = false
    },
    findBank (bankId) {

      if (bankId) {
        const locBank = bankId ? this.banks.find(bank => (bank.id === bankId)) : null
        this.document.bankAccountId = locBank.id ? parseInt(locBank.id) : null
        this.document.bankAccountName = locBank.BankName ? locBank.BankName : null
        this.document.bankAccountNr = locBank.IBAN ? locBank.IBAN : null
      }
      this.firstTimeBank = false
    },
    formatdocumentDate () {
     // if (this.document.documentDate) {
     //   this.document.documentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
     // }

    },
    formatInvoiceStartDate () {
     // if (this.document.documentInvoiceStartDate) {
     //   this.document.invoiceStartDate = this.formatDate(this.document.documentInvoiceStartDate)
     // }

    },
    formatInvoiceEndDate () {
      if (this.document.documentInvoiceEndDate) {
        this.invoiceEndDate = this.localDate(this.document.documentInvoiceEndDate)
      }
    },
    formatsupplierDocumentDate () {
      if (this.document.supplierDocumentDate) {
        this.supplierDocumentDate = this.localDate(this.document.supplierDocumentDate)
      }
    },
    calculateImportDoc() {
      this.document.domecilAmount = parseNum(((this.document.exRate ? parseFloat(this.document.exRate) : 1) * (this.document.importAmount ? parseFloat(this.document.importAmount) : 0)),2)
      this.document.totalExp = (this.document.customAmount ? parseNum(this.document.customAmount, 2) : 0) + (this.document.bankExp ? parseNum(this.document.bankExp, 2) : 0) + (this.document.forwarderExp ? parseNum(this.document.forwarderExp, 2) : 0) + (this.document.transportExp ? parseNum(this.document.transportExp, 2) : 0) + (this.document.otherExp ? parseNum(this.document.otherExp, 2) : 0)
      this.document.expenesPercent = (this.document.domecilAmount && parseNum(this.document.domecilAmount, 2) > 0 ) ? (parseNum((this.document.totalExp / (parseNum(this.document.domecilAmount, 2) / 100)), 2)) : 0
      this.totalFullExp = '= ' + moneyFormat(this.document.totalExp) + ' (' + moneyFormat(this.document.expenesPercent) + '%)'
    },
    async addOpenStatement(){
      // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
          this.mainledgerAna.type = 5
          if (this.document.partnerId) {
            this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainledgerAna.partnerId = this.document.partnerId
            this.mainledgerAna.partnerVATID = this.document.partnerVATID
            this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
            this.mainledgerAna.fromAccount = '1200'
            this.mainledgerAna.toAccount = '1200'
            this.mainledgerAna.docFromDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
            this.mainledgerAna.docToDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')

            await MainLedgerDetailService.card(this.mainledgerAna)
            .then(res => {
              this.items = res.data.documents ? res.data.documents : []
              if (this.items.length > 0) {
                const totalOpen = round(parseNum(this.items[0].totalDebit), 2) - round(parseNum(this.items[0].totalCredit), 2)
                if (totalOpen > 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta imate otvoren iznos dugovanja: ' + moneyFormat(totalOpen) + ' Kn'
                }
                if (totalOpen === 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta nemate otvoren iznos dugovanja. Hvala!'
                }
                if (totalOpen < 0) { 
                  this.document.documentDescription5 = this.document.documentDescription5 +  'Poštovani, obaviještavam Vas da na dan izdavanja ovog dokumenta imate pretplatu u iznosu: ' + moneyFormat(totalOpen) + ' Kn'
                }

              } else {
                this.text = 'Error! Nema knjizenja za odabranog partnera!'
                this.color = 'red'
                this.snackbar = true
                this.timeout = 4000
              }
            })
            .catch(err => {
              this.error = this.langC.ErrorE + err
            })
          } else {
            this.text = 'Error! Partner nije definiran!'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          }
    }
  },
  watch: {
    'myPartnerid' () {      
      if (this.$store.state.partnerVATID) {
        this.selectPa = this.document.partnerVATID ? this.partners.find(partner => (partner.partnerVATID === this.$store.state.partnerVATID)) : null
        this.findPartner(this.$store.state.partnerVATID)
        this.showPartners = false
      }
     // this.partner()
    },
    'myVehicleid' () {      
      if (this.$store.state.vehicleid) {
        this.findVehicle(this.$store.state.vehicleid)
        // this.selectPa = this.document.partnerId ? this.partners.find(partner => (partner.id === this.$store.state.partnerid)) : null
        // this.findPartner(this.$store.state.partnerid)
        // this.showPartners = false
      }
     // this.partner()
    },
     'selectPa' (val) {
      if (val) {
        if (val.partnerVATID) {
          if (this.$store.state.editItem === 0) {
            this.findPartner(val.partnerVATID)
          }
          if (this.$store.state.editItem === 1 && val.partnerVATID !== '00000000000') {
            this.findPartner(val.partnerVATID)
          }
        }
      }
      if (!val) {
        this.clearPartner()
      }
    },
    'selectBank' (val) {
      if (val) {
        val.id && this.findBank(val.id) 
      }           
    },
    'selectPT' (val) {
      if (val) {
        this.document.productiontype = val.value ? val.value : null
      }           
    },
    'selectDS' (val) {
      if (val) {
        this.document.docColor = val.docColor ? val.docColor : ''
        this.document.docStatus = val.docStatus ? val.docStatus : ''
      } else {
        this.document.docColor = null
        this.document.docStatus = null
      }
    },
    'selectDD' (val) {
      if (val) {
        this.document.documentDescription5 = val.description ? val.description : ''
      }
    },
    'document.documentDate' () {
      this.formatdocumentDate()
    },
    'document.documentInvoiceStartDate' () {
      this.formatInvoiceStartDate()
    },
    'document.documentInvoiceEndDate' () {
      this.formatInvoiceEndDate()
    },
    'document.supplierDocumentDate' () {
      this.formatsupplierDocumentDate()
    },
    'document.documentTotalAmountPayInAdvance' () {
      if (isNaN(this.document.documentTotalAmountPayInAdvance)) {
        this.document.documentTotalAmountPayInAdvance = 0
      }
      if (!this.document.documentTotalAmountPayInAdvance) {
        this.document.documentTotalAmountPayInAdvance
      }
      const exRate = this.document.documentExRate ? this.document.documentExRate : 1
      this.document.documentTotalAmountPayInAdvance = round(this.document.documentTotalAmountPayInAdvance, 2)
      this.document.documentTotalAmountToPay = this.document.documentTotalAmountBrutto ? (round(this.document.documentTotalAmountBrutto, 2) - (this.document.documentTotalAmountPayInAdvance)): 0
      this.document.documentTotalAmountPayInAdvanceForeign = round((this.document.documentTotalAmountPayInAdvance / exRate), 2)
      this.document.documentTotalAmountToPayForeign = this.document.documentTotalAmountBrutto ? (round((this.document.documentTotalAmountBrutto/ exRate), 2) - (this.document.documentTotalAmountPayInAdvance / exRate) ) : 0  
    },
    'document.importAmount' () {
      if (!this.document.importAmount) {
        this.document.importAmount  = 0
      }
      if (this.document.importAmount && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.exRate' () {
      if (!this.document.exRate) {
        this.document.exRate  = 0
      }
      if (this.document.exRate && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.customAmount' () {
      if (!this.document.customAmount) {
        this.document.customAmount  = 0
      }
      if (this.document.customAmount && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.bankExp' () {
      if (!this.document.bankExp) {
        this.document.bankExp  = 0
      }
      if (this.document.bankExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.forwarderExp' () {
      if (!this.document.forwarderExp) {
        this.document.forwarderExp  = 0
      }
      if (this.document.forwarderExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.transportExp' () {
      if (!this.document.transportExp) {
        this.document.transportExp  = 0
      }
      if (this.document.transportExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.otherExp' () {
      if (!this.document.otherExp) {
        this.document.otherExp  = 0
      }
      if (this.document.otherExp && this.document.exportDoc) {
        this.calculateImportDoc()
      }
    },
    'document.exportDoc' () {
      this.importPurchase = false
      if (this.have6) {
        if (this.document.exportDoc) {
          this.importPurchase = true
        }
      }
    },
    'document.vehicleHands' () {
      if (!this.document.vehicleHands) {
        this.document.vehicleHands = 0
        this.document.vehicleTotalService = this.document.vehicleParts
      }
      if (this.document.vehicleHands && this.document.vehicleHands !== 0) {
        this.document.vehicleTotalService = this.document.vehicleHands + this.document.vehicleParts
      }
    },
    'document.vehicleParts' () {
      if (!this.document.vehicleParts) {
        this.document.vehicleParts = 0
        this.document.vehicleTotalService = this.document.vehicleHands
      }
      if (this.document.vehicleParts && this.document.vehicleParts !== 0) {
        this.document.vehicleTotalService = this.document.vehicleHands + this.document.vehicleParts
      }
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
