<template>
  <v-container>
    <v-row dense>
      <v-flex xs12 sm12 md10 lg8 xl8>
        <v-card>
        <v-card-title>
          Select business year
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="20"
            @click:row="navigateTo"     
            :mobile-breakpoint="0"       
          >
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

      <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
          </v-snackbar>

      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import CompBusinessYearService from '@/services/CompBusinessYearService'

export default {
  name: 'companyselect',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [25],
      columns: [],
      rows: [],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        email: ''
      },
      mainQuery2: {
        CompanyId: 0
      },
      defaultCompanyId: 0,
      currentYear: 0,
      companyBY: [],
    }
  },
  async mounted () {
    try {
      if (this.$store.state.user.email) {
        this.mainQuery.email = this.$store.state.user.email
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.mainQuery2.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.items = (await CompBusinessYearService.choose(this.mainQuery2)).data.companyBY
        // eslint-disable-next-line
        console.log(this.items)
        if (this.$store.state.language === 'EN') {
          this.headers.push(
            {text: 'Business Year', value: 'BusinessYear', width: '150px'},
            {text: 'Start', value: 'BusinessYearStartDate', width: '150px'},
            {text: 'End', value: 'BusinessYearEndDate', width: '150px'},
            {text: 'Company', value: 'CompanyNameDatabase', type: 'number', width: '300px'},
            {text: 'DbId', value: 'id', type: 'number', width: '50px'}
          )
        }
        if (this.$store.state.language === 'HR') {
          // console.log(' radim push 2 hr naziva ', this.$store.state.language)
          this.headers.push(
            {text: 'Poslovna godina', value: 'BusinessYear', width: '150px'},
            {text: 'Početak', value: 'BusinessYearStartDate', width: '150px'},
            {text: 'Kraj', value: 'BusinessYearEndDate', width: '150px'},
            {text: 'Tvrtka', value: 'CompanyNameDatabase', type: 'number', width: '300px'},
            {text: 'DbId', value: 'id', type: 'number', width: '50px'}
          )
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    async navigateTo (params) {
      try {
        // eslint-disable-next-line
        console.log(' params ', params)
        this.currentYear = parseInt(params.BusinessYear)
        await this.$store.dispatch('setBusinessYear', this.currentYear)
        const query = {}
        query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : null
        query.businessYear = this.currentYear ? this.currentYear : null
        //query.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : null
        //query.MLType = this.$store.state.documentSide ? this.$store.state.documentSide : 0
        query.documentName =  null
        query.MLType = 0
        query.searchName = null
        query.page =  0
        query.pageSize =  30
        query.searchItem =  null
        await this.$store.dispatch('setOnPage', query)
        this.$router.push({
          name: 'crm'
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
