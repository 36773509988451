<template>
  <v-container fluid class="py-10">
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>
      <v-row v-if="error">
              <v-alert 
                :type="typeError"
                v-model="alert"
                dismissible>
               {{error}}
              </v-alert>
            </v-row>
        <v-card>
          <v-card-title>
            {{ documentNameLoc }}
            <span class="blue--text">, Za povezati: {{localMoney(useAmount)}}</span>
            <span class="green--text">,  Povezano: {{localMoney(allSelectedAmount)}}</span>
            <span class="pink--text">,  Ostalo: {{localMoney(restAmount)}}</span>
            <v-spacer></v-spacer>
             <v-btn @click="doUpdate()">Poveži</v-btn>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page="50"
            item-value="id"
            show-select
            return-object
            class="elevation-1"
          >
            <template v-slot:[`item.itInvoice`]="{ item }">
                <span>{{ parseInt(item.itInvoice) }}</span>
            </template>
            <template v-slot:[`item.invoiceInNumber`]="{ item }">
                <span v-if="item.invoiceInNumber !== 0">{{ parseInt(item.invoiceInNumber) }}</span>
            </template>
            <template v-slot:[`item.invoiceOutNumber`]="{ item }">
                <span v-if="item.invoiceOutNumber !== 0">{{ parseInt(item.invoiceOutNumber) }}</span>
            </template>
            <template v-slot:[`item.Debit`]="{ item }">
                <span>{{ localMoney(item.Debit) }}</span>
            </template>
             <template v-slot:[`item.Credit`]="{ item }">
                <span>{{ localMoney(item.Credit) }}</span>
            </template>
             <template v-slot:[`item.CreditDebit`]="{ item }">
                <span>{{ localMoney(item.CreditDebit) }}</span>
            </template>

            <template v-slot:[`item.BookingDate`]="{ item }">
                <span>{{ localDate(item.BookingDate) }}</span>
            </template>
            <template v-slot:[`item.InvoiceDate`]="{ item }">
                <span>{{ localDate(item.InvoiceDate) }}</span>
            </template>
             <template v-slot:[`item.InvoicePaymentDeadlineDate`]="{ item }">
                <span>{{ localDate(item.InvoicePaymentDeadlineDate) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
     
      <div v-if="showDialog">
        <Dialog :header="header" :message="message" :dcolor="dcolor" :showDialog="showDialog"/>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import Dialog from  '../../snippets/Dialog'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {parseNum, round} from '@/snippets/allSnippets'
import MainLedgerDetailService from '../../services/MainLedgerDetailService'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard11connect',
  props: ['items', 'head', 'partner'],
  data () {
    return {
      showDialog: false,
      header: '',
      message: '',
      dcolor: '',
      alert: true,
      documentNameLoc: '',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      typeError: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partners: [],
      mainQuery: {},
      singleSelect: false,
      selected: [],
      useAmount: 0,
      selectedAmount: 0,
      allSelectedAmount: 0,
      restAmount: 0,
      ssitems: [],
      midItems: [],
      order: 0,
      locid: 1,
      showSelected: false,
      indxTo: 0,
      headObj: {},
      headers: [ ],
      outNr: 0,
      inNr: 0,
      haveErr: false,
    }
  },
  components: {
    Dialog
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }

    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    if (this.$store.state.companyid) {
      this.mainQuery.CompanyId = this.$store.state.companyid
    }
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    const ureTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceInNumber' },
        { text: 'Orig.račun', value: 'InvoiceNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
    ]
     const ireTable = [
        { text: 'Vrsta', align: 'start', value: 'BookName'},
        { text: 'Broj dok.', value: 'itInvoice' },
        { text: 'VEZA', value: 'invoiceOutNumber' },
        { text: 'Datum knjiženja', value: 'BookingDate' },
        { text: 'Datum dokumenta', value: 'InvoiceDate' },
        { text: 'Datum dospijeća', value: 'InvoicePaymentDeadlineDate' },
        { text: 'Opis', value: 'Description' },
        { text: 'Konto', value: 'account4' },
        { text: 'Duguje', value: 'Debit' },
        { text: 'Potražuje', value: 'Credit' },
        { text: 'Saldo', value: 'CreditDebit' },
    ]
    this.headers = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? [...ureTable] : [...ireTable]
    this.documentNameLoc = 'Povezivanje proknjiženih stavaka: ' + this.partner.partnerName +  ', OIB:'+ this.partner.partnerVATID
    
    this.header = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? 'Odaberite URU' : 'Odaberite IRU'
    this.message =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? 'Prvo odaberite URU koju želite povezati, a zatim izvod ili temeljnicu sa kojom povezujete' : 'Prvo odaberite IRU koju želite povezati, a zatim izvod ili temeljnicu sa kojom povezujete'
    this.dcolor = 'green'
    this.showDialog = true
    this.indxTo = null

  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    localMoney(moneyTo) {
      return moneyFormat(moneyTo)
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    showError () {
      this.header = 'Greška!'
      this.message =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? 'Niste prvo odabrali URU!!' : 'Niste prvo odabrali IRU!!'
      this.dcolor = 'red'
      this.showDialog = true
      //this.selected = null
    },
    close () {
      this.show = false
    },
    doUpdate() {
      if (this.midItems && this.midItems.length > 0 && !this.haveErr) {
        this.midItems.map(async(item) => {
          const upItem = {}
          upItem.id = item.id
          upItem.invoiceOutNumber = item.invoiceOutNumber
          upItem.invoiceInNumber = item.invoiceInNumber
          await MainLedgerDetailService.put(upItem)
        })
      }
      if (this.haveErr) {
        alert('Greška!!! Ne može se proknjižiti povezano jer je označeno više dokumenata!')
      }
    },
    doCalculation(){
        // let saveItems = []
        // let saveIt = true
        // let runDebit =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? true : false
        // let allSelected = 0
        let allDebit = 0
        let allCredit = 0
        let side = null
        if (this.head.fromAccount && this.head.fromAccount.substr(0,2) === '12') { side = 1}
        if (this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22') { side = 2}

        if (this.midItems.length === 0){
          this.allSelectedAmount = 0
          //saveIt = false
          this.showSelected = false
        }
        
          if (this.midItems && this.midItems.length > 0) {
            this.allSelectedAmount = 0
            
            // do math
            this.midItems.map(item => {
              // const itemAmount = round(Math.abs(parseNum(item.Debit) - parseNum(item.Credit)), 2)
              
              const dDebit = round(parseNum(item.Debit),2)
              const cCredit = round(parseNum(item.Credit),2)
              //const itemAmount = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? round((cCredit - dDebit),2) : round((dDebit - cCredit), 2) 
              allDebit += round(dDebit,2)
              allCredit += round(cCredit,2)
              // allDebit = round(allDebit, 2)
              // allCredit = round(allCredit,2)


              // if (allDebit === 0 && allCredit !== 0) {
              //   allSelected = allCredit
              // }
              // if (allDebit !== 0 && allCredit === 0) {
              //   allSelected = allDebit
              // }
              // // if buyer or supplier
              // if (allDebit !== 0 && allCredit !== 0) {
              //   allSelected = this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? round((allCredit - allDebit),2) : round((allDebit - allCredit), 2) 
              // }
              // allSelected = round(allSelected, 2)
              // let goToLast = true
              
              // if (allSelected <= this.useAmount){
              //   goToLast = false
              //   let newItem = {...item}
              //   newItem.Debit = cCredit
              //   newItem.Credit = dDebit

              //   this.allSelectedAmount = round(allSelected, 2)
              //   saveItems.push(newItem)
              // }

              // if (goToLast){
              //   if (round(allSelected - itemAmount, 2) <= this.useAmount) {
              //     const haveRest = round(this.useAmount, 2) - round((allSelected - itemAmount), 2)
              //     let newItem = {...item}
                  
              //     if (runDebit) {
              //       newItem.Debit = round(haveRest, 2)
              //       newItem.Credit = 0
              //     }
              //     if (!runDebit) {
              //       newItem.Debit = 0
              //       newItem.Credit = round(haveRest, 2)
              //     }
              //     this.allSelectedAmount += round(haveRest, 2)
              //     saveItems.push(newItem)
              //   }
              // }

            })
          
          if (side === 2) {
            this.allSelectedAmount = round(Math.abs(allDebit - allCredit), 2)
          }
          if (side === 1) {
            this.allSelectedAmount = round(Math.abs(allCredit - allDebit), 2)
          }
          this.restAmount = this.useAmount - this.allSelectedAmount
        
          // if (saveIt) {

          //   this.allSelectedAmount = round(this.allSelectedAmount, 2)
          //   this.ssitems = [...saveItems]
            
          //   this.locid += 1 
          //   this.showSelected = true
          //   this.restAmount = round(this.useAmount - this.allSelectedAmount, 2)
          //   this.$store.dispatch('setMainLedgerDetailItems', saveItems)
          // }
        } 
    },
  },
  watch: {
    'selected' ()
      {
        // copy selected to midItems, by clicked order 
        const newArr = []
        this.midItems = [...newArr]
        
        let side = null
        if (this.head.fromAccount && this.head.fromAccount.substr(0,2) === '12') { side = 1}
        if (this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22') { side = 2}

        if(this.selected && this.selected.length === 0) {
          this.indxTo = null
          this.useAmount = 0
          this.allSelectedAmount = 0
          this.restAmount = 0
          const newObj = {}
          this.headObj = {...newObj}
          this.inNr = 0
          this.outNr = 0
          this.haveErr = false
        }

        if (this.selected && this.selected.length > 0) {

          if(this.selected.length === 1) {
            this.indxTo = this.selected[0].id
            
            const firstOne = this.selected[0]
            if (firstOne.Credit > 0 && side === 2 && firstOne.BookName.substr(0,3) === 'URA') {
              this.useAmount = round(Math.abs(firstOne.Credit - firstOne.Debit), 2)
              console.log('URA', this.selected[0].invoiceInNumber)
              this.inNr = this.selected[0].invoiceInNumber
            }
            if (firstOne.Debit > 0 && side === 1 && firstOne.BookName.substr(0,3) === 'IRA') {
              this.useAmount = round(Math.abs(firstOne.Debit - firstOne.Credit), 2)
              console.log('IRA', this.selected[0].invoiceOutNumber)
              this.outNr = this.selected[0].invoiceOutNumber
            }
          }
  
          if(this.selected.length > 1) {
            this.selected.map(item => {
              let addNewOne = true

              if (item.id === this.indxTo) {
                addNewOne = false
              }
              if (addNewOne) {
                if (item.BookName.substr(0,3) === 'URA' || item.BookName.substr(0,3) === 'IRA') {
                  this.error =  this.head.fromAccount && this.head.fromAccount.substr(0,2) === '22' ? 'Već ste odabrali URU koju povezujete!!, Poništite sva povezivanje i krenite ispočetka.' : 'Već ste odabrali IRU koju povezujete!! Poništite sva povezivanje i krenite ispočetka.'
                  alert(this.error)
                  this.haveErr = true
                }
                if (!this.haveErr) {
                  const newItem = {...item}
                  this.order += 1
                  newItem.order = this.order
                  newItem.invoiceInNumber = this.inNr
                  newItem.invoiceOutNumber = this.outNr
                  this.midItems.push(newItem)
                }
              }
            })
          }
        }

        this.doCalculation()
    }
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
