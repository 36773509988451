<template>
  <div class="mainledgerprint">

  </div>  
</template>

<script>
// import DocumentService from '@/services/DocumentService'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ledgerEN from '../MainLedger/MainLedgerDescEN'
import ledgerHR from '../MainLedger/MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import {dynamicSort} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgerprint',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      comp: {},
      docDefinition: {},
      content: [],
      items: [],
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      locCreatedAt: '',
      locUpdatedAt: '',
      order: 0,
      totalInv: 0
    }
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    
  },
  methods: {
    async print (side, items) {
      this.totalInv = 0
      if (items) {
        items.map(item => {
          this.totalInv += parseFloat(item.InvoiceAmount)
        })
      }
      this.initDocument(side)
      this.addHeaderNoLogo(side)
      this.addEmptyRow()
      this.addLine()
      this.addItemsHeader()
      this.addLine()
      this.addItems(side, items)
      this.addLine()
      this.addItemsFoter()
      this.addLine()
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument (side) {
      const year = + this.$store.state.businessYear 
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'landscape'
      this.docDefinition.pageMargins = [ 20, 20, 20, 20 ]
      this.docDefinition.header = function(currentPage, pageCount) {
      // you can apply any logic and return any valid pdfmake element
      return [
        { text: '\n' + 'Početno stanje '  + (side ===1 ? 'URE ' : 'IRE ') + year.toString() + ' - str.:' + currentPage + ' od ' + pageCount + '\u200B\t', alignment: 'right', fontSize: 8, margin: [0,0,30] },
      ]
    },
      this.content = []
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 800,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },
    addHeaderNoLogo (side) {
      const docPart = {
            columns: [
              {
                // auto-sized columns have their widths based on their content
                width: '35%',
                text: [
                  { text: this.comp.CompanyName + ' \n', fontSize: 10, bold: true },
                  { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                  { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                  { text: this.lang.VATID + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' '), fontSize: 8 }
                ]
              },
              {
                // % width
                width: '45%',
                text: [
                  { text:  'Početno stanje ' + (side ===1 ? 'URE ' : 'IRE ') + this.$store.state.businessYear + ' \n', fontSize: 18, bold: true },
                ]   
              },
              {
                // % width
                width: '20%',
                text: [                 
                  { text: ' Datum ispisa: ' + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
                ]   
              }

            ],
            // optional space between columns
            columnGap: 10
          }
        this.content.push(docPart)
    },
    addItemsHeader () {
        const docPart = {
        columns: [
              {
                width: '5%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.order 
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.InvoiceNumber 
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.InvoiceDate
              },
              {
                width: '25%',
                fontSize: 8,
                alignment: 'left',
                text: this.lang.partnerName + ', ' + this.lang.partnerVATID
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'center',
                text: this.lang.InvoiceAmount + ' EUR'
              },
              {
                width: '25%',
                fontSize: 7,
                alignment: 'center',
                text: this.lang.description
              },

            ]
          }
        this.content.push(docPart)        
    },
    addItems (side, items) {
           this.order = 0           
            this.expitems = items ? items.sort(dynamicSort("partnerName")): []
            let condPart = null
            let totPart = 0


            //console.log(' add items  ', this.expitems)
            if (this.expitems) {
              this.expitems.map(item => {
                  
                  // this.pTtt5 = item.itemBaseAmount ? this.formatPrice(item.itemBaseAmount) : 0
                  // this.pTtt6 = item.itemDiscountPercentage1 ? this.formatPrice(item.itemDiscountPercentage1) : 0
                  // this.pTtt7 = item.itemBaseAmountNoVAT ? this.formatPrice(item.itemBaseAmountNoVAT) : 0
                  // this.pTtt8 = item.itemVAT1Percentage ? this.formatPrice(item.itemVAT1Percentage) : 0
                  // this.pTtt9 = item.itemAmountWithVAT ? this.formatPrice(item.itemAmountWithVAT) : 0
                  if (!condPart) {
                    condPart = item.partnerVATID
                  }
                  if (condPart !== item.partnerVATID) {
                    const docPartT = {
                    columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '35%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '10%',
                          fontSize: 9,
                          alignment: 'right',
                          bold: true,
                          text: this.formatPrice(totPart)
                        },
                        {
                          width: '1%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '6%',
                          fontSize: 8,
                          alignment: 'left',
                          text: '' + '\n' + ' '
                        },
                      ]
                    }
                    this.content.push(docPartT)
                    totPart = 0
                    condPart = item.partnerVATID
                  }
                  if (condPart === item.partnerVATID) {
                      this.order +=1
                  }
                  totPart += parseFloat(item.InvoiceAmount)
                  const docPart = {
                  columns: [
                        {
                          width: '5%',
                          fontSize: 8,
                          alignment: 'left',
                          text: this.order
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: (side === 1 ? parseInt(item.BookID) : item.InvoiceNumber)
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'left',
                          text: dayjs(item.InvoiceDate).format('DD.MM.YYYY')
                        },
                        {
                          width: '35%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.partnerName + (item.partnerVATID ? ',   OIB:' + item.partnerVATID : '\n')
                        },
                        {
                          width: '10%',
                          fontSize: 8,
                          alignment: 'right',
                          text: this.formatPrice(item.InvoiceAmount)
                        },
                        {
                          width: '1%',
                          fontSize: 8,
                          alignment: 'left',
                          text: ''
                        },
                        {
                          width: '26%',
                          fontSize: 8,
                          alignment: 'left',
                          text: item.MLFree5
                        },


                      ]
                    }
                  this.content.push(docPart)
                }
              )
          }
    },
    addItemsFoter () {
        const docPart = {
        columns: [
              {
                width: '60%',
                fontSize: 8,
                alignment: 'left',
                text: ' '
              },
              {
                width: '10%',
                fontSize: 8,
                alignment: 'right',
                text: this.formatPrice(this.totalInv)
              },


            ]
          }
        this.content.push(docPart)        
    },
  },
  computed: {
  },
  watch: {
  },
  components: {
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>