<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12 sm12 m12 lg12 ex12>

        <div id="printArea">

                <v-layout row style="border-bottom:solid 1px;">
                    <v-flex xs5>
                        <h4><b><i>{{comp.CompanyNameShort}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                         OIB: {{comp.CompanyVATID}}
                    </v-flex>
                    <v-flex xs3>
                      <span v-if="this.$store.state.language == 'EN'">
                      <h2>Partner - full card {{currency}}</h2>
                      </span>
                      <span v-if="this.$store.state.language == 'HR'">
                      <h2>Partner - kartica {{currency}}</h2>
                      </span>

                      <h4><b><i>{{partner.partnerName}}</i></b></h4>
                      {{partner.partnerStreet}} {{partner.partnerBuildingNameNumber}}   <br />                
                      {{partner.partnerPostcode}} {{partner.partnerCity}}<br />
                      OIB: {{partner.partnerVATID}}, {{partner.partneremail}}
                  </v-flex>
                    <v-flex xs 4>
                      <p class="text-md-left">
                          Datum izvještaja: {{PrintDate}}<br />
                          Telefon: {{comp.CompanyPhones}}<br />
                          email: {{comp.Companyemail}}<br />
                          <v-btn @click="print" class="mr-2">Printaj</v-btn>
                      </p>
                    </v-flex>
              </v-layout>

            <v-layout row style="border-bottom:solid 1px; border-top:solid 1px;">

                    <v-flex xs1 class="text-left" >
                     Rb.  Vrsta
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Broj dok.
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Orig.račun
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum knjiženja
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dokumenta
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Datum dospijeća
                    </v-flex>
                    <v-flex xs1 class="text-left">
                      Opis
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      Konto
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Duguje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Potražuje
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      Saldo
                    </v-flex>

              </v-layout>

                <v-layout row v-for="item in pItems" :key="item.id">
                    <v-flex xs1 class="text-left" >
                      {{ item.itBookName }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.itInvoice }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.InvoiceNumber }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.BookingDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoiceDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ localDate(item.InvoicePaymentDeadlineDate) }}
                    </v-flex>
                    <v-flex xs1 class="text-center" style="font-size:12px;">
                      {{ item.Description }}
                    </v-flex>
                    <v-flex xs1 class="text-left" >
                      {{ item.account4 }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.Debit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.Credit)) }}
                    </v-flex>
                    <v-flex xs1 class="text-right" >
                      {{ formatPrice(parseFloat(item.CreditDebit)) }}
                    </v-flex>
                    

            </v-layout>
            <v-layout row style="border-top:solid 1px;">
              <v-flex xs8 class="text-xs-right">

              </v-flex>

              <v-flex xs1 class="text-right">
                {{ formatPrice(Total1) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total2) }}
              </v-flex>
              <v-flex xs1 class="text-right">
                {{ formatPrice(Total5) }}
              </v-flex>

            </v-layout>
        </div>
      </v-flex>
      <MainLedgerPrintCard2 ref="prnt" :card="card" />
    </v-layout>
  </v-container>
</template>

<script>
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import MainLedgerPrintCard2 from './MainLedgerPrintCard2'
//import {checkPartners} from '../MainLedger/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {round} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'mainledgercard1',
  props: ['items', 'head', 'showEUR'],
  data () {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      size: 'sm',
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      pItems: [],
      mainQuery2: {},
      partner: {},
      partners: [],
      card: {},
      mainQuery: {},
      exRate: 1,
      currency: 'EUR'
    }
  },
  components: {
    MainLedgerPrintCard2
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
    this.exRate = this.$store.state.defExRate ? this.$store.state.defExRate : 1

    if (!this.showEUR) {
      this.exRate = 1
    }
    this.currency = this.$store.state.businessYear >= 2023 ? 'EUR' : 'Kn'
    if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
    }
    this.PrintDate = dayjs(new Date()).format('llll')
    this.comp = this.$store.state.companyActiv ? this.$store.state.companyActiv : {}
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    //this.partners = (await PartnerService.index()).data.partners
    
    this.Total1 = 0
    this.Total2 = 0
    this.Total3 = 0
    this.Total4 = 0
    let itLine = 0
    let partnerId = null

    if (this.items) {
      // eslint-disable-next-line
      console.log(this.items)
      this.items.map(item => {
        itLine += 1
        const newDebit = round((parseFloat(item.Debit) / this.exRate), 2)
        const newCredit = round((parseFloat(item.Credit) / this.exRate), 2)
        this.Total1 += newDebit
        this.Total2 += newCredit
        //this.Total3 += parseFloat(item.DebitForegin)
        //this.Total4 += parseFloat(item.CreditForegin)
        partnerId = item.partnerId ? parseInt(item.partnerId) : null
        const itInvoice = parseInt(item.invoiceInNumber) > 0 ? parseInt(item.invoiceInNumber) : parseInt(item.invoiceOutNumber)
        const bookName = item.BookName !== 'BS' ? item.BookName : 'Izv.' + parseInt(item.BookID)
        const object2 = {
          Debit: newDebit,
          Credit: newCredit,
          itBookName: itLine + '. ' + bookName,
          CreditDebit: this.Total1 - this.Total2,
          itInvoice: itInvoice
        }
        const object3 = {...item, ...object2}
        this.pItems.push(object3)
      })
    }
    this.Total5 = this.Total1 - this.Total2
    this.partner = partnerId ? this.partners.find(partner => (partner.id === partnerId)) : {}

    this.card = {...this.head}
    this.card.items = this.pItems
    //this.card = {...this.card, ...this.lastOne}
    this.card.Total1 = this.Total1
    this.card.Total2 = this.Total2
    this.card.Total5 = this.Total5

    // if (partnerVATID) {
    //   this.mainQuery2.partnerVATID = partnerVATID
    // }
    // await PartnerService.check(this.mainQuery2)
    // .then(res => {
    //   if (res.data.partner) {
    //     this.partner = res.data.partner
    //   }                
    // })
    // .catch(err => {
    //   this.error = err.data
    // })

  },
  computed: {

  },
  methods: {
    print(){
      this.$refs.prnt.print()
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    close () {
      this.show = false
    }
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
