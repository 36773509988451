<template>
  <div style="background-color: #F5F5F5">

    <v-container grid-list-md text-xs-center>
      <v-progress-linear v-if="saving"
        indeterminate
        color="blue"
      ></v-progress-linear>
      <v-row>
        <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
          <h2 v-if="actionType === 1">
            {{lang.SalarieDefinition}}, {{employee.First}} {{employee.Last}}, {{employee.hashtag}}
          </h2>
          <h2 v-if="actionType === 2">
            Obračun plaće {{employee.First}} {{employee.Last}}, {{employee.hashtag}}
          </h2>
        </v-col>
        <v-col cols="6" sm="6" md="2" xs="6" lg="2" xl="2" class="pr-2">
          <span v-if="actionType === 2">
          <v-btn
            text
            @click="createYearCard">
            Godišnja kartica
          </v-btn>
          </span>
        </v-col>

        <v-col cols="6" sm="6" md="2" xs="6" lg="2" xl="2" class="pr-2">
          <v-btn
            class="primary"
            @click="register">
            {{langC.Save}}
          </v-btn>
        </v-col>

        <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" class="pr-2">
          <v-btn
            class="primary"
            @click="employeeList">
            {{langC.Back}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >
          <v-btn text color="primary" @click="showNote1()" class="pb-3 pl-0">Bilješka <v-icon v-if="!showNote">mdi-chevron-down</v-icon><v-icon v-if="showNote">mdi-chevron-up</v-icon></v-btn>
          <v-textarea
            dense
            background-color="amber lighten-5"
            label="Billješka"
            v-model="employee.Biljeska"
            v-if="showNote"
          ></v-textarea>
        </v-col>
      </v-row>

    <div v-if="actionType === 2">
       <v-card
            class="pa-2 pt-4 ma-1"
                outlined
              >
      <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          Za period:    
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12" lg="2" xl="2" class="pr-2">
          Od: <input type="date" v-model="employee.StartInMonth" />         
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12" lg="2" xl="2" class="pr-2">
          Do: <input type="date" v-model="employee.FinishInMonth" />  
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2"  >

        </v-col>
    </v-row>
    <v-divider class="mt-2 mb-4" v-if="actionType === 2"></v-divider>

      <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          Ugovorena plaća          
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati/mjesec"
            v-model.number="employee.FinWH"
            type="number"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sat vrijednost"
            v-model.number="employee.FinWHvalue"
            type="number"
          ></v-text-field> 
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="3" xl="3" class="pr-2">
          
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
            dense
            label="Ugovorena_plaća"
            v-model.number="employee.FinBruto0"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2"  >
          <v-checkbox
            class="pa-0 ma-0"
            v-model="employee.CalculateByHours"
            label="Računaj na sate"
          ></v-checkbox> 
        </v-col>
    </v-row>

     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Redovan Rad
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.RedovanRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.RedovanRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label=""
            v-model.number="employee.RedovanRadIznos"
            type="number"
            disabled
          ></v-text-field>
        </v-col>
    </v-row>
    <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Godišnji Odmor
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.GodisnjiOdmor"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.GodisnjiOdmorPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.GodisnjiOdmorIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Bolovanje Do 42 Dana
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.BolovanjeDo42Dana"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.BolovanjeDo42DanaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.BolovanjeDo42DanaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
        <v-col cols="12" xs="11" sm="11" md="6" lg="1" xl="1" >
           <v-btn text color="primary" @click="showAllHours1()" class="pb-3 pl-0">Ostala satnica <v-icon v-if="!showAllHours">mdi-chevron-down</v-icon><v-icon v-if="showAllHours">mdi-chevron-up</v-icon></v-btn> 
        </v-col>
    </v-row>

    <div v-if="showAllHours">
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Bolovanje Od 42 Dana
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.BolovanjeOd42Dana"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.BolovanjeOd42DanaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.BolovanjeOd42DanaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>


     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Noćni Rad
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.NocniRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.NocniRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Posto"
            v-model.number="employee.NocniRadIznos"
            type="number"
            disabled
          ></v-text-field> 
        </v-col>
    </v-row>

     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Prekovremeni Rad
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.PrekovremeniRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.PrekovremeniRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.PrekovremeniRadIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
    
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Nedjelja
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.NedjeljaRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.NedjeljaRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.NedjeljaRadIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Blagdan
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.BlagdanRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.BlagdanRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.BlagdanRadIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Teren
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.TerenRad"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.TerenRadPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.TerenRadIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Preraspodjela Rada
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.PreraspodjelaRada"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.PreraspodjelaRadaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.PreraspodjelaRadaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Pripravnost
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Pripravnost"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.PripravnostPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.PripravnostIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Dnevni Odmor
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.DnevniOdmor"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.DnevniOdmorPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.DnevniOdmorIznos"
            type="number"
            diabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Tjedni Odmor
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.TjedniOdmor"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.TjedniOdmorPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.TjedniOdmorIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Neradni Blagdan
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.NeradniBlagdan"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.NeradniBlagdanPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.NeradniBlagdanIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>

     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Rodiljni dop.
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Rodiljni"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.RodiljniPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.RodiljniIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Plaćeni Dopust
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.PlaceniDopust"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.PlaceniDopustPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.PlaceniDopustIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Neplaćeni Dopust
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.NeplaceniDopust"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.NeplaceniDopustPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.NeplaceniDopustIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Nenazočnost Na Zahtjev Radnika
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.NenazocnostNaZahtjevRadnika"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.NenazocnostNaZahtjevRadnikaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
       <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.NenazocnostNaZahtjevRadnikaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Zastoj Krivnjom Poslodavca
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.ZastojKrivnjomPoslodavca"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.ZastojKrivnjomPoslodavcaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.ZastojKrivnjomPoslodavcaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Štrajk
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Strajk"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.StrajkPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.StrajkIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Lockout
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Lockout"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.LockoutPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.LockoutIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>
     <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          Vrijeme Mirovanja Radnog Odnosa
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.VrijemeMirovanjaRadnogOdnosa"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.VrijemeMirovanjaRadnogOdnosaPosto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.VrijemeMirovanjaRadnogOdnosaIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>  

    <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-text-field
            dense
            label="Dodatno 1"
            v-model="employee.Dodatno1Opis"
          ></v-text-field>  
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Dodatno1"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.Dodatno1Posto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.Dodatno1Iznos"
            type="number"
            disabled
          ></v-text-field>
        </v-col>
    </v-row> 

    <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-text-field
            dense
            label="Dodatno 2"
            v-model="employee.Dodatno2Opis"
          ></v-text-field>  
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Dodatno2"
            type="number"
            :rules="rules"
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Postotak %"
            v-model.number="employee.Dodatno2Posto"
            type="number"
            :rules="rules"
          ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.Dodatno2Iznos"
            type="number"
            disabled
          ></v-text-field>
        </v-col>
    </v-row> 
    </div>
    <v-row dense v-if="actionType === 2">
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          UKUPNO:
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-text-field
            dense
            label="Sati"
            v-model.number="employee.Ukupno"
            type="number"
            disabled
          ></v-text-field>             
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">

        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
            dense
            label="Iznos"
            v-model.number="employee.UkupnoIznos"
            type="number"
            disabled
          ></v-text-field>                  
        </v-col>
    </v-row>                                                                            
       </v-card>
    </div>

    <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >
      <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold"> {{lang.Bruto1}}</span>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="2" xl="2" class="pr-2">
            <v-text-field
              v-if="!do2024"
              dense
              label="Bruto 1 u Kn"
              v-model.number="FinBruto1Kn"
              type="number"
              :disabled = "fromKnToEur"
            ></v-text-field>
            <v-text-field
              v-if="do2024"
              dense
              label="Bruto plaća već obračunata"
              v-model.number="employee.FinBrutoOther"
              type="number"
            ></v-text-field>          
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-icon @click="brutoKnSwitch"  v-if="!do2024">
              {{fromKnToEur ? 'mdi-pencil' : 'mdi-check'}}
            </v-icon>
            <v-text-field
              v-if="do2024"
              dense
              label="Iskorišten iznos umanjena MIO 1"
              v-model.number="employee.IskoristenoPravo"
              type="number"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.Bruto1"
              v-model.number="employee.FinBruto1"
              type="number"
              outlined
            ></v-text-field>                  
        </v-col>

    </v-row>
    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          Umanjenje osnovice za MIO 1. (joppd)
        </v-col>

        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              v-if="do2024"
              dense
              label="Oznaka (7.2)"
              v-model.number="employee.Joppd72"
              type="number"
            ></v-text-field>               
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              v-if="do2024"
              dense
              label="Iznos umanjenja mj.os.(12.9)"
              v-model.number="employee.Joppd129"
              type="number"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-checkbox
            class="mt-0 pt-0"
            v-model="employee.NeRacunajUmanjenje"
            label="BEZ umanjenja"
            v-if="do2024"
          ></v-checkbox>
        </v-col>

    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-text-field
              dense
              :label="lang.Add1"
              v-model.number="employee.FinKoef1Desc"
            ></v-text-field> 
        </v-col>
        <v-col cols="6" sm="6" md="6" xs="6" lg="2" xl="2" class="pr-2">
          <v-checkbox
            v-model="employee.FinTaxOnTaxVukovar"
            label="0,5% na staž"
          ></v-checkbox>
           <!-- <v-checkbox>
              class="shrink mr-0 mt-0"
              v-model="employee.FinTaxOnTaxVukovar"
              <template v-slot:label>
                <span style="font-size: 12px">Dodaj 0,5% za god.staža</span>
            </template> 
          </v-checkbox> -->
  
        </v-col>
        <v-col cols="6" sm="6" md="6" xs="6" lg="1" xl="1" >
          <v-text-field
              dense
              :label="lang.Add1"
              v-model.number="employee.FinKoef1"
              type="number"
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-text-field
              dense
              :label="lang.Add2"
              v-model.number="employee.FinKoef2Desc"
            ></v-text-field> 
        </v-col>
        <v-col cols="6" sm="1" md="1" xs="6" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="6" sm="6" md="6" xs="6" lg="1" xl="1" >
          <v-text-field
              dense
              :label="lang.Add2"
              v-model.number="employee.FinKoef2"
              type="number"
            ></v-text-field>                  
        </v-col>
    </v-row>

     <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          {{lang.Bruto2}}
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.Bruto2"
              v-model.number="employee.FinBruto2"
              type="number"
              outlined
            ></v-text-field>                  
        </v-col>
    </v-row>
    </v-card>

    <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >
    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold">{{lang.SalFrom}}</span>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesFrom"
              v-model="selectSalariesFrom1"
              :label="lang.SalFrom1"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalFrom2Per"
              v-model="employee.FinFrom1_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalFrom2Amount"
              v-model="employee.FinFrom1_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>  

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesFrom"
              v-model="selectSalariesFrom2"
              :label="lang.SalFrom1"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalFrom2Per"
              v-model="employee.FinFrom2_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalFrom2Amount"
              v-model="employee.FinFrom2_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesFrom"
              v-model="selectSalariesFrom3"
              :label="lang.SalFrom1"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalFrom2Per"
              v-model="employee.FinFrom3_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalFrom2Amount"
              v-model="employee.FinFrom3_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesFrom"
              v-model="selectSalariesFrom4"
              :label="lang.SalFrom1"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalFrom2Per"
              v-model="employee.FinFrom4_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalFrom2Amount"
              v-model="employee.FinFrom4_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold">{{lang.FinNeto1ls}}</span>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.FinNeto1ls"
              v-model="employee.FinNeto1ls"
              type="number"
              disabled
              outlined
            ></v-text-field>                  
        </v-col>
    </v-row>
    </v-card>
  <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >

    <v-row dense>
        <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-2">
          <span  style="font-weight:bold">{{lang.Deduction}}</span>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-checkbox
            v-model="employee.FinFreeDisabled"
            class="shrink mr-0 mt-0 ml-3"
          >
          <template v-slot:label>
            <span style="font-size: 12px">NE koristi se</span>
          </template>
          </v-checkbox>                 
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.DeductionFactor"
              v-model="employee.FinFree1Indeks"
              type="number"
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFree1OnAm"
              type="number"
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          Broj djece   
        </v-col>
        <!-- <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="children"
              v-model="selectChildren"
              :label="lang.DeductionKids"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>           
        </v-col> -->
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
          <v-checkbox 
            class="shrink mr-0 mt-0 ml-3"
            label="1"
            v-model="employee.Child1">
          </v-checkbox> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
          <v-checkbox 
            class="shrink mr-0 mt-0 ml-3"
            label="2"
            v-model="employee.Child2">
          </v-checkbox>     
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
          <v-checkbox
            class="shrink mr-0 mt-0 ml-3"
            label="3"
            v-model="employee.Child3">
          </v-checkbox> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
          <v-checkbox 
            class="shrink mr-0 mt-0 ml-3"
            label="4"
            v-model="employee.Child4">
          </v-checkbox>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.DeductionFactor"
              v-model="employee.FinFree2Indeks"
              type="number"
              disabled
            ></v-text-field>                 
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.DeductionUse"
              v-model="employee.ChildPer"
              type="number"
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFree2Am"
              type="number"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="familysupport"
              v-model="selectFamilySupport"
              :label="lang.DeductionFamily"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>           
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.DeductionFactor"
              v-model="employee.FinFree3Indeks"
              type="number"
              disabled
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFree3Am"
              type="number"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="invalidtype"
              v-model="selectInvalidType"
              :label="lang.DeductionDisablity"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>           
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.DeductionFactor"
              v-model="employee.FinFree4In"
              type="number"
              disabled
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFree4Am"
              type="number"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
           <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFreeTotal"
              type="number"
              disabled
            ></v-text-field>     
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
             <v-text-field
              dense
              :label="lang.DeductionUse"
              v-model="employee.FinFreeTotalPer"
              type="number"
            ></v-text-field>     
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.DeductionAmount"
              v-model="employee.FinFreeTotalToUse"
              type="number"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>
  </v-card>
  <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >

  <div v-if="!do2024">
    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold"> {{lang.TaxTax}}</span>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
     
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-autocomplete
              :items="cities"
              v-model="selectCity"
              :label="lang.CityTaxId"
              item-text="CityName"
              item-value="CityCode"
              return-object
              persistent-hint
              clearable
              dense
            ></v-autocomplete> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.CityTaxId"
              v-model="employee.FinTaxOnTaxCityCode"
              type="text"
              disabled
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-text-field
              dense
              :label="lang.CityTaxName"
              v-model="employee.FinTaxOnTaxCityName"
              type="text"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          {{lang.Tax20}} {{lang.Tax20Desc}}
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              :label="lang.Tax20"
              v-model="employee.FinTax1From"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
            <v-text-field
              dense
              :label="lang.Tax20Am"
              v-model="employee.FinTax1Am"
              type="number"
              disabled
            ></v-text-field>  
        </v-col>
    </v-row>
    <v-row dense>
        
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          {{lang.Tax30}} {{lang.Tax30Desc}}
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              :label="lang.Tax30"
              v-model="employee.FinTax2From"
              type="number"
              disabled
            ></v-text-field>               
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
            <v-text-field
              dense
              :label="lang.Tax30Am"
              v-model="employee.FinTax2Am"
              type="number"
              disabled
            ></v-text-field>                
        </v-col>
    </v-row>
  </div>

  <div v-if="do2024">
    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
         <span  style="font-weight:bold"> Porez</span>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
     
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-autocomplete
              :items="cities"
              v-model="selectCity"
              :label="lang.CityTaxId"
              item-text="CityName"
              item-value="CityCode"
              return-object
              persistent-hint
              clearable
              dense
            ></v-autocomplete> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.CityTaxId"
              v-model="employee.FinTaxOnTaxCityCode"
              type="text"
              disabled
            ></v-text-field>                 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-text-field
              dense
              :label="lang.CityTaxName"
              v-model="employee.FinTaxOnTaxCityName"
              type="text"
              disabled
            ></v-text-field>                  
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          Porez niža stopa (do 4200€/mj)
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              label="Niža stopa %"
              v-model="employee.FinTax1Per"
              type="number"
              :rules="rules20"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              label="Porez osn"
              v-model="employee.FinTax1From"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
            <v-text-field
              dense
              label="Porez n.stopa iznos"
              v-model="employee.FinTax1Am"
              type="number"
              disabled
            ></v-text-field>  
        </v-col>
    </v-row>
    <v-row dense>
        
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          Porez viša stopa (od 4200€/mj)
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              label="Viša stopa %"
              v-model="employee.FinTax2Per"
              type="number"
              :rules="rules30"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
           <v-text-field
              dense
              label="Porez osn"
              v-model="employee.FinTax2From"
              type="number"
              disabled
            ></v-text-field>               
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
            <v-text-field
              dense
              label="Porez v.stopa iznos"
              v-model="employee.FinTax2Am"
              type="number"
              disabled
            ></v-text-field>                
        </v-col>
    </v-row>
  </div>


    <v-row dense v-if="$store.state.documentActivHead.Month === 11">
        
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          Godišnja razlika poreza
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">              
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-text-field
              dense
              label="Razlika"
              v-model="employee.FinTax3To"
              type="number"
            ></v-text-field>                
        </v-col>
    </v-row>

    <div v-if="!do2024">
      <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                  
          </v-col>
          <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-select
                :items="citytax"
                v-model="selectCitytax"
                :label="lang.CityTaxPer"
                single-line
                item-text="text"
                item-value="value"
                return-object
                persistent-hint
                clearable
                dense
              ></v-select>           
          </v-col>
          <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                  
          </v-col>
          <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
                dense
                :label="lang.TaxOnTax"
                v-model="employee.FinTaxOnTaxPer"
                type="number"
                disabled
              ></v-text-field>               
          </v-col>
          <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
              <v-text-field
                dense
                :label="lang.TaxOnTaxAm"
                v-model="employee.FinTaxOnTaxAm"
                type="number"
                disabled
              ></v-text-field>                
          </v-col>
      </v-row>
    </div>
    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
         {{do2024 ? "Porez ukupno €=" : lang.TaxTotal}}
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
             
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
            <v-text-field
              dense
              label="Ukupno"
              v-model="employee.FinTaxTotal"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
    </v-row>
  </v-card>
  <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >

    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold"> {{lang.Neto}}</span>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.Neto"
              v-model="employee.FinNeto2"
              type="number"
              :disabled="netoChange"
              outlined
            ></v-text-field>                  
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
              <v-icon
                  @click="netoSwitch"
                >
                  {{netoChange ? 'mdi-pencil' : 'mdi-check'}}
                </v-icon>
        </v-col>
    </v-row>
  </v-card>
    <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >

    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <span  style="font-weight:bold"> Neoporezive naknade i obustave</span>
        </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-text-field
          dense
          :label="lang.NetoAddDesc"
          v-model="employee.FinAdd1_Desc"
          type="text"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="notaxaddition"
            v-model="oneNotaxaddition"
            :label="lang.NetoAddType"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>              
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="joppd"
            v-model="oneJoppd"
            :label="lang.NetoAddJoppd"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>  
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
            dense
              :label="lang.NetoAddAmounut"
              v-model="employee.FinAdd1_Amount"
              type="number"
          ></v-text-field>                  
      </v-col>
    </v-row>

    <v-row dense>

      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-text-field
          dense
          :label="lang.NetoAddDesc"
          v-model="employee.FinAdd2_Desc"
          type="text"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="notaxaddition"
            v-model="twoNotaxaddition"
            :label="lang.NetoAddType"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>              
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="joppd"
            v-model="twoJoppd"
            :label="lang.NetoAddJoppd"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>  
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
            dense
              :label="lang.NetoAddAmounut"
              v-model="employee.FinAdd2_Amount"
              type="number"
          ></v-text-field>                  
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-text-field
          dense
          :label="lang.NetoAddDesc"
          v-model="employee.FinAdd3_Desc"
          type="text"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="notaxaddition"
            v-model="threeNotaxaddition"
            :label="lang.NetoAddType"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>              
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="joppd"
            v-model="threeJoppd"
            :label="lang.NetoAddJoppd"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>  
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
            dense
              :label="lang.NetoAddAmounut"
              v-model="employee.FinAdd3_Amount"
              type="number"
          ></v-text-field>                  
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-text-field
          dense
          :label="lang.NetoAddDesc"
          v-model="employee.FinAdd4_Desc"
          type="text"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="notaxaddition"
            v-model="fourNotaxaddition"
            :label="lang.NetoAddType"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>              
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        <v-select
            :items="joppd"
            v-model="fourJoppd"
            :label="lang.NetoAddJoppd"
            single-line
            item-text="text"
            item-value="value"
            return-object
            persistent-hint
            clearable
            dense
        ></v-select>  
      </v-col>
      <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
        <v-text-field
            dense
              :label="lang.NetoAddAmounut"
              v-model="employee.FinAdd4_Amount"
              type="number"
          ></v-text-field>                  
      </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Obustava 1"
              v-model="employee.Fin1Loan"
            ></v-text-field>          
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-select
              :items="banks"
              v-model="selectBankLoan1"
              label="Kod banke"
              single-line
              item-text="text"
              item-value="vatid"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2 pt-0" >
          <small>Zadnji dat.ispl.</small>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2 pt-0" >
           <input type="date" v-model="employee.Fin1LoanLastDate" />
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
          <v-text-field
              dense
              label="Iznos obustave 1"
              v-model.number="employee.Fin1LoanBankAmount"
              type="number"
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Žiro rn.banke"
              v-model="employee.Fin1LoanBankAccountNr1"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              label="Poziv Na"
              v-model="employee.Fin1LoanBankAccountNr2"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              label="Poziv Broj"
              v-model="employee.Fin1LoanBankAccountNr3"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" v-if="actionType === 2">
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Obustava 2"
              v-model="employee.Fin2Loan"
            ></v-text-field>          
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-select
              :items="banks"
              v-model="selectBankLoan2"
              label="Kod banke"
              single-line
              item-text="text"
              item-value="vatid"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2 pt-0" >
         <small>Zadnji dat.ispl.</small>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2 pt-0" >
           <input type="date" v-model="employee.Fin2LoanLastDate" />
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
          <v-text-field
              dense
              label="Iznos obustave 2"
              v-model.number="employee.Fin2LoanBankAmount"
              type="number"
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Žiro rn.banke"
              v-model="employee.Fin2LoanBankAccountNr1"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              label="Poziv Na"
              v-model="employee.Fin2LoanBankAccountNr2"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              label="Poziv Broj"
              v-model="employee.Fin2LoanBankAccountNr3"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" v-if="actionType === 2">
        </v-col>
    </v-row>

      <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Obustava 3"
              v-model="employee.Fin3Loan"
            ></v-text-field>          
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-select
              :items="banks"
              v-model="selectBankLoan3"
              label="Kod banke"
              single-line
              item-text="text"
              item-value="vatid"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2 pt-0" >
          <small>Zadnji dat.ispl.</small>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2 pt-0" >
           <input type="date" v-model="employee.Fin3LoanLastDate" />
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="1" xl="1" >
          <v-text-field
              dense
              label="Iznos obustave 3"
              v-model.number="employee.Fin3LoanBankAmount"
              type="number"
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
        
        </v-col>

        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Žiro rn.banke"
              v-model="employee.Fin3LoanBankAccountNr1"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              label="Poziv Na"
              v-model="employee.Fin3LoanBankAccountNr2"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              label="Poziv Broj"
              v-model="employee.Fin3LoanBankAccountNr3"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" v-if="actionType === 2">
        </v-col>
    </v-row>
    </v-card>
    <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >

    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="headline pr-2">
          {{lang.FinNeto3}}
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              class="headline text-blue"
              :label="lang.FinNeto3"
              v-model="employee.FinNeto3"
              type="number"
              disabled
              outlined
            ></v-text-field>                  
        </v-col>
    </v-row>
  </v-card>
  <v-card
    class="pa-2 pt-4 ma-1"
        outlined
      >


    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            Redovan račun           
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-select
              :items="banks"
              v-model="selectBank1"
              label="Redovan račun kod banke"
              single-line
              item-text="text"
              item-value="vatid"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Žiro rn.banke"
              v-model="employee.Fin1bankAccountNr1"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              label="Poziv Na"
              v-model="employee.Fin1bankAccountNr2"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              label="Poziv Broj"
              v-model="employee.Fin1bankAccountNr3"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" v-if="actionType === 2">
          <v-text-field
              dense
              label="Iznos"
              v-model.number="employee.Fin1bankAmount"
              type="number"
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            Zaštićeni račun     
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
          <v-select
              :items="banks"
              v-model="selectBank2"
              label="Zaštićeni račun kod banke"
              single-line
              item-text="text"
              item-value="vatid"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
            <v-text-field
              dense
              label="Žiro rn.banke"
              v-model="employee.Fin2bankAccountNr1"
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
          <v-text-field
              dense
              label="Poziv Na"
              v-model="employee.Fin2bankAccountNr2"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              label="Poziv Broj"
              v-model="employee.Fin2bankAccountNr3"
            ></v-text-field>
        </v-col>
         <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" v-if="actionType === 2">
          <v-text-field
              dense
              label="Iznos"
              v-model.number="employee.Fin2bankAmount"
              type="number"
            ></v-text-field>
        </v-col>
    </v-row>
  </v-card>
  <v-card
    class="pa-2 pt-4 ma-1 mb-4"
        outlined
      >

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesTo"
              v-model="selectSalariesTo1"
              :label="lang.SalTo"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalToPer"
              v-model="employee.FinTo1_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalToAmount"
              v-model="employee.FinTo1_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesTo"
              v-model="selectSalariesTo2"
              :label="lang.SalTo"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalToPer"
              v-model="employee.FinTo2_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalToAmount"
              v-model="employee.FinTo2_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          <v-select
              :items="salariesTo"
              v-model="selectSalariesTo3"
              :label="lang.SalTo"
              single-line
              item-text="descHR"
              item-value="value"
              return-object
              persistent-hint
              clearable
              dense
            ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
            <v-text-field
              dense
              :label="lang.SalToPer"
              v-model="employee.FinTo3_Percent"
              type="number"
              disabled
            ></v-text-field> 
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.SalToAmount"
              v-model="employee.FinTo3_Amount"
              type="number"
              disabled
            ></v-text-field>
        </v-col>
    </v-row> 
  </v-card>   

    <v-row dense>
        <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
          {{lang.Bruto3}}
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-2">
                            
        </v-col>
        <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
          <v-text-field
              dense
              :label="lang.Bruto3"
              v-model="employee.FinBruto3"
              type="number"
              disabled
            ></v-text-field>                  
        </v-col>
        <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-2">
          <v-btn
            class="primary"
            @click="register">
            {{langC.Save}}
          </v-btn>
        </v-col>
    </v-row> 


    <v-row dense v-if="actionType === 2">
      <v-col cols="12" sm="1" md="1" lg="1" xl="1">
          <v-btn text color="red" @click="salarieDelete" class="mr-1">
            {{langC.Delete}}
          </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <SalariePrintCardEmpYear ref="prnt5" />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

    </v-container>



  </div>
</template>

<script>
import EmployeeService from '@/services/EmployeeService'
import SalarieService from '@/services/SalarieService'
import SalarieDetailService from '@/services/SalarieDetailService'
import CityService from '@/services/CityService'
import SalariePrintCardEmpYear from '../Salarie/SalariePrintCardEmpYear'
import langEn from './empDescEn'
import langHr from './empDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dayjs from 'dayjs'
import {dynamicSort, round} from '@/snippets/allSnippets'
//import {, round} from '@/snippets/allSnippets'

export default {
  name: 'employeedit3',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      saving: false,
      msg: '',
      newEmployee: {},
      employee: { },
      required: (value) => !!value || 'Required.',
      error: '',
      salariesFrom: [],
      selectSalariesFrom1: {},
      selectSalariesFrom2: {},
      selectSalariesFrom3: {},
      selectSalariesFrom4: {},
      children: [],
      selectChildren: {},
      familysupport: [],
      selectFamilySupport: {},
      invalidtype: [],
      selectInvalidType: {},
      citytax: [],
      cities: [],
      selectCity: {},
      selectCitytax: {},
      salariesTo: [],
      selectSalariesTo1: {},
      selectSalariesTo2: {},
      selectSalariesTo3: {},
      notaxaddition: [],
      oneNotaxaddition: {},
      twoNotaxaddition: {},
      threeNotaxaddition: {},
      fourNotaxaddition: {},
      fiveNotaxaddition: {},
      joppd: [],
      oneJoppd: {},
      twoJoppd: {},
      threeJoppd: {},
      fourJoppd: {},
      fiveJoppd: {},
      actionType: 0,
      rules: [
         v => v >= 0 || 'Mora biti 0 ili više',
         v => v <= 500 || 'Ne može biti više od 500',
      ],
      rules20: [
         v => v >= 15 || 'Mora biti 15 ili više',
         v => v <= 23.60 || 'Ne može biti više od 23.60',
      ],
      rules30: [
         v => v >= 25 || 'Mora biti 25 ili više',
         v => v <= 36 || 'Ne može biti više od 36',
      ],
      banks: [],
      selectBank1: {},
      selectBank2: {},
      selectBankLoan1: {},
      selectBankLoan2: {},
      selectBankLoan3: {},
      dialog1: false,
      modal1: false,
      dialog2: false,
      modal2: false,
      dialog3: false,
      modal3: false,
      netoChange: true,
      FinBruto1Kn: 0,
      fromKnToEur: true,
      showNote: false,
      showAllHours: false,
      do2024: false,
      osobniOdbitak: 560
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentActivHead.Month < 12 && this.$store.state.documentActivHead.Year < 2023) {
      this.do2024 = false
    }
    if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2023) {
      this.do2024 = true
    }
    if (this.$store.state.documentActivHead.Year === 2024) {
      this.do2024 = true
    }
    this.osobniOdbitak = this.do2024 ? 560 :  530.90
    this.saving = true
    this.salariesFrom = this.$store.state.salariesFrom
    this.children = this.$store.state.children
    this.familysupport = this.$store.state.familysupport
    this.invalidtype = this.$store.state.invalidtype
    this.citytax = this.$store.state.citytax
    this.salariesTo = this.$store.state.salariesTo
    this.notaxaddition = this.$store.state.joppdNotax
    this.joppd = this.$store.state.joppd 

    this.actionType = this.$store.state.actionType
    this.banks = this.$store.state.banksHR
    const {data} = await CityService.index()
      if (data && data.cities !== undefined){
        this.cities = data.cities
      }

    if (this.$store.state.employeeid) {
      try {
        if (this.$store.state.actionType === 1) {
          const dbEmployee = await EmployeeService.show(this.$store.state.employeeid)
          this.employee = dbEmployee.data.employee

        }
        if (this.$store.state.actionType === 2) {

          const query = {}
          query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          query.id = this.$store.state.employeeid ? this.$store.state.employeeid: 0
          const dbEmployee = await SalarieDetailService.choose(query)
          //console.log(dbEmployee.data.salariedetails[0])
          this.employee = dbEmployee.data.salariedetails[0]
          const dbEmp = await EmployeeService.show(this.employee.empID)
          if (dbEmp && dbEmp.data && dbEmp.data.employee) {
            const tempEmp = dbEmp.data.employee
            this.employee.WorkYearsBefore = tempEmp.WorkYearsBefore ? tempEmp.WorkYearsBefore : 0
            this.employee.WorkMonthsBefore = tempEmp.WorkMonthsBefore ? tempEmp.WorkMonthsBefore : 0
            this.employee.WorkDaysBefore = tempEmp.WorkDaysBefore ? tempEmp.WorkDaysBefore : 0
            this.employee.StartInCompany = tempEmp.StartInCompany ? tempEmp.StartInCompany : null
          }
        }
        if (this.employee.FinFrom1Per === null || this.employee.FinFrom1Per === 0) {
          this.employee.FinFrom1Desc = 'Doprinos MIO 1.stup'
          this.employee.FinFrom1Per = 20
        }
        if (this.employee.FinFrom2Per === null || this.employee.FinFrom2Per === 0) {
          this.employee.FinFrom2Desc = 'Doprinos MIO 2.stup'
          this.employee.FinFrom2Per = 0
        }
      } catch (error) {
        this.error = error
      }

      if (!this.employee.FinFreeDisabled && this.round(this.employee.FinFree1Indeks, 2) === 0) {
        const basicAm = this.children.find(child => child.calc === 1)
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = this.round((basicAm * 1.6), 2)
        this.employee.FinFreeTotal = this.round((basicAm * 1.6), 2)
        this.employee.FinFreeTotalPer = 100
        this.employee.FinFreeTotalToUse = this.round((basicAm * 1.6), 2)
      }
    //  if (this.round(this.employee.FinBruto1, 2) !== 0) {
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
     // }
    }
    this.selectValues()
    this.saving = false
  },
  methods: {
    showNote1() {
      this.showNote = !this.showNote
    },
    showAllHours1() {
      this.showAllHours = !this.showAllHours
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    async salarieDelete() {
      const warningMessage = this.employee.First + ' ' + this.employee.Last + ' iz obračuna ' +this.$store.state.documentActivHead.Order + '/' + this.$store.state.documentActivHead.Month + '/' + this.$store.state.documentActivHead.Year
      if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!')) {
        // delete employeee from salarie liste
        const deletedEmp = await SalarieDetailService.delete(this.employee.id)
        console.log('Deleted emp ', deletedEmp)
        this.$router.push({
            name: 'salariedetail'
          })
      }
    },
    selectValues() {
      if (this.employee) {
        this.selectSalariesFrom1 = this.employee.FinFrom1_id ? this.salariesFrom.find(from1 => (parseInt(from1.value) === this.employee.FinFrom1_id )) : null
        this.selectSalariesFrom2 = this.employee.FinFrom2_id ? this.salariesFrom.find(from2 => (parseInt(from2.value) === this.employee.FinFrom2_id )) : null
        this.selectSalariesFrom3 = this.employee.FinFrom3_id ? this.salariesFrom.find(from3 => (parseInt(from3.value) === this.employee.FinFrom3_id )) : null
        this.selectSalariesFrom4 = this.employee.FinFrom4_id ? this.salariesFrom.find(from4 => (parseInt(from4.value) === this.employee.FinFrom4_id )) : null

        this.selectChildren = this.employee.FinFree2OnAm ? this.children.find(child => (parseInt(child.value) === this.employee.FinFree2OnAm )) : null
        this.selectFamilySupport = this.employee.FinFree3OnAm ? this.familysupport.find(fams => (parseInt(fams.value) === this.employee.FinFree3OnAm )) : null
        this.selectInvalidType = this.employee.FinFree4OnAm ? this.invalidtype.find(itype => (parseInt(itype.value) === this.employee.FinFree4OnAm )) : null

        this.oneNotaxaddition = this.employee.FinAdd1_TypeId ? this.notaxaddition.find(notax1 => (parseInt(notax1.value) === this.employee.FinAdd1_TypeId )) : null
        this.twoNotaxaddition = this.employee.FinAdd2_TypeId ? this.notaxaddition.find(notax2 => (parseInt(notax2.value) === this.employee.FinAdd2_TypeId )) : null
        this.threeNotaxaddition = this.employee.FinAdd3_TypeId ? this.notaxaddition.find(notax3 => (parseInt(notax3.value) === this.employee.FinAdd3_TypeId )) : null
        this.fourNotaxaddition = this.employee.FinAdd4_TypeId ? this.notaxaddition.find(notax4 => (parseInt(notax4.value) === this.employee.FinAdd4_TypeId )) : null

        this.selectCity = this.cities.find( city => city.CityCode === this.employee.FinTaxOnTaxCityCode)
        this.selectCitytax = this.employee.FinTaxOnTaxPer ? this.citytax.find(city => (parseFloat(city.value) === this.employee.FinTaxOnTaxPer )) : null

        this.oneJoppd = this.employee.FinAdd1_JoppId ? this.joppd.find(joppd1 => ((joppd1.value) === this.employee.FinAdd1_JoppId )) : null
        this.twoJoppd = this.employee.FinAdd2_JoppId ? this.joppd.find(joppd2 => ((joppd2.value) === this.employee.FinAdd2_JoppId )) : null
        this.threeJoppd = this.employee.FinAdd3_JoppId ? this.joppd.find(joppd3 => ((joppd3.value) === this.employee.FinAdd3_JoppId )) : null
        this.fourJoppd = this.employee.FinAdd4_JoppId ? this.joppd.find(joppd4 => ((joppd4.value) === this.employee.FinAdd4_JoppId )) : null

        this.selectSalariesTo1 = this.employee.FinTo1_id ? this.salariesTo.find(To1 => (parseInt(To1.value) === this.employee.FinTo1_id )) : null
        this.selectSalariesTo2 = this.employee.FinTo2_id ? this.salariesTo.find(To2 => (parseInt(To2.value) === this.employee.FinTo2_id )) : null
        this.selectSalariesTo3 = this.employee.FinTo3_id ? this.salariesTo.find(To3 => (parseInt(To3.value) === this.employee.FinTo3_id )) : null

        this.selectBank1 = this.employee.Fin1bankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin1bankAccountId )) : null
        this.selectBank2 = this.employee.Fin2bankAccountId ? this.banks.find(bank2 => ((bank2.vatid) === this.employee.Fin2bankAccountId )) : null

        this.selectBankLoan1 = this.employee.Fin1LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin1LoanBankAccountId )) : null
        this.selectBankLoan2 = this.employee.Fin2LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin2LoanBankAccountId )) : null
        this.selectBankLoan3 = this.employee.Fin3LoanBankAccountId ? this.banks.find(bank1 => ((bank1.vatid) === this.employee.Fin3LoanBankAccountId )) : null
      }
    },
    checkSave() {

    },
    async register () {
      try {
        if (this.$store.state.user.email) {
          this.employee.email = this.$store.state.user.email
          this.employee.LastUpdateBy = this.$store.state.user.email
        }
        if (this.$store.state.actionType === 1) {
          await EmployeeService.put(this.employee)
          this.$router.push({
          name: 'employeeview'
        })
        }
        if (this.$store.state.actionType === 2) {
          console.log(' snimam this.employee', this.employee)
          await SalarieDetailService.put(this.employee)
          this.$router.push({
            name: 'salariedetail'
          })
        }
        
      } catch (error) {
        this.error = error.response.data.error
      }
    },
  employeeList () {

      //this.register()
      if (this.$store.state.actionType === 1) {
        this.$router.push({
          name: 'employeeview'
        })
      }
      if (this.$store.state.actionType === 2) {
        this.$router.push({
          name: 'salariedetail'
        })
      }

    },
    netoSwitch() {
      if (this.netoChange === false) {
        if (!this.employee.FinNeto2) {
          this.employee.FinNeto2 = 0
        }
        if (this.employee.FinNeto2 > 1000000) {
          this.employee.FinNeto2 = 0
        }
        
        this.employee.FinBruto0 = this.round(this.calcBrutoIzNeto(), 2)
        this.employee.FinBruto1 = this.employee.FinBruto0
        this.calculateSal()
      }
      this.netoChange = !this.netoChange
    },
    brutoKnSwitch() {

      if (this.fromKnToEur === false) {
        this.employee.FinBruto0 = this.round((this.FinBruto1Kn / 7.5345), 2) !== 0 ? this.round((this.FinBruto1Kn / 7.5345), 2) : 0
        this.calculateSal()
      }
      this.fromKnToEur = !this.fromKnToEur
    },
    calcBrutoIzNeto() {
      let Bp = 0
      if (this.employee.FinNeto2 && this.employee.FinNeto2 > 0) {
        const N = this.round(this.employee.FinNeto2, 2)
        const O = this.round(this.employee.FinFreeTotalToUse, 2)
        let koefFirst = 1.25
        const brutoPosto = this.round((this.round(this.employee.FinFrom1_Percent, 2) + this.round(this.employee.FinFrom2_Percent, 2)),2)
        if (brutoPosto === 10) {
          koefFirst = 1.111111
        }
        
        let haveIt = false
        // case 1 if N < O
        if (N < O) {
          Bp = N * koefFirst
          haveIt = true
        }
        // 6000 kn = 796.44
        // 4000 kn = 530.90
        // 2500 kn = 331.81

        // case 2 if N < 3981.69 - 796.44 * kpr + O
        if ((N < 3981.69 - 796.44 * this.kpr + O) && !haveIt) {
          Bp = (((N - O) * this.kppr20) + O) / 0.8
        }
        
        // case 3 if N > 3981.69 - 796.44 * kpr + O  - bez ogranicenja
        if ((N > 3981.69 - 796.44 * this.kpr + O) && !haveIt) {
          Bp = (3981.69 + O + (N - (3981.69 - 796.44 * this.kpr + O)) * this.kppr30) / 0.8
        }
        
        // case 4 if N > 3981.69 - 796.44 * kpr + O - sa ogranicenjem !!!! do not use it for now
        // if (N > 3981.69 - 796.44 * this.kpr + O) {
        //   Bp = (3981.69 + O + (N - (3981.69-796.44 * this.kpr + O)) * this.kppr30) + 11440
        //   console.log('4 Bp ', Bp)
        // }
        
        return Bp
        //this.employee.FinBruto2 = this.round(Bp, 2)
        //this.employee.FinBruto1 = this.employee.FinBruto2  -  this.employee.FinKoef1 - this.employee.FinKoef2
      }
    },


    // todo doraditi obracun za djecu 
    calcPersonalFree () {
      if (!this.do2024) {
        let childFaktor = 0
        if (this.employee.Child1) {
          childFaktor = 0.7
        }
        if (this.employee.Child2) {
          childFaktor = this.round((childFaktor  + 1),2)
        }
        if (this.employee.Child3) {
          childFaktor = this.round((childFaktor + 1.4),2)
        }
        if (this.employee.Child4) {
          childFaktor = this.round((childFaktor + 1.9),2)
        }
        const basicAm = this.children.find(child => child.calc === 1)

        if (basicAm) {
          this.employee.FinFree2Indeks = childFaktor
          this.employee.FinFree2OnAm = basicAm.value
          this.employee.FinFree2Am = this.round((childFaktor * basicAm.amount * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
        }
        this.employee.FinFreeTotal = this.round(this.employee.FinFree1OnAm, 2) + this.round(this.employee.FinFree2Am, 2) + this.round(this.employee.FinFree3Am, 2) + this.round(this.employee.FinFree4Am, 2)
        this.employee.FinFreeTotalToUse = this.round((this.employee.FinFreeTotal * (this.employee.FinFreeTotalPer / 100)) ,2)
      }
      if (this.do2024) {
        let childAmount = 0
        let childFaktor = 0
        if (this.employee.Child1) {
          childAmount = this.round((0.5 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor = 0.5
        }
        if (this.employee.Child2) {
          childAmount += this.round((0.7 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 0.7
        }
        if (this.employee.Child3) {
          childAmount += this.round((1 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 1
        }
        if (this.employee.Child4) {
          childAmount += this.round((1.4 * 560 * (this.employee.ChildPer ? this.employee.ChildPer/100 : 0)),2)
          childFaktor += 1.4
        }
        const basicAm = this.children.find(child => child.calc === 1)
        if (basicAm) {
          this.employee.FinFree2Indeks = childFaktor
          this.employee.FinFree2OnAm = basicAm.value
          this.employee.FinFree2Am = childAmount
        }
        this.employee.FinFreeTotal = this.round(this.employee.FinFree1OnAm, 2) + this.round(this.employee.FinFree2Am, 2) + this.round(this.employee.FinFree3Am, 2) + this.round(this.employee.FinFree4Am, 2)
        this.employee.FinFreeTotalToUse = this.round((this.employee.FinFreeTotal * (this.employee.FinFreeTotalPer / 100)) ,2)
      }
    },

    // ----- allCalc
    calculateSal() {
      

      // this.employee.FinBruto0 = typeof this.employee.FinBruto0 === 'string' ? 0 : round(this.employee.FinBruto0, 2)
      if (typeof this.employee.FinBruto0 === 'string'){
        if(this.employee.FinBruto0.length === 0){this.employee.FinBruto0 = 0}
        if(this.employee.FinBruto0.length !== 0){this.employee.FinBruto0 = round(parseFloat(this.employee.FinBruto0),2)}
      }
      if (typeof this.employee.FinBruto1 === 'string'){
        if(this.employee.FinBruto1.length === 0){this.employee.FinBruto1 = 0}
        if(this.employee.FinBruto1.length !== 0){this.employee.FinBruto1 = round(parseFloat(this.employee.FinBruto1),2)}
      }
      // this.employee.FinBruto1 = typeof this.employee.FinBruto1 === 'string' ? 0 : round(this.employee.FinBruto1, 2)
      if (typeof this.employee.FinAdd1_Amount === 'string'){
        if(this.employee.FinAdd1_Amount.length === 0){this.employee.FinAdd1_Amount = 0}
        if(this.employee.FinAdd1_Amount.length !== 0){this.employee.FinAdd1_Amount = round(parseFloat(this.employee.FinAdd1_Amount),2)}
      }
      if (typeof this.employee.FinAdd2_Amount === 'string'){
        if(this.employee.FinAdd2_Amount.length === 0){this.employee.FinAdd2_Amount = 0}
        if(this.employee.FinAdd2_Amount.length !== 0){this.employee.FinAdd2_Amount = round(parseFloat(this.employee.FinAdd2_Amount),2)}
      }
      if (typeof this.employee.FinAdd3_Amount === 'string'){
        if(this.employee.FinAdd3_Amount.length === 0){this.employee.FinAdd3_Amount = 0}
        if(this.employee.FinAdd3_Amount.length !== 0){this.employee.FinAdd3_Amount = round(parseFloat(this.employee.FinAdd3_Amount),2)}
      }
      if (typeof this.employee.FinAdd4_Amount === 'string'){
        if(this.employee.FinAdd4_Amount.length === 0){this.employee.FinAdd4_Amount = 0}
        if(this.employee.FinAdd4_Amount.length !== 0){this.employee.FinAdd4_Amount = round(parseFloat(this.employee.FinAdd4_Amount),2)}
      }
      if (typeof this.employee.FinBruto2 === 'string'){
        if(this.employee.FinBruto2.length === 0){this.employee.FinBruto2 = 0}
        if(this.employee.FinBruto2.length !== 0){this.employee.FinBruto2 = round(parseFloat(this.employee.FinBruto2),2)}
      }
      if (typeof this.employee.FinBrutoOther === 'string'){
        if(this.employee.FinBrutoOther.length === 0){this.employee.FinBrutoOther = 0}
        if(this.employee.FinBrutoOther.length !== 0){this.employee.FinBrutoOther = round(parseFloat(this.employee.FinBrutoOther),2)}
      }
      if (typeof this.employee.IskoristenoPravo === 'string'){
        if(this.employee.IskoristenoPravo.length === 0){this.employee.IskoristenoPravo = 0}
        if(this.employee.IskoristenoPravo.length !== 0){this.employee.IskoristenoPravo = round(parseFloat(this.employee.IskoristenoPravo),2)}
      }

      if (typeof this.employee.Joppd72 === 'string'){
        if(this.employee.Joppd72.length === 0){this.employee.Joppd72 = 0}
        if(this.employee.Joppd72.length !== 0){this.employee.Joppd72 = round(parseFloat(this.employee.Joppd72),2)}
      }
      if (typeof this.employee.Joppd129 === 'string'){
        if(this.employee.Joppd129.length === 0){this.employee.Joppd129 = 0}
        if(this.employee.Joppd129.length !== 0){this.employee.Joppd129 = round(parseFloat(this.employee.Joppd129),2)}
      }
      if (typeof this.employee.Fin1LoanBankAmount === 'string'){
        if(this.employee.Fin1LoanBankAmount.length === 0){this.employee.Fin1LoanBankAmount = 0}
        if(this.employee.Fin1LoanBankAmount.length !== 0){this.employee.Fin1LoanBankAmount = round(parseFloat(this.employee.Fin1LoanBankAmount),2)}
      }
      if (typeof this.employee.Fin2LoanBankAmount === 'string'){
        if(this.employee.Fin2LoanBankAmount.length === 0){this.employee.Fin2LoanBankAmount = 0}
        if(this.employee.Fin2LoanBankAmount.length !== 0){this.employee.Fin2LoanBankAmount = round(parseFloat(this.employee.Fin2LoanBankAmount),2)}
      }
      if (typeof this.employee.Fin3LoanBankAmount === 'string'){
        if(this.employee.Fin3LoanBankAmount.length === 0){this.employee.Fin3LoanBankAmount = 0}
        if(this.employee.Fin3LoanBankAmount.length !== 0){this.employee.Fin3LoanBankAmount = round(parseFloat(this.employee.Fin3LoanBankAmount),2)}
      }
      // this.employee.FinAdd1_Amount = typeof this.employee.FinAdd1_Amount === 'string' ? 0 : round(this.employee.FinAdd1_Amount, 2)
      // this.employee.FinAdd2_Amount = typeof this.employee.FinAdd2_Amount === 'string' ? 0 : round(this.employee.FinAdd2_Amount, 2)
      // this.employee.FinAdd3_Amount = typeof this.employee.FinAdd3_Amount === 'string' ? 0 : round(this.employee.FinAdd3_Amount, 2)
      // this.employee.FinAdd4_Amount = typeof this.employee.FinAdd4_Amount === 'string' ? 0 : round(this.employee.FinAdd4_Amount, 2)
      // this.employee.FinBruto2 = typeof this.employee.FinBruto2 === 'string' ? 0 : round(this.employee.FinBruto2, 2)
      // this.employee.FinBrutoOther = typeof this.employee.FinBrutoOther === 'string' ? 0 : round(this.employee.FinBrutoOther, 2)
      // this.employee.IskoristenoPravo = typeof this.employee.IskoristenoPravo === 'string' ? 0 : round(this.employee.IskoristenoPravo, 2)
      // this.employee.Joppd72 = typeof this.employee.Joppd72 === 'string' ? 0 : round(this.employee.Joppd72, 2)
      // this.employee.Joppd129 = typeof this.employee.Joppd129 === 'string' ? 0 : round(this.employee.Joppd129, 2)
      // this.employee.Fin1LoanBankAmount = typeof this.employee.Fin1LoanBankAmount === 'string' ? 0 : round(this.employee.Fin1LoanBankAmount,2)
      // this.employee.Fin2LoanBankAmount = typeof this.employee.Fin2LoanBankAmount === 'string' ? 0 : round(this.employee.Fin2LoanBankAmount,2)
      // this.employee.Fin3LoanBankAmount = typeof this.employee.Fin3LoanBankAmount === 'string' ? 0 : round(this.employee.Fin3LoanBankAmount,2)

      if (!this.employee.CalculateByHours) {
        this.employee.FinBruto1 = this.employee.FinBruto0 && this.round(this.employee.FinBruto0,2) >= 0 ? this.round(this.employee.FinBruto0,2) : this.employee.FinBruto1
      }
      if (this.employee.FinBruto0 === 0){
        this.employee.FinBruto1 = 0
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
      }
      this.employee.FinBruto1 = this.employee.FinBruto1 ? round(this.employee.FinBruto1,2): 0
      if (this.employee.FinTaxOnTaxVukovar) {
        
        // if have years past to calculate 
        let allYears = 0
        if (this.employee.WorkYearsBefore) {
          allYears = this.employee.WorkYearsBefore * 365
        }
        if (this.employee.WorkYearsBefore) {
          allYears += this.employee.WorkMonthsBefore * 30
        }
        if (this.employee.WorkYearsBefore) {
          allYears += this.employee.WorkDaysBefore
        }
        
        if (this.employee.StartInCompany) {
          let date_1 = new Date(this.employee.StartInCompany)
          let date_2 = new Date()
          let difference = date_2.getTime() - date_1.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
          allYears += TotalDays
          this.employee.FinKoef1 = this.employee.FinBruto1  * ((parseInt(allYears/365) * 0.5) / 100)
          this.employee.FinKoef1Desc = 'Uvećanje od 0,5% za svaku navr.god.radnog staža' + '(Uk.god.:' + parseInt(allYears/365) + ')'
        }
      }
      this.employee.FinKoef1 = this.employee.FinKoef1 ? round(this.employee.FinKoef1, 2): 0
      this.employee.FinKoef2 = this.employee.FinKoef2 ? round(this.employee.FinKoef2,2): 0
      this.employee.FinBruto2 = round((this.employee.FinBruto1 + this.employee.FinKoef1 + this.employee.FinKoef2), 2)
      // console.log('this.employee.FinBruto2', this.employee.FinBruto2)
      if (!this.do2024) {
        this.employee.FinFrom1_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom1_Percent / 100)), 2)
        this.employee.FinFrom2_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom2_Percent / 100)), 2)
        this.employee.FinFrom3_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom3_Percent / 100)), 2)
        this.employee.FinFrom4_Amount = round((this.employee.FinBruto2 * (this.employee.FinFrom4_Percent / 100)), 2)
        this.employee.FinNeto1ls = round((this.employee.FinBruto2 - this.employee.FinFrom1_Amount - this.employee.FinFrom2_Amount - this.employee.FinFrom3_Amount - this.employee.FinFrom4_Amount), 2)
      }
      if (this.do2024) {

        let baseFrom1 = this.employee.FinBruto2
        let baseFrom2 = this.employee.FinBruto2
        let firstPer = 0
        let secPer = 100
        let per = 0
        let imaoUmanjenje = false
        this.employee.Joppd72 = 0
        this.employee.Joppd129 = 0

        // imao je prije nesto bruto 
        if (this.round(this.employee.FinBrutoOther,2) > 0) {
            imaoUmanjenje = true
            per = this.round(this.employee.FinBrutoOther + this.employee.FinBruto2, 2) / 100
            firstPer = this.round(this.employee.FinBrutoOther, 2) / per
            secPer = this.round(this.employee.FinBruto2, 2) / per
        }
        if (this.employee.NeRacunajUmanjenje === true) {
          this.employee.FinBrutoOther = 0
          this.employee.IskoristenoPravo = 0
          this.employee.Joppd72 = 0
          this.employee.Joppd129 = 0
        }

        // -------------------------------------------------------->> NORMALNO 
        if (!imaoUmanjenje && this.employee.NeRacunajUmanjenje === false) {

          // ako je ukupna placa niza od ukupnog umanjenja 300 
          if (this.round(this.employee.FinBruto2,2) <= 300) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = round(this.employee.FinBruto2,2)
            baseFrom2 = 0
          }

          // treba jos vidjeti da li se oduzima 300 i ako nije na puno radno vrijeme
          if (this.round(this.employee.FinBruto2,2) > 300  && this.round(this.employee.FinBruto2,2) <= 700) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = 300
            let Osnovica1 = this.employee.FinBruto2

            // ako je na bolovanju cijeli mjesec (70% place) i osnovica je manja od najnize osnovice onda se za 2023 uzima 519.53 za obracun doprinosa
            if (this.employee.BolCijeliMjesecNaPoslodavca) {
              Osnovica1 = 519.53
            }

            baseFrom2 = Osnovica1 - 300
            if (baseFrom2 < 0 ) {
              baseFrom2 = 0
            }
          }
          if (this.round(this.employee.FinBruto2,2) >= 700.01 && this.round(this.employee.FinBruto2,2) <= 1300) {
            this.employee.Joppd72 = 1
            this.employee.Joppd129 = round(((1300 - this.employee.FinBruto2) * 0.5),2)
            baseFrom2 = this.employee.FinBruto2 - this.employee.Joppd129
          }
        }

        // -------------------------------------------------------->> Ima vec isplaceni bruto 
        if (imaoUmanjenje && this.employee.NeRacunajUmanjenje === false) {

          const ukupniBruto = this.round(this.employee.FinBrutoOther,2) + this.round(this.employee.FinBruto2,2)
          let ukupnoPravo = 0

          if (ukupniBruto <= 300.00) {
            baseFrom2 = 0
          }

          if (this.round(ukupniBruto,2) > 300  && this.round(ukupniBruto,2) <= 700) {
            baseFrom2 = 300 - this.employee.IskoristenoPravo
            this.employee.Joppd72 = 3
            this.employee.Joppd129 = round((300 - this.employee.IskoristenoPravo),2)
          }

          if (ukupniBruto >= 700.01 && ukupniBruto <= 1300) {
            ukupnoPravo = this.round(((1300 - ukupniBruto) * 0.5),2)
            baseFrom2 = this.employee.FinBruto2 - (ukupnoPravo - this.employee.IskoristenoPravo)
            this.employee.Joppd72 = 3
            this.employee.Joppd129 = round((ukupnoPravo - this.employee.IskoristenoPravo),2)
          }
          if (ukupniBruto > 1300) {
            baseFrom2 = ukupniBruto
          }
        }

        this.employee.FinFrom1_Amount = this.round((baseFrom2 * (this.employee.FinFrom1_Percent / 100)), 2)
        this.employee.FinFrom2_Amount = this.round((baseFrom1 * (this.employee.FinFrom2_Percent / 100)), 2)
        this.employee.FinFrom3_Amount = this.round((baseFrom1 * (this.employee.FinFrom3_Percent / 100)), 2)
        this.employee.FinFrom4_Amount = this.round((baseFrom1 * (this.employee.FinFrom4_Percent / 100)), 2)
        this.employee.FinNeto1ls = this.round((this.employee.FinBruto2 - this.employee.FinFrom1_Amount - this.employee.FinFrom2_Amount - this.employee.FinFrom3_Amount - this.employee.FinFrom4_Amount), 2)
      }
      // tax
      if ((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) > 0 ) {
        // have tax
       
        const taxBase = this.round((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) ,2)

        if (!this.do2024) {

          if (taxBase > 0 && taxBase <= 3981.69) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * 0.2), 2)
            this.employee.FinTax2From = 0
            this.employee.FinTax2Am = 0
          }
          if (taxBase > 3981.69) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = 796.44
            this.employee.FinTax2From = (taxBase - 3981.69)
            this.employee.FinTax2Am = this.round(((taxBase - 3981.69) * 0.3), 2)
          }

          if (this.round(this.employee.FinTaxOnTaxPer ,2) > 0) {
            this.employee.FinTaxOnTaxAm = this.round(((this.employee.FinTax1Am + this.employee.FinTax2Am) * (this.employee.FinTaxOnTaxPer / 100)), 2)
          }
        }
        if (this.do2024) {
          this.employee.FinTaxOnTaxAm = 0
          if (taxBase > 0 && taxBase <= 4200) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * (this.employee.FinTax1Per/100)), 2)
            this.employee.FinTax2From = 0
            this.employee.FinTax2Am = 0
          }
          if (taxBase > 4200) {
            this.employee.FinTax1From = taxBase
            this.employee.FinTax1Am = this.round((taxBase * (this.employee.FinTax1Per/100)), 2)
            this.employee.FinTax2From = (taxBase - 4200)
            this.employee.FinTax2Am = this.round(((taxBase - 4200) * (this.employee.FinTax2Per/100)), 2)
          }

        }
        this.employee.FinTaxTotal = this.round((this.employee.FinTax1Am + this.employee.FinTax2Am + this.employee.FinTaxOnTaxAm + this.employee.FinTax3To), 2)
      }
      if ((this.employee.FinNeto1ls - this.employee.FinFreeTotalToUse) < 0 ) {
        // dont have tax
          this.employee.FinTax1From = 0
          this.employee.FinTax1Am = 0
          this.employee.FinTax2From = 0
          this.employee.FinTax2Am = 0

          this.employee.FinTax1From = 0
          this.employee.FinTax1Am = 0
          this.employee.FinTax2From = 0
          this.employee.FinTax2Am = 0

          this.employee.FinTaxOnTaxAm = this.round(((this.employee.FinTax1Am + this.employee.FinTax2Am) * this.round(this.employee.FinTaxOnTaxPer / 100 ,2)), 2)
        
          this.employee.FinTaxTotal = this.round((this.employee.FinTax1Am + this.employee.FinTax2Am + this.employee.FinTaxOnTaxAm), 2)
      }
      let addTaxReturn = 0

      // ako ima povrat poreza onda se dodaje na neto 
      if (this.$store.state.documentActivHead.Month === 11 && this.round(this.employee.FinTax3To,2) < 0) {
        if (this.employee.FinTaxTotal !== 0) {
          addTaxReturn= 0
        } else {
          addTaxReturn= this.round(Math.abs(this.employee.FinTax3To),2)
        }
      }

      // Neto 2
      this.employee.FinNeto2 = this.round((this.employee.FinNeto1ls - this.employee.FinTaxTotal + addTaxReturn), 2)

      //Neto 3
      this.employee.FinNeto3 = this.round((this.employee.FinNeto2 + this.employee.FinAdd1_Amount  + this.employee.FinAdd2_Amount  + this.employee.FinAdd3_Amount  + this.employee.FinAdd4_Amount - (this.employee.Fin1LoanBankAmount) - (this.employee.Fin2LoanBankAmount) - (this.employee.Fin3LoanBankAmount)), 2)

      this.employee.Fin1bankAmount = this.round((this.employee.Fin2bankAmount), 2) > 0 ? this.employee.FinNeto3 - this.round((this.employee.Fin2bankAmount), 2) : this.employee.FinNeto3
      this.employee.FinTo1_Amount = this.employee.FinTo1_Percent && this.employee.FinTo1_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo1_Percent / 100)), 2) : 0
      this.employee.FinTo2_Amount = this.employee.FinTo2_Percent && this.employee.FinTo2_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo2_Percent / 100)), 2) : 0
      this.employee.FinTo3_Amount = this.employee.FinTo3_Percent && this.employee.FinTo3_Percent > 0 ? this.round((this.employee.FinBruto2 * (this.employee.FinTo3_Percent / 100)), 2) : 0
      const FinToAll = this.round((this.employee.FinTo1_Amount + this.employee.FinTo2_Amount + this.employee.FinTo3_Amount), 2)
      const FinAddAll = this.round((this.employee.FinAdd1_Amount  + this.employee.FinAdd2_Amount  + this.employee.FinAdd3_Amount  + this.employee.FinAdd4_Amount), 2)
      this.employee.FinBruto3 = this.round((this.employee.FinBruto2 + FinAddAll + FinToAll), 2)
    },
    calculateHours() {
      this.employee.RedovanRadIznos = this.round((this.employee.RedovanRad * this.employee.FinWHvalue * (this.employee.RedovanRadPosto / 100)), 2)
      this.employee.NocniRadIznos = this.round((this.employee.NocniRad * this.employee.FinWHvalue * (this.employee.NocniRadPosto / 100)), 2)
      this.employee.PrekovremeniRadIznos = this.round((this.employee.PrekovremeniRad * this.employee.FinWHvalue * (this.employee.PrekovremeniRadPosto / 100)), 2)
      this.employee.NedjeljaRadIznos = this.round((this.employee.NedjeljaRad * this.employee.FinWHvalue * (this.employee.NedjeljaRadPosto / 100)), 2)
      this.employee.BlagdanRadIznos = this.round((this.employee.BlagdanRad * this.employee.FinWHvalue * (this.employee.BlagdanRadPosto / 100)), 2)
      this.employee.TerenRadIznos = this.round((this.employee.TerenRad * this.employee.FinWHvalue * (this.employee.TerenRadPosto / 100)), 2)
      this.employee.PreraspodjelaRadaIznos = this.round((this.employee.PreraspodjelaRada * this.employee.FinWHvalue * (this.employee.PreraspodjelaRadaPosto / 100)), 2)
      this.employee.PripravnostIznos = this.round((this.employee.Pripravnost * this.employee.FinWHvalue * (this.employee.PripravnostPosto / 100)), 2)
      this.employee.TjedniOdmorIznos = this.round((this.employee.TjedniOdmor * this.employee.FinWHvalue * (this.employee.TjedniOdmorPosto / 100)), 2)
      this.employee.NeradniBlagdanIznos = this.round((this.employee.NeradniBlagdan * this.employee.FinWHvalue * (this.employee.NeradniBlagdanPosto / 100)), 2)
      this.employee.GodisnjiOdmorIznos = this.round((this.employee.GodisnjiOdmor * this.employee.FinWHvalue * (this.employee.GodisnjiOdmorPosto / 100)), 2)
      this.employee.BolovanjeDo42DanaIznos = this.round((this.employee.BolovanjeDo42Dana * this.employee.FinWHvalue * (this.employee.BolovanjeDo42DanaPosto / 100)), 2)
      this.employee.BolovanjeOd42DanaIznos = this.round((this.employee.BolovanjeOd42Dana * this.employee.FinWHvalue * (this.employee.BolovanjeOd42DanaPosto / 100)), 2)
      this.employee.RodiljniIznos = this.round((this.employee.Rodiljni * this.employee.FinWHvalue * (this.employee.RodiljniPosto / 100)), 2)
      this.employee.PlaceniDopustIznos = this.round((this.employee.PlaceniDopust * this.employee.FinWHvalue * (this.employee.PlaceniDopustPosto / 100)), 2)
      this.employee.NeplaceniDopustIznos = this.round((this.employee.NeplaceniDopust * this.employee.FinWHvalue * (this.employee.NeplaceniDopustPosto / 100)), 2)
      this.employee.NenazocnostNaZahtjevRadnikaIznos = this.round((this.employee.NenazocnostNaZahtjevRadnika * this.employee.FinWHvalue * (this.employee.NenazocnostNaZahtjevRadnikaPosto / 100)), 2)
      this.employee.ZastojKrivnjomPoslodavcaIznos = this.round((this.employee.ZastojKrivnjomPoslodavca * this.employee.FinWHvalue * (this.employee.ZastojKrivnjomPoslodavcaPosto / 100)), 2)
      this.employee.StrajkIznos = this.round((this.employee.Strajk * this.employee.FinWHvalue * (this.employee.StrajkPosto / 100)), 2)
      this.employee.LockoutIznos = this.round((this.employee.Lockout * this.employee.FinWHvalue * (this.employee.LockoutPosto / 100)), 2)
      this.employee.VrijemeMirovanjaRadnogOdnosaIznos = this.round((this.employee.VrijemeMirovanjaRadnogOdnosa * this.employee.FinWHvalue * (this.employee.VrijemeMirovanjaRadnogOdnosaPosto / 100)), 2)
      this.employee.Dodatno1Iznos = this.round((this.employee.Dodatno1 * this.employee.FinWHvalue * (this.employee.Dodatno1Posto / 100)), 2)
      this.employee.Dodatno2Iznos = this.round((this.employee.Dodatno2 * this.employee.FinWHvalue * (this.employee.Dodatno2Posto / 100)), 2)

      this.employee.Ukupno = this.employee.RedovanRad + this.employee.NocniRad + this.employee.PrekovremeniRad + this.employee.NedjeljaRad + this.employee.BlagdanRad
      this.employee.Ukupno += this.employee.TerenRad + this.employee.PreraspodjelaRada + this.employee.Pripravnost + this.employee.TjedniOdmor
      this.employee.Ukupno += this.employee.NeradniBlagdan + this.employee.GodisnjiOdmor + this.employee.BolovanjeDo42Dana + this.employee.BolovanjeOd42Dana
      this.employee.Ukupno += this.employee.Rodiljni + this.employee.PlaceniDopust + this.employee.NeplaceniDopust + this.employee.NenazocnostNaZahtjevRadnika
      this.employee.Ukupno += this.employee.ZastojKrivnjomPoslodavca + this.employee.Strajk + this.employee.Lockout + this.employee.VrijemeMirovanjaRadnogOdnosa
      this.employee.Ukupno += this.employee.Dodatno1 + this.employee.Dodatno2

      this.employee.UkupnoIznos = this.employee.RedovanRadIznos + this.employee.NocniRadIznos + this.employee.PrekovremeniRadIznos + this.employee.NedjeljaRadIznos + this.employee.BlagdanRadIznos
      this.employee.UkupnoIznos += this.employee.TerenRadIznos + this.employee.PreraspodjelaRadaIznos + this.employee.PripravnostIznos + this.employee.TjedniOdmorIznos
      this.employee.UkupnoIznos += this.employee.NeradniBlagdanIznos + this.employee.GodisnjiOdmorIznos + this.employee.BolovanjeDo42DanaIznos + this.employee.BolovanjeOd42DanaIznos
      this.employee.UkupnoIznos += this.employee.RodiljniIznos + this.employee.PlaceniDopustIznos + this.employee.NeplaceniDopustIznos + this.employee.NenazocnostNaZahtjevRadnikaIznos
      this.employee.UkupnoIznos += this.employee.ZastojKrivnjomPoslodavcaIznos + this.employee.StrajkIznos + this.employee.LockoutIznos + this.employee.VrijemeMirovanjaRadnogOdnosaIznos
      this.employee.UkupnoIznos += this.employee.Dodatno1Iznos + this.employee.Dodatno2Iznos
      this.employee.UkupnoIznos = this.round(this.employee.UkupnoIznos, 2)
      if (this.employee.CalculateByHours === true) {
        this.employee.FinBruto1 = this.employee.UkupnoIznos
      }
    },
    async createYearCard() {
        const reportArr = []
        const allYear = []

        const query = {}
        query.CompanyId = this.$store.state.companyid
        const bYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
        query.fromDate = dayjs([bYear]).startOf('year').format('YYYY-MM-DD')
        query.toDate = dayjs([bYear]).endOf('year').format('YYYY-MM-DD')
        const allSalaries = (await SalarieService.choose(query)).data.salaries
        if (allSalaries) {
          let results = allSalaries.map(async(salarie) => {
            const qry ={}
            qry.CompanyId = this.$store.state.companyid
            qry.databaseDocID = salarie && salarie.id ? salarie.id : 999999999
            qry.empID = parseInt(this.employee.empID)
            const res2 = await SalarieDetailService.choose(qry)
            const detData = res2 && res2.data && res2.data.salariedetails ? res2.data.salariedetails : null
            if (detData) {
              allYear.push(...detData)
            }
          })
          results = await Promise.all(results)

        const totals = {}
        totals.FinTax = 0
        totals.Porez = 0
        totals.razlikaPoreza = 0
        if (allYear) {
            let FinBruto2 = 0
            let FinFrom1_Amount = 0
            let FinFrom2_Amount = 0
            let FinFrom3_Amount = 0
            let FinFrom4_Amount = 0
            let FinNeto1ls = 0
            let FinFreeTotalToUse = 0
            let FinTax1Am = 0
            let FinTax2Am = 0
            let Porez20 = 0
            let Porez30 = 0
            let FinTaxOnTaxAm = 0
            let FinNeto2 = 0
  
            if (allYear && allYear.length > 0) {

                allYear.map(detEmp => {
                  // go calculate
                  FinBruto2 += this.round((detEmp.FinBruto2), 2)
                  FinFrom1_Amount += this.round((detEmp.FinFrom1_Amount),2)
                  FinFrom2_Amount += this.round((detEmp.FinFrom2_Amount),2)
                  FinFrom3_Amount += this.round((detEmp.FinFrom3_Amount),2)
                  FinFrom4_Amount += this.round((detEmp.FinFrom4_Amount),2)
                  const DoprinosiIZTotal = this.round((detEmp.FinFrom1_Amount),2) + this.round((detEmp.FinFrom2_Amount),2) + this.round((detEmp.FinFrom3_Amount),2) + this.round((detEmp.FinFrom4_Amount),2)
                  FinNeto1ls += this.round((detEmp.FinNeto1ls),2)
                  FinFreeTotalToUse += this.round((detEmp.FinFreeTotalToUse),2)
                  FinTax1Am += this.round((detEmp.FinTax1Am),2)
                  FinTax2Am += this.round((detEmp.FinTax2Am),2)
                  FinTaxOnTaxAm += this.round((detEmp.FinTaxOnTaxAm), 2)
                  FinNeto2 = this.round((detEmp.FinNeto2), 2)
                  const reportItem = {}
                  reportItem.empID = detEmp.empID
                  reportItem.eemail = detEmp.eemail
                  reportItem.First = detEmp.First
                  reportItem.Last = detEmp.Last
                  reportItem.VatID = detEmp.VatID
                  reportItem.Mjesec = parseInt(detEmp.Month)
                  reportItem.Godina = parseInt(detEmp.Year)
                  reportItem.Month = detEmp.Month.toString() + '/' + detEmp.Year.toString()
                  reportItem.FinBruto2 = this.round((detEmp.FinBruto2), 2)
                  reportItem.DoprinosiIZTotal = DoprinosiIZTotal
                  reportItem.FinNeto1ls = this.round((detEmp.FinNeto1ls),2)
                  reportItem.FinFreeTotalToUse = this.round((detEmp.FinFreeTotalToUse),2)
                  reportItem.FinTax1Am = this.round((detEmp.FinTax1Am),2)
                  reportItem.FinTax2Am = this.round((detEmp.FinTax2Am),2)
                  reportItem.Prirez = this.round(FinTaxOnTaxAm,2)
                  reportItem.UkPorezPrirez = this.round((detEmp.FinTax1Am),2) + this.round((detEmp.FinTax2Am),2)  + this.round(FinTaxOnTaxAm,2)
                  reportItem.Neto = this.round(FinNeto2,2)
                  reportArr.push(reportItem)
                  totals.eemail = detEmp.eemail
                  totals.First = detEmp.First
                  totals.Last = detEmp.Last
                  totals.VatID = detEmp.VatID
                })
                
                totals.FinBruto2 = this.round((FinBruto2), 2)
                totals.DoprinosiIZTotal = this.round((FinFrom1_Amount),2) + this.round((FinFrom2_Amount),2) + this.round((FinFrom3_Amount),2) + this.round((FinFrom4_Amount),2)
                totals.FinNeto1ls = this.round((FinNeto1ls),2)
                totals.FinFreeTotalToUse = this.round((FinFreeTotalToUse),2)
                totals.FinTax1Am = this.round((FinTax1Am),2)
                totals.FinTax2Am = this.round((FinTax2Am),2)
                totals.Prirez = this.round(FinTaxOnTaxAm,2)
                totals.UkPorezPrirez = this.round((FinTax1Am),2) + this.round((FinTax2Am),2)  + this.round(FinTaxOnTaxAm,2)
                totals.Neto = this.round(FinNeto2,2)
                let OsnovicaZaPorez = FinNeto1ls - this.round(FinFreeTotalToUse,2)
                if (this.round(OsnovicaZaPorez,2) < 0) {
                  OsnovicaZaPorez = 0
                }

                if (OsnovicaZaPorez <= 47780.28) {
                  Porez20 = this.round((OsnovicaZaPorez * 0.2),2)
                }
                if (OsnovicaZaPorez > 47780.28) {
                  Porez20 = this.round((47780.28 * 0.2),2)
                  Porez30 = this.round(((OsnovicaZaPorez - 47780.28) * 0.3),2)
                }

                totals.stariPorez = this.round(((FinTax1Am + FinTax2Am) + FinTaxOnTaxAm),2)
                totals.noviPorez = this.round(((Porez20 + Porez30) + this.round(((Porez20 + Porez30) * (this.employee.FinTaxOnTaxPer / 100)),2)),2)
                totals.razlikaPoreza =  totals.stariPorez - totals.noviPorez
                totals.FinTax += this.round((FinTax1Am + FinTax2Am)+ FinTaxOnTaxAm, 2)
                totals.Porez += this.round((totals.noviPorez),2)
            }
        }
        reportArr.sort(dynamicSort("Mjesec"))
        const card = {...this.$store.state.documentActivHead}
        card.head1 = reportArr
        card.head2 = 'KARTICA GODIŠNJEG OBRAČUNA POREZA NA DOHODAK ZA GODINU ' + this.$store.state.documentActivHead.Year.toString()
        card.head3 = totals ? totals : {}
        card.head4 = totals.First + ' ' + totals.Last + ', ' +  totals.eemail + ', OIB: ' + totals.VatID
        card.head5 = null
        await this.$store.dispatch('setPrintObj', card)
        await this.$refs.prnt5.print()

      }
      
    }
  },
  computed: {
    kpr() {
      return (this.round(this.employee.CityTax,2) / 100 ) + 1 
    },
    kppr20() {
      return ((20 * this.kpr) / (100-(20 * this.kpr))) + 1 
    },
    kppr30() {
      return ((30 * this.kpr) / (100-(30 * this.kpr))) + 1
    },

  },
  watch: {
    'employee.FinFreeDisabled' () {
      if (!this.employee.FinFreeDisabled) {
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = this.osobniOdbitak
        this.employee.ChildPer = 100
      }
      if (this.employee.FinFreeDisabled) {
        this.employee.FinFree1Indeks = 1.6
        this.employee.FinFree1OnAm = 0
        this.employee.FinFree2OnAm = 0
        this.employee.FinFree3OnAm = 0
        this.employee.FinFree4OnAm = 0
        this.employee.ChildPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child1' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child2' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child3' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.Child4' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.ChildPer' () {
      if (this.employee.ChildPer === null) {
        this.employee.ChildPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinFree1OnAm' () {
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectBank1' () {
      if (this.selectBank1) {
        this.employee.Fin1bankAccountId = this.selectBank1.vatid
        this.employee.Fin1bankAccountName = this.selectBank1.text
        }
     // if (Object.keys(this.selectBank1).length === 0) {this.employee.Fin1bankAccountId = null}
    },
    'selectBank2' () {
      if (this.selectBank2) {
        this.employee.Fin2bankAccountId = this.selectBank2.vatid
        this.employee.Fin2bankAccountName = this.selectBank2.text
      }
//if (Object.keys(this.selectBank2).length === 0) {this.employee.Fin2bankAccountId = null}
    },
    'selectBankLoan1' () {
      if (this.selectBankLoan1) {this.employee.Fin1LoanBankAccountId = this.selectBankLoan1.vatid}
//if (Object.keys(this.selectBankLoan1).length === 0) {this.employee.Fin1LoanBankAccountId = null}
    },
    'selectBankLoan2' () {
      if (this.selectBankLoan2) {this.employee.Fin2LoanBankAccountId = this.selectBankLoan2.vatid}
//if (Object.keys(this.selectBankLoan2).length === 0) {this.employee.Fin2LoanBankAccountId = null}
    },
    'selectBankLoan3' () {
      if (this.selectBankLoan3) {this.employee.Fin3LoanBankAccountId = this.selectBankLoan3.vatid}
  //    if (Object.keys(this.selectBankLoan3).length === 0) {this.employee.Fin3LoanBankAccountId = null}
    },

    'selectSalariesFrom1' () {
      if (this.selectSalariesFrom1 && this.selectSalariesFrom1.value) {
        this.employee.FinFrom1_id = this.selectSalariesFrom1 && this.selectSalariesFrom1.value ? this.selectSalariesFrom1.value : null
        this.employee.FinFrom1_Desc = this.selectSalariesFrom1 && this.selectSalariesFrom1.descHR ? this.selectSalariesFrom1.descHR : null
        this.employee.FinFrom1_Percent = this.selectSalariesFrom1 && this.selectSalariesFrom1.per ? this.selectSalariesFrom1.per : 0
        this.employee.FinFrom1_Amount = 0
      }
      if (!this.selectSalariesFrom1) {
        this.employee.FinFrom1_id = null
        this.employee.FinFrom1_Desc = null
        this.employee.FinFrom1_Percent = 0
        this.employee.FinFrom1_Amount = 0
      }
      this.calculateSal()    
    },
    'selectSalariesFrom2' () {
      if (this.selectSalariesFrom2 && this.selectSalariesFrom2.value) {
        this.employee.FinFrom2_id = this.selectSalariesFrom2 && this.selectSalariesFrom2.value ? this.selectSalariesFrom2.value : null
        this.employee.FinFrom2_Desc = this.selectSalariesFrom2 && this.selectSalariesFrom2.descHR ? this.selectSalariesFrom2.descHR : null
        this.employee.FinFrom2_Percent = this.selectSalariesFrom2 && this.selectSalariesFrom2.per ? this.selectSalariesFrom2.per : 0
        this.employee.FinFrom2_Amount = 0
      }
      if (!this.selectSalariesFrom2) {
        this.employee.FinFrom2_id = null
        this.employee.FinFrom2_Desc = null
        this.employee.FinFrom2_Percent = 0
        this.employee.FinFrom2_Amount = 0
      }
      this.calculateSal()   
    },
    'selectSalariesFrom3' () {
      if (this.selectSalariesFrom3 && this.selectSalariesFrom3.value) {
        this.employee.FinFrom3_id = this.selectSalariesFrom3 && this.selectSalariesFrom3.value ? this.selectSalariesFrom3.value : null
        this.employee.FinFrom3_Desc = this.selectSalariesFrom3 && this.selectSalariesFrom3.descHR ? this.selectSalariesFrom3.descHR : null
        this.employee.FinFrom3_Percent = this.selectSalariesFrom3 && this.selectSalariesFrom3.per ? this.selectSalariesFrom3.per : 0
        this.employee.FinFrom3_Amount = 0
      }
      if (!this.selectSalariesFrom3) {
        this.employee.FinFrom3_id = null
        this.employee.FinFrom3_Desc = null
        this.employee.FinFrom3_Percent = 0
        this.employee.FinFrom3_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesFrom4' () {
      if (this.selectSalariesFrom4 && this.selectSalariesFrom4.value) {
        this.employee.FinFrom4_id = this.selectSalariesFrom4 && this.selectSalariesFrom4.value ? this.selectSalariesFrom4.value : null
        this.employee.FinFrom4_Desc = this.selectSalariesFrom4 && this.selectSalariesFrom4.descHR ? this.selectSalariesFrom4.descHR : null
        this.employee.FinFrom4_Percent = this.selectSalariesFrom4 && this.selectSalariesFrom4.per ? this.selectSalariesFrom4.per : 0
        this.employee.FinFrom4_Amount = 0
      }
      if (!this.selectSalariesFrom4) {
        this.employee.FinFrom4_id = null
        this.employee.FinFrom4_Desc = null
        this.employee.FinFrom4_Percent = 0
        this.employee.FinFrom4_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo1' () {
      if (this.selectSalariesTo1 && this.selectSalariesTo1.value) {
        this.employee.FinTo1_id = this.selectSalariesTo1 && this.selectSalariesTo1.value ? this.selectSalariesTo1.value : null
        this.employee.FinTo1_Desc = this.selectSalariesTo1 && this.selectSalariesTo1.descHR ? this.selectSalariesTo1.descHR : null
        this.employee.FinTo1_Percent = this.selectSalariesTo1 && this.selectSalariesTo1.per ? this.selectSalariesTo1.per : 0
        this.employee.FinTo1_Amount = 0
      }
      if (!this.selectSalariesTo1) {
        this.employee.FinTo1_id = null
        this.employee.FinTo1_Desc = null
        this.employee.FinTo1_Percent = 0
        this.employee.FinTo1_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo2' () {
      if (this.selectSalariesTo2 && this.selectSalariesTo2.value) {
        this.employee.FinTo2_id = this.selectSalariesTo2 && this.selectSalariesTo2.value ? this.selectSalariesTo2.value : null
        this.employee.FinTo2_Desc = this.selectSalariesTo2 && this.selectSalariesTo2.descHR ? this.selectSalariesTo2.descHR : null
        this.employee.FinTo2_Percent = this.selectSalariesTo2 && this.selectSalariesTo2.per ? this.selectSalariesTo2.per : 0
        this.employee.FinTo2_Amount = 0
      }
      if (!this.selectSalariesTo2) {
        this.employee.FinTo2_id = null
        this.employee.FinTo2_Desc = null
        this.employee.FinTo2_Percent = 0
        this.employee.FinTo2_Amount = 0
      }
      this.calculateSal()
    },
    'selectSalariesTo3' () {
      if (this.selectSalariesTo3 && this.selectSalariesTo3.value) {
        this.employee.FinTo3_id = this.selectSalariesTo3 && this.selectSalariesTo3.value ? this.selectSalariesTo3.value : null
        this.employee.FinTo3_Desc = this.selectSalariesTo3 && this.selectSalariesTo3.descHR ? this.selectSalariesTo3.descHR : null
        this.employee.FinTo3_Percent = this.selectSalariesTo3 && this.selectSalariesTo3.per ? this.selectSalariesTo3.per : 0
        this.employee.FinTo3_Amount = 0
      }
      if (!this.selectSalariesTo3) {
        this.employee.FinTo3_id = null
        this.employee.FinTo3_Desc = null
        this.employee.FinTo3_Percent = 0
        this.employee.FinTo3_Amount = 0
      }
      this.calculateSal() 
    },
    'selectChildren' () {
      if (this.selectChildren) {
        this.employee.FinFree2Indeks = this.selectChildren.calc ? this.selectChildren.calc : 0
        this.employee.FinFree2OnAm = this.selectChildren.value ? this.selectChildren.value : 0
        this.employee.FinFree2Am = this.selectChildren.amount ? this.selectChildren.amount : 0
      }
      if (!this.selectChildren) {
        this.employee.FinFree2Indeks = 0
        this.employee.FinFree2OnAm = null
        this.employee.FinFree2Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectFamilySupport' () {
      if (this.selectFamilySupport) {
        this.employee.FinFree3Indeks = this.selectFamilySupport && this.selectFamilySupport.calc ? this.selectFamilySupport.calc : 0
        this.employee.FinFree3OnAm = this.selectFamilySupport && this.selectFamilySupport.value ? this.selectFamilySupport.value : 0
        this.employee.FinFree3Am = this.selectFamilySupport && this.selectFamilySupport.amount ? this.selectFamilySupport.amount : 0
      }
      if (!this.selectFamilySupport) {
        this.employee.FinFree3Indeks = 0
        this.employee.FinFree3OnAm = null
        this.employee.FinFree3Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'selectInvalidType' () {
      if (this.selectInvalidType) {
        this.employee.FinFree4In = this.selectInvalidType && this.selectInvalidType.calc ? this.selectInvalidType.calc : 0
        this.employee.FinFree4OnAm = this.selectInvalidType && this.selectInvalidType.value ? this.selectInvalidType.value : 0
        this.employee.FinFree4Am = this.selectInvalidType && this.selectInvalidType.amount ? this.selectInvalidType.amount : 0
      }
      if (!this.selectInvalidType) {
        this.employee.FinFree4In = 0
        this.employee.FinFree4OnAm = null
        this.employee.FinFree4Am = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinFreeTotalPer' () {
      if (this.employee.FinFreeTotalPer) {
        //this.employee.FinFreeTotalPer = parseFloat(this.employee.FinFreeTotalPer)
        this.employee.FinFreeTotalPer = this.employee.FinFreeTotalPer > 100 ? 100 : this.employee.FinFreeTotalPer
      }
      if (!this.employee.FinFreeTotalPer) {
        this.employee.FinFreeTotalPer = 0
      }
      this.calcPersonalFree()
      this.calculateSal()
    },
    'employee.FinKoef1' () {
      if (this.employee.FinKoef1 === null) {
        this.employee.FinKoef1 = 0
      }
      //this.employee.FinKoef1 = parseFloat(this.employee.FinKoef1)
      this.calculateSal()
    },
    'employee.FinKoef2' () {
      if (this.employee.FinKoef2 === null) {
        this.employee.FinKoef2 = 0
      }
     // this.employee.FinKoef2 = parseFloat(this.employee.FinKoef2)
      this.calculateSal()
    },
    'employee.FinBruto0' () {
      if (this.employee.FinBruto0 === null) {
        this.employee.FinBruto0 = 0
      }
      //this.employee.FinBruto0 = parseFloat(this.employee.FinBruto0)
      if (this.employee.FinWH && this.round(this.employee.FinWH,2) > 0) {
        this.employee.FinWHvalue = this.round((this.employee.FinBruto0 / this.round(this.employee.FinWH,2)), 5)
      }
      this.calculateHours()
      this.calculateSal()
    },
    'employee.FinBrutoOther' () {
      this.calculateSal()
    },
    'employee.IskoristenoPravo' () {
      this.calculateSal()
    },
    'employee.Joppd129' () {
      this.calculateSal()
    },
    'employee.FinBruto1' () {
      if (round(this.employee.FinBruto1, 2) !== 0) {
        this.FinBruto1Kn = this.round((this.employee.FinBruto1 * 7.5345) ,2)
      }
      this.calculateSal()
    },
    'employee.FinAdd1_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd2_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd3_Amount' () {
      this.calculateSal()
    },
    'employee.FinAdd4_Amount' () {
      this.calculateSal()
    },
    'employee.Fin1LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.Fin2LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.Fin3LoanBankAmount' () {
      this.calculateSal()
    },
    'employee.NeRacunajUmanjenje' () {
      this.calculateSal()
    },
    'oneNotaxaddition' () {
      if (this.oneNotaxaddition) {
        this.employee.FinAdd1_TypeId = this.oneNotaxaddition.value
        this.employee.FinAdd1_TypeProtected = this.oneNotaxaddition.protected
      }
      if (!this.oneNotaxaddition) {
        this.employee.FinAdd1_TypeId = 0
        this.employee.FinAdd1_TypeProtected = false
      }
    },
    'twoNotaxaddition' () {
      if (this.twoNotaxaddition) {
        this.employee.FinAdd2_TypeId = this.twoNotaxaddition.value
        this.employee.FinAdd2_TypeProtected = this.twoNotaxaddition.protected
      }
      if (!this.twoNotaxaddition) {
        this.employee.FinAdd2_TypeId = 0
        this.employee.FinAdd2_TypeProtected = false
      }
    },
    'threeNotaxaddition' () {
      if (this.threeNotaxaddition) {
        this.employee.FinAdd3_TypeId = this.threeNotaxaddition.value
        this.employee.FinAdd3_TypeProtected = this.threeNotaxaddition.protected
      }
      if (!this.threeNotaxaddition) {
        this.employee.FinAdd3_TypeId = 0
        this.employee.FinAdd3_TypeProtected = false
      }
    },
    'fourNotaxaddition' () {
      if (this.fourNotaxaddition) {
        this.employee.FinAdd4_TypeId = this.fourNotaxaddition.value
        this.employee.FinAdd4_TypeProtected = this.fourNotaxaddition.protected
      }
      if (!this.fourNotaxaddition) {
        this.employee.FinAdd4_TypeId = 0
        this.employee.FinAdd4_TypeProtected = false
      }
    },

    'oneJoppd' () {
      this.employee.FinAdd1_JoppId = this.oneJoppd ? this.oneJoppd.value : 0
    },
    'twoJoppd' () {
      this.employee.FinAdd2_JoppId = this.twoJoppd ? this.twoJoppd.value : 0
    },
    'threeJoppd' () {
      this.employee.FinAdd3_JoppId = this.threeJoppd ? this.threeJoppd.value : 0
    },
    'fourJoppd' () {
      this.employee.FinAdd4_JoppId = this.fourJoppd ? this.fourJoppd.value : 0
    },
    'selectCitytax' () {
      
      if (this.selectCitytax) {
        this.employee.FinTaxOnTaxPer = parseFloat(this.selectCitytax.value)
      }
      if (!this.selectCitytax) {
        this.employee.CityTax = 0
        this.employee.FinTaxOnTaxPer = 0
        this.employee.FinTaxOnTaxAm = 0
      }
      this.calculateSal()
    },
    // 'employee.FinTaxOnTaxPer' () {
    //   if (this.employee.FinTaxOnTaxPer === null) {
    //     this.employee.FinTaxOnTaxPer = 0
    //   }
    //   this.calculateSal()
    // },
    'selectCity' () {
      if (this.selectCity) {
        this.employee.FinTaxOnTaxCityCode = this.selectCity.CityCode
        this.employee.FinTaxOnTaxCityName = this.selectCity.CityName
      }
      if (!this.selectCity) {
        this.employee.FinTaxOnTaxCityCode = null
        this.employee.FinTaxOnTaxCityName = null
      }
    },
    'employee.FinTax1Per' () {
      if (this.employee.FinTax1Per === null) {
        this.employee.FinTax1Per = 0
      }
 
      this.calculateSal()
    },
    'employee.FinTax2Per' () {
      if (this.employee.FinTax2Per === null) {
        this.employee.FinTax2Per = 0
      }
      this.calculateSal()
    },
    'employee.RedovanRad' () {
      if (this.employee.RedovanRad === null) {
        this.employee.RedovanRad = 0
      }
      if (this.employee.RedovanRad > 500) {
        this.employee.RedovanRad = 0
      }
      this.calculateHours()
    },
    'employee.NocniRad' () {
      if (this.employee.NocniRad === null) {
        this.employee.NocniRad = 0
      }
      if (this.employee.NocniRad > 500) {
        this.employee.NocniRad = 0
      }
      this.calculateHours()
    },
    'employee.PrekovremeniRad' () {
      if (this.employee.PrekovremeniRad === null) {
        this.employee.PrekovremeniRad = 0
      }
      if (this.employee.PrekovremeniRad > 500) {
        this.employee.PrekovremeniRad = 0
      }
      this.calculateHours()
    },
    'employee.NedjeljaRad' () {
      if (this.employee.NedjeljaRad === null) {
        this.employee.NedjeljaRad = 0
      }
      if (this.employee.NedjeljaRad > 500) {
        this.employee.NedjeljaRad = 0
      }
      this.calculateHours()
    },
    'employee.BlagdanRad' () {
      if (this.employee.BlagdanRad  === null) {
        this.employee.BlagdanRad = 0
      }
      if (this.employee.BlagdanRad > 500) {
        this.employee.BlagdanRad = 0
      }
      this.calculateHours()
    },
    'employee.TerenRad' () {
      if (this.employee.TerenRad === null) {
        this.employee.TerenRad = 0
      }
      if (this.employee.TerenRad > 500) {
        this.employee.TerenRad = 0
      }
      this.calculateHours()
    },
    'employee.PreraspodjelaRada' () {
      if (this.employee.PreraspodjelaRada === null) {
        this.employee.PreraspodjelaRada = 0
      }
      if (this.employee.PreraspodjelaRada > 500) {
        this.employee.PreraspodjelaRada = 0
      }
      this.calculateHours()
    },
    'employee.Pripravnost' () {
      if (!this.employee.Pripravnost) {
        this.employee.Pripravnost = 0
      }
      if (this.employee.Pripravnost > 500) {
        this.employee.Pripravnost = 0
      }
      this.calculateHours()
    },
    'employee.DnevniOdmor' () {
      if (this.employee.DnevniOdmor === null) {
        this.employee.DnevniOdmor = 0
      }
      if (this.employee.DnevniOdmor > 500) {
        this.employee.DnevniOdmor = 0
      }
      this.calculateHours()
    },
    'employee.TjedniOdmor' () {
      if (this.employee.TjedniOdmor === null) {
        this.employee.TjedniOdmor = 0
      }
      if (this.employee.TjedniOdmor > 500) {
        this.employee.TjedniOdmor = 0
      }
      this.calculateHours()
    },
    'employee.NeradniBlagdan' () {
      if (this.employee.NeradniBlagdan === null) {
        this.employee.NeradniBlagdan = 0
      }
      if (this.employee.NeradniBlagdan > 500) {
        this.employee.NeradniBlagdan = 0
      }
      this.calculateHours()
    },
    'employee.GodisnjiOdmor' () {
      if (this.employee.GodisnjiOdmor === null) {
        this.employee.GodisnjiOdmor = 0
      }
      if (this.employee.GodisnjiOdmor > 500) {
        this.employee.GodisnjiOdmor = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeDo42Dana' () {
      if (this.employee.BolovanjeDo42Dana === null) {
        this.employee.BolovanjeDo42Dana = 0
      }
      if (this.employee.BolovanjeDo42Dana > 500) {
        this.employee.BolovanjeDo42Dana = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeOd42Dana' () {
      if (this.employee.BolovanjeOd42Dana === null) {
        this.employee.BolovanjeOd42Dana = 0
      }
      if (this.employee.BolovanjeOd42Dana > 500) {
        this.employee.BolovanjeOd42Dana = 0
      }
      this.calculateHours()
    },
    'employee.Rodiljni' () {
      if (this.employee.Rodiljni === null) {
        this.employee.Rodiljni = 0
      }
      if (this.employee.Rodiljni > 500) {
        this.employee.Rodiljni = 0
      }
      this.calculateHours()
    },
    'employee.PlaceniDopust' () {
      if (!this.employee.PlaceniDopust) {
        this.employee.PlaceniDopust = 0
      }
      if (this.employee.PlaceniDopust > 500) {
        this.employee.PlaceniDopust = 0
      }
      this.calculateHours()
    },
    'employee.NeplaceniDopust' () {
      if (!this.employee.NeplaceniDopust) {
        this.employee.NeplaceniDopust = 0
      }
      if (this.employee.NeplaceniDopust > 500) {
        this.employee.NeplaceniDopust = 0
      }
      this.calculateHours()
    },
    'employee.NenazocnostNaZahtjevRadnika' () {
      if (!this.employee.NenazocnostNaZahtjevRadnika) {
        this.employee.NenazocnostNaZahtjevRadnika = 0
      }
      if (this.employee.NenazocnostNaZahtjevRadnika > 500) {
        this.employee.NenazocnostNaZahtjevRadnika = 0
      }
      this.calculateHours()
    },
    'employee.ZastojKrivnjomPoslodavca' () {
      if (!this.employee.ZastojKrivnjomPoslodavca) {
        this.employee.ZastojKrivnjomPoslodavca = 0
      }
      if (this.employee.ZastojKrivnjomPoslodavca > 500) {
        this.employee.ZastojKrivnjomPoslodavca = 0
      }
      this.calculateHours()
    },
    'employee.Strajk' () {
      if (!this.employee.Strajk) {
        this.employee.Strajk = 0
      }
      if (this.employee.Strajk > 500) {
        this.employee.Strajk = 0
      }
      this.calculateHours()
    },
    'employee.Lockout' () {
      if (!this.employee.Lockout) {
        this.employee.Lockout = 0
      }
      if (this.employee.Lockout > 500) {
        this.employee.Lockout = 0
      }
      this.calculateHours()
    },
    'employee.VrijemeMirovanjaRadnogOdnosa' () {
      if (!this.employee.VrijemeMirovanjaRadnogOdnosa) {
        this.employee.VrijemeMirovanjaRadnogOdnosa = 0
      }
      if (this.employee.VrijemeMirovanjaRadnogOdnosa > 500) {
        this.employee.VrijemeMirovanjaRadnogOdnosa = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno1' () {
      if (!this.employee.Dodatno1) {
        this.employee.Dodatno1 = 0
      }
      if (this.employee.Dodatno1 > 500) {
        this.employee.Dodatno1 = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno2' () {
      if (!this.employee.Dodatno2) {
        this.employee.Dodatno2 = 0
      }
      if (this.employee.Dodatno2 > 500) {
        this.employee.Dodatno2 = 0
      }
      this.calculateHours()
    },
  // --------------------------------------------------------------------
    'employee.RedovanRadPosto' () {
      if (!this.employee.RedovanRadPosto) {
        this.employee.RedovanRadPosto = 0
      }
      if (this.employee.RedovanRadPosto > 500) {
        this.employee.RedovanRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.NocniRadPosto' () {
      if (!this.employee.NocniRadPosto) {
        this.employee.NocniRadPosto = 0
      }
      if (this.employee.NocniRadPosto > 500) {
        this.employee.NocniRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.PrekovremeniRadPosto' () {
      if (!this.employee.PrekovremeniRadPosto) {
        this.employee.PrekovremeniRadPosto = 0
      }
      if (this.employee.PrekovremeniRadPosto > 500) {
        this.employee.PrekovremeniRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.NedjeljaRadPosto' () {
      if (!this.employee.NedjeljaRadPosto) {
        this.employee.NedjeljaRadPosto = 0
      }
      if (this.employee.NedjeljaRadPosto > 500) {
        this.employee.NedjeljaRadPosto= 0
      }
      this.calculateHours()
    },
    'employee.BlagdanRadPosto' () {
      if (!this.employee.BlagdanRadPosto) {
        this.employee.BlagdanRadPosto = 0
      }
      if (this.employee.BlagdanRadPosto > 500) {
        this.employee.BlagdanRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.TerenRadPosto' () {
      if (!this.employee.TerenRadPosto) {
        this.employee.TerenRadPosto = 0
      }
      if (this.employee.TerenRadPosto > 500) {
        this.employee.TerenRadPosto = 0
      }
      this.calculateHours()
    },
    'employee.PreraspodjelaRadaPosto' () {
      if (!this.employee.PreraspodjelaRadaPosto) {
        this.employee.PreraspodjelaRadaPosto = 0
      }
      if (this.employee.PreraspodjelaRadaPosto > 500) {
        this.employee.PreraspodjelaRadaPosto = 0
      }
      this.calculateHours()
    },
    'employee.PripravnostPosto' () {
      if (!this.employee.PripravnostPosto) {
        this.employee.PripravnostPosto = 0
      }
      if (this.employee.PripravnostPosto > 500) {
        this.employee.PripravnostPosto = 0
      }
      this.calculateHours()
    },
    'employee.DnevniOdmorPosto' () {
      if (!this.employee.DnevniOdmorPosto) {
        this.employee.DnevniOdmorPosto = 0
      }
      if (this.employee.DnevniOdmorPosto > 500) {
        this.employee.DnevniOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.TjedniOdmorPosto' () {
      if (!this.employee.TjedniOdmorPosto) {
        this.employee.TjedniOdmorPosto = 0
      }
      if (this.employee.TjedniOdmorPosto > 500) {
        this.employee.TjedniOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.NeradniBlagdanPosto' () {
      if (!this.employee.NeradniBlagdanPosto) {
        this.employee.NeradniBlagdanPosto = 0
      }
      if (this.employee.NeradniBlagdanPosto > 500) {
        this.employee.NeradniBlagdanPosto = 0
      }
      this.calculateHours()
    },
    'employee.GodisnjiOdmorPosto' () {
      if (!this.employee.GodisnjiOdmorPosto) {
        this.employee.GodisnjiOdmorPosto = 0
      }
      if (this.employee.GodisnjiOdmorPosto > 500) {
        this.employee.GodisnjiOdmorPosto = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeDo42DanaPosto' () {
      if (!this.employee.BolovanjeDo42DanaPosto) {
        this.employee.BolovanjeDo42DanaPosto = 0
      }
      if (this.employee.BolovanjeDo42DanaPosto > 500) {
        this.employee.BolovanjeDo42DanaPosto = 0
      }
      this.calculateHours()
    },
    'employee.BolovanjeOd42DanaPosto' () {
      if (!this.employee.BolovanjeOd42DanaPosto) {
        this.employee.BolovanjeOd42DanaPosto = 0
      }
      if (this.employee.BolovanjeOd42DanaPosto > 500) {
        this.employee.BolovanjeOd42DanaPosto = 0
      }
      this.calculateHours()
    },
    'employee.RodiljniPosto' () {
      if (!this.employee.RodiljniPosto) {
        this.employee.RodiljniPosto = 0
      }
      if (this.employee.RodiljniPosto > 500) {
        this.employee.RodiljniPosto= 0
      }
      this.calculateHours()
    },
    'employee.PlaceniDopustPosto' () {
      if (!this.employee.PlaceniDopustPosto) {
        this.employee.PlaceniDopustPosto = 0
      }
      if (this.employee.PlaceniDopustPosto > 500) {
        this.employee.PlaceniDopustPosto = 0
      }
      this.calculateHours()
    },
    'employee.NeplaceniDopustPosto' () {
      if (!this.employee.NeplaceniDopustPosto) {
        this.employee.NeplaceniDopustPosto = 0
      }
      if (this.employee.NeplaceniDopustPosto > 500) {
        this.employee.NeplaceniDopustPosto = 0
      }
      this.calculateHours()
    },
    'employee.NenazocnostNaZahtjevRadnikaPosto' () {
      if (!this.employee.NenazocnostNaZahtjevRadnikaPosto) {
        this.employee.NenazocnostNaZahtjevRadnikaPosto = 0
      }
      if (this.employee.NenazocnostNaZahtjevRadnikaPosto > 500) {
        this.employee.NenazocnostNaZahtjevRadnikaPosto = 0
      }
      this.calculateHours()
    },
    'employee.ZastojKrivnjomPoslodavcaPosto' () {
      if (!this.employee.ZastojKrivnjomPoslodavcaPosto) {
        this.employee.ZastojKrivnjomPoslodavcaPosto = 0
      }
      if (this.employee.ZastojKrivnjomPoslodavcaPosto > 500) {
        this.employee.ZastojKrivnjomPoslodavcaPosto = 0
      }
      this.calculateHours()
    },
    'employee.StrajkPosto' () {
      if (!this.employee.StrajkPosto) {
        this.employee.StrajkPosto = 0
      }
      if (this.employee.StrajkPosto > 500) {
        this.employee.StrajkPosto = 0
      }
      this.calculateHours()
    },
    'employee.LockoutPosto' () {
      if (!this.employee.LockoutPosto
      ) {
        this.employee.LockoutPosto = 0
      }
      if (this.employee.LockoutPosto > 500) {
        this.employee.LockoutPosto = 0
      }
      this.calculateHours()
    },
    'employee.VrijemeMirovanjaRadnogOdnosaPosto' () {
      if (!this.employee.VrijemeMirovanjaRadnogOdnosaPosto) {
        this.employee.VrijemeMirovanjaRadnogOdnosaPosto = 0
      }
      if (this.employee.VrijemeMirovanjaRadnogOdnosaPosto > 500) {
        this.employee.VrijemeMirovanjaRadnogOdnosaPosto = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno1Posto' () {
      if (!this.employee.Dodatno1Posto) {
        this.employee.Dodatno1Posto = 0
      }
      if (this.employee.Dodatno1Posto > 500) {
        this.employee.Dodatno1Posto = 0
      }
      this.calculateHours()
    },
    'employee.Dodatno2Posto' () {
      if (!this.employee.Dodatno2Posto) {
        this.employee.Dodatno2Posto = 0
      }
      if (this.employee.Dodatno2Posto > 500) {
        this.employee.Dodatno2Posto = 0
      }
      this.calculateHours()
    },
    'employee.FinTaxOnTaxVukovar' () {
      if (this.employee.FinTaxOnTaxVukovar) {
        this.employee.FinKoef1Desc = 'Uvećanje od 0,5% za svaku navr.god.radnog staža'
      }
      if (!this.employee.FinTaxOnTaxVukovar) {
        this.employee.FinKoef1Desc = ''
        this.employee.FinKoef1 = 0
      }
      this.calculateSal()
    }

  },
  components: {
    SalariePrintCardEmpYear
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-input--checkbox .v-label {
    font-size: 10px;
  }
</style>
