<template>
    <v-container fluid grid-list-md>
      <v-layout row>
        <v-flex xs4 sm4 md4 lg4 xl4>
          <h1>{{this.$store.state.documentActivLoc}}</h1>
        </v-flex>
       
        <v-flex xs5 sm5 md5 lg5 xl5>
            <v-btn text color="primary" class="pt-3" @click="payments()"
            >{{lang.EnterPayment}}</v-btn>
            <v-btn text color="primary" class="pt-3" @click="syncBooks()"
            v-if="this.$store.state.documentSide === 2"
            >{{lang.SyncBooks}}</v-btn>
        </v-flex>
         <v-flex xs2 sm2 md2 lg2 xl2>
          <v-select
            label="Vrsta dok."
            :items="bookType"
            v-model="bookTypeSelected"
            value="bookType.value"
            single-line
            return-object
            persistent-hint
            clearable
            dense
            ></v-select>
        </v-flex>
        <v-flex xs1 sm1 md1 lg1 xl1 class="text-xs-right">
            <v-btn fab dark fixed right class="indigo" @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
        </v-flex>
      </v-layout>

      <MainLedgerList :key="myDocumentSide" ref="init" />

      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    <v-row>
      <v-col cols="6" >
      </v-col>
      <v-col cols="2" >
        <v-file-input v-model="somefile" single label="File input"></v-file-input>
      </v-col>
      <v-col cols="2" >
      
      <v-col cols="2" >
        <v-btn text color="primary"  @click="doAgain()"
        >Upload eRacuni</v-btn>
      </v-col>
      </v-col>
      <v-col cols="2" >
        <v-btn text color="primary" @click="setshowBankOrder()"
          >Virmani</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="showImport">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <MainLedgerImportExcel :toVerify="toVerify" />
      </v-col>
    </v-row>
    <v-row v-if="showBankOrder">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <BankOrderIndex virmanFor="topay"/>
      </v-col>
    </v-row>
    <div v-if="showDialog">
      <JustWait :message="message" :showDialog="showDialog" />
    </div>

    </v-container>
</template>

<script>
import MainLedgerList from './MainLedgerList'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerImportExcel from './MainLedgerImportExcel.vue'
import PartnerService from '@/services/PartnerService'
import BankOrderIndex from '../BankOrder/BankOrderIndex'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import Api from '@/services/Api'
import {parseNum, dynamicSort, round} from '@/snippets/allSnippets'
import { defMainQuery, defNewpartner, checkBooks, defMainLedger, defNew, defMainLedgerDetail, defMainLedgerDetailFill } from './mainledgerHelper'
//mport { defMainQuery, checkPartners, defNewpartner, checkBooks, defMainLedger, defNew } from './mainledgerHelper'
import { mapGetters } from 'vuex'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'
import JustWait from '../../snippets/JustWait'
import dayjs from 'dayjs'

export default {
  name: 'mainledger',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      MainLedgerName: '',
      MainLedgerNameLoc: '',
      mainledger: {},
      documentName: '',
      documentNameLoc: '',
      error: null,
      localError: false,
      newPath: '',
      show: 1,
      lang: {},
      langC: {},
      mount: true,
      mainQuery: {
      },
      mainQuery2: {
      },
      bookType: [],
      bookTypeSelected: {
      },
      refreshKey: 0,
      somefile: null,
      mlitem: {},
      partners: [],
      account4s: [],
      newPartners: [],
      toAddPartners: [],
      firstNew: 0,
      allHeads: [],
      allItems: [],
      allItemsFinal: [],
      oldPartners: 0,
      allSavedHeads: [],
      maxDB: 0,
      minDB: 10000000000,
      showImport: false,
      toVerify: [],
      showBankOrder: false,
      message: '',
      showDialog: false
    }
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr
    }
    this.message = this.langC.JustWait
    this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
    this.partners = this.$store.state.partnerStore ? this.$store.state.partnerStore : []

    this.mainQuery = defMainQuery()
    this.MainLedgerName = this.$store.state.MainLedgerActiv
    this.MainLedgerNameLoc = this.$store.state.MainLedgerActivLoc
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.show = 1
    if (this.$store.state.documentSide === 1) {
      this.show = 1
    }
    if (this.$store.state.documentSide === 2) {
      this.show = 2
    }
    if (this.$store.state.documentSide === 3) {
      this.show = 3
    }
    if (this.$store.state.documentSide === 4) {
      this.show = 4
    }
   
    await this.$store.dispatch('setMainLedgerActiv', {})
    await this.$store.dispatch('seteditItem', 0)
    await this.$store.dispatch('setMainLedgerDetailid', null)
    await this.$store.dispatch('setPartnerid', null)
    await this.$store.dispatch('setPartnerVATID', null)
    await this.$store.dispatch('setMainLedgerItems', [])
    await this.$store.dispatch('setMainLedgerSideItem', null)
  //  await this.$store.dispatch('setbookTypeSelected', null)

    await this.setBooks()
    if (this.$store.state.pathAgain) {
      this.newPath = this.$store.state.pathAgain
      this.$store.dispatch('setpathAgain', '')
      this.$router.push({
        name: this.newPath
      })
    }
    await testLastUpdate()
  },
  methods: {
    setshowBankOrder(){
      this.showBankOrder = !this.showBankOrder
    },
    async setBooks () {
      await checkBooks(this.show)
      this.bookTypeSelected = this.$store.state.bookTypeSelected
      this.setBookTypes()
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    setBookTypes() {
      this.bookType = []
      if (this.$store.state.booktype) {
        this.bookType = this.$store.state.booktype.filter(book => {
            return book.side === this.show
          })
      }
    },
    async doAgain() {
      await this.uploadPuls('EXCEL_')
      await this.readexcel()
      this.showImport = true
    },
    async doIt() {
      await this.uploadPuls('PULS_')
      await this.readpulsml()
      await this.savePartners()
      await this.refreshAllPartners()
      await this.findLast()
      await this.prepareData()
      await this.saveAllHead()
      // await this.prepareAllItems()
      // await this.saveAllItems()
      this.$refs.init.defaultInit()
    },
    async uploadPuls(param) {
        // param 1 = 'PULS_', param 2 = 'EXCEL_'
        this.MLFree2 = ''
        this.MLFree3 = ''
        this.MLFree4 = ''
        this.MLFree5 = ''
        const newObj = {}

        this.mainQuery = {...newObj}
        if (this.somefile) {
          let fd = new FormData()
          fd.append('companyId', this.$store.state.companyid)
          fd.append('originalName', this.somefile.name)
          fd.append('typeDoc', param + parseInt(this.show))
          fd.append('myFile', this.somefile, this.somefile.name)
          fd.append('newId', this.getRndInteger(1000000000, 9000000000))
          this.somefile = null

          await Api().post('bookin/invoice', fd).then(response => {
              this.data = response.data
              console.log('res ', this.data)
              if (this.data) {
                this.mainQuery.MLFree2 = this.data.originalname ? this.data.originalname : ''
                this.mainQuery.MLFree3 = this.data.filename ? this.data.filename : ''
                this.mainQuery.MLFree4 = this.data.destination ? this.data.destination : ''
                this.mainQuery.MLFree5 = this.data.path ? this.data.path : ''
                
              }
          }).catch(error => {
              alert(this.lang.alertError1)
              if (error.response.status === 422) {
                  this.errors = error.response.data.errors || {};
              }
          });
        } else {
          this.text = 'Greska u uploadu!!'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
    },
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
          const key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }
          // Add object to list for given key's value
          acc[key].push(obj);
          return acc;
      }, {});
    },
    async findLast() {
      try {
        this.mainQuery = defMainQuery()
        const docNumber = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
        this.mainledger.BookID = docNumber
        this.firstNew = docNumber - 1
      } catch (error) {
        this.locError = 'Greska! Ne mogu pronaci zadnji broj!'
        // eslint-disable-next-line
        console.log('Error on find ID ', error)
        alert(this.locError)
      }
    },
    async createNewHead(head) {
      const newObj = {}
      this.mainledger = {...newObj}
      this.mainledger = defMainLedger()
      this.mainledger = {...this.mainledger, ...defNew()}
      this.firstNew += 1
      this.mainledger.BookID = this.firstNew
      this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + this.mainledger.BookID 
      const locPartner = this.partners.find(partner => partner.partnerVATID === head.oib)
      if (locPartner) {
        this.mainledger.partnerId = locPartner.id
        this.mainledger.partnerName = locPartner.partnerName
        this.mainledger.partnerNameShort = locPartner.partnerNameShort
        this.mainledger.partnerNameDatabase = locPartner.partnerNameDatabase
        this.mainledger.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
        this.mainledger.partnerStreet = locPartner.partnerStreet
        this.mainledger.partnerCity = locPartner.partnerCity
        this.mainledger.partnerPostcode = locPartner.partnerPostcode
        this.mainledger.partnerCountyRegion = locPartner.partnerCountyRegion
        this.mainledger.partnerState = locPartner.partnerState
        this.mainledger.partnerVATID = locPartner.partnerVATID
        this.mainledger.partneremail = locPartner.partneremail
        this.mainledger.partnerPhones = locPartner.partnerPhones
        this.mainledger.partnerBankName = locPartner.partnerBankName
        this.mainledger.partnerBankAddress = locPartner.partnerBankAddress
        this.mainledger.partnerBankIBAN = locPartner.partnerBankIBAN
        this.mainledger.partnerBankSWIFT = locPartner.partnerBankSWIFT
      }
      this.mainledger.InvoiceNumber = head.fa_broj
      this.mainledger.BookingDate = head.fa_datum
      this.mainledger.InvoiceDate = head.fa_datum
      this.mainledger.BusinessYearInvoice = head.fa_datum ? (new Date(head.fa_datum)).getFullYear() : null
      this.mainledger.InvoicePaymentDeadlineDate = head.racrokpl
      this.mainledger.DeliveryNoteNumber = head.opis
      this.mainledger.BookTypeID = parseInt(this.bookTypeSelected.value)
      this.mainledger.BookName = this.bookTypeSelected.text
     // this.mainledger.ClosedAccount = true
      if (this.show === 1) {
        this.mainledger.ConnName = 'URA'
      }
      if (this.show === 2) {
        this.mainledger.ConnName = 'IRA'
      }
      this.mainledger.ConnId = parseInt(head.urabroj)
      this.mainledger.InvoiceAmount = head.pot && head.pot !== 0 ? parseNum(head.pot, 2) : parseNum(head.dug,2)
      this.mainledger.InvoiceForeginAmount =  this.mainledger.InvoiceAmount / 7.5345
 
    },
    async saveAllHead() {
      try {
        if (this.allHeads.length > 0) {
          const {data} = (await MainLedgerService.postbulk(this.allHeads))
          if (data && data.mainledgers) {
            this.allSavedHeads = [...data.mainledgers]
          } else {
            console.log('Error on save bulk mainledgers')
          }
        }
      } catch (error) {
        this.locError = this.lang.alertError3
        // eslint-disable-next-line
        console.log('Error on save head ', error)
        alert(this.locError)
      }
    },

    async prepareAllItems() {
      try {
        this.allItemsFinal = []
        this.allItems.map(item => {
          const head = this.allSavedHeads.find(head => head.ConnId === item.ConnId)
          const newFullItem = {...item}
          newFullItem.databaseMLID = parseInt(head.id)
          newFullItem.partnerId = head.partnerId
          newFullItem.partnerName = head.partnerName
          newFullItem.partnerVATID = head.partnerVATID
          newFullItem.partnerIBAN = head.partnerIBAN
          newFullItem.partnerNameShort = head.partnerNameShort
          newFullItem.partnerNameDatabase = head.partnerNameDatabase
          newFullItem.partnerBuildingNameNumber = head.partnerBuildingNameNumber
          newFullItem.partnerStreet = head.partnerStreet
          newFullItem.partnerCity = head.partnerCity
          newFullItem.partnerPostcode = head.partnerPostcode
          newFullItem.partnerCountyRegion = head.partnerCountyRegion
          newFullItem.partnerState = head.partnerState
          newFullItem.partneremail = head.partneremail
          newFullItem.BookID = parseInt(head.BookID)
          newFullItem.BookTypeID = head.BookTypeID
          newFullItem.BookName = head.BookName
          newFullItem.documentName = head.documentName
          newFullItem.documentIdGeneral = head.documentIdGeneral
          if (this.show === 1) {
            newFullItem.invoiceInID = head.id
           // newFullItem.invoiceInNumber = head.InvoiceNumber
          }
          if (this.show === 2) {
            newFullItem.invoiceOutID = head.id
           // newFullItem.invoiceOutNumber = head.InvoiceNumber
          }
          newFullItem.BusinessYearInvoice = head.InvoiceDate ? (new Date(head.InvoiceDate)).getFullYear : 2023
          newFullItem.InvoiceNumber = head.InvoiceNumber
          newFullItem.InvoiceDate = head.InvoiceDate
          newFullItem.InvoicePaymentDeadlineDate = head.InvoicePaymentDeadlineDate
          newFullItem.BookingDate = head.BookingDate
          newFullItem.InvoiceAmount = head.InvoiceAmount
          newFullItem.InvoiceForeginAmount = head.InvoiceForeginAmount
          newFullItem.HomeCurrency = head.HomeCurrency
          newFullItem.HomeCurrencyPrimary = true
          newFullItem.ForeignCurrency = head.ForeignCurrency
          newFullItem.ForeignCurrencyPrimary = false
          newFullItem.ExRate = 7.530
          newFullItem.WareHouseId = head.WareHouseId
          this.allItemsFinal.push(newFullItem)
        })

            //   // mlitem.Debit = 0
            //   // mlitem.Credit = 0
            //   // mlitem.DebitForegin = 0
            //   // mlitem.CreditForegin = 0
            //   // mlitem.ClosedAccount = false
            //   // mlitem.partnerId = ''
            //   // mlitem.partnerName = ''
            //   // mlitem.partnerVATID = ''
            //   // mlitem.partnerIBAN = ''
            //   // mlitem.partnerNameShort = ''
            //   // mlitem.partnerNameDatabase = ''
            //   // mlitem.partnerBuildingNameNumber = ''
            //   // mlitem.partnerStreet = ''
            //   // mlitem.partnerCity = ''
            //   // mlitem.partnerPostcode = ''
            //   // mlitem.partnerCountyRegion = ''
            //   // mlitem.partnerState = ''
            //   // mlitem.partneremail = ''
            //   // mlitem.BookID = 0
            //   // mlitem.BookTypeID = 0
            //   // mlitem.BookName = ''
            //   // mlitem.Status = ''
            //   // mlitem.Locked = false
            //   // mlitem.LockedDate = null
            //   // mlitem.LockedTime = null
            //   // mlitem.LockedBy = ''
            //   // mlitem.UpdateDate = null
            //   // mlitem.UpdateTime = null
            //   // mlitem.UpdateBy = ''
            //   // mlitem.invoiceInID = 0
            //   // mlitem.invoiceOutID = 0
            //   // mlitem.invoiceInNumber = 0
            //   // mlitem.invoiceOutNumber = 0
            //   // mlitem.BusinessYearInvoice = 0
            //   // mlitem.InvoiceNumber = ''
            //   // mlitem.InvoiceDate = null
            //   // mlitem.InvoicePaymentDeadlineDate = null
            //   // mlitem.BookingDate = null
            //   // mlitem.InvoiceAmount = 0
            //   // mlitem.InvoiceForeginAmount = 0
            //   // mlitem.HomeCurrency = 'Kn'
            //   // mlitem.HomeCurrencyPrimary = true
            //   // mlitem.ForeignCurrency = 'EUR'
            //   // mlitem.ForeignCurrencyPrimary = false
            //   // mlitem.ExRate = 7.500
            //   // mlitem.WareHouseId = 0
            //   // mlitem.ConnName = ''
            //   // mlitem.ConnId = 0
            //   // mlitem.VATPer = 0
            //   // mlitem.VATBase = 0
            //   // mlitem.VATAmount = 0
            //   // mlitem.VATPerCanUse = 0
            //   // mlitem.VATCanUse = 0
            //   // mlitem.VATCanTUse = 0
 
        } catch (error) {
          this.locError = this.lang.alertError3
          // eslint-disable-next-line
          console.log('Error on save items ', error)
          alert(this.locError)
        }
    },
    async saveAllItems() {
      try{
        if (this.allItemsFinal.length > 0) {
          await MainLedgerDetailService.saveitems(this.allItemsFinal)
        }
      } catch(err) {
        alert(err)
        console.log("🚀 ~ file: MainLedgerIndex.vue ~ line 331 ~ saveAllItems ~ err", err)
      }
    },
    checkAccount4(checkAcc) {
      if (checkAcc === '220000') return '2200'
      if (checkAcc === '221000') return '2210'
      if (checkAcc === '401100') return '4011'
      if (checkAcc === '160220') return '16022' // stjecanje dobara EU 25%
      if (checkAcc === '260220') return '26022' // stjecanje dobara EU 25%
      if (checkAcc === '310000') return '3100'
      if (checkAcc === '400100') return '4001'
      if (checkAcc === '140500') return '1405' // 25% priznati
      if (checkAcc === '406100') return '4061' 
      if (checkAcc === '465000') return '4650'
      if (checkAcc === '360000') return '3600'
      if (checkAcc === '363000') return '3630'
      if (checkAcc === '412000') return '4120'
      if (checkAcc === '419900') return '4199'
      if (checkAcc === '469910') return '4699'
      if (checkAcc === '416400') return '4164'
      if (checkAcc === '401100') return '4011'
      if (checkAcc === '231000') return '2310' // racuni placeni gotovinom bruto
      if (checkAcc === '461700') return '4617'
      if (checkAcc === '400300') return '4002'
      if (checkAcc === '411100') return '4111'
      if (checkAcc === '400400') return '4004'
      if (checkAcc === '406700') return '4067'
      if (checkAcc === '407501') return '407501' // priznati gorivo bez pdva
      if (checkAcc === '407601') return '407601' // nepriznati dio gorivo sa pdvom 
      if (checkAcc === '401400') return '4014'
      if (checkAcc === '417100') return '4171'
      if (checkAcc === '413011') return '4130'
      if (checkAcc === '120000') return '1200'
      if (checkAcc === '751000') return '751001'
      if (checkAcc === '240250') return '240012' // pdv 25% izlazni
      if (checkAcc === '412501') return '4125'   // priznati usluga bez pdv
      if (checkAcc === '412601') return '4126'   // ne priznati usluga sa pdv 50%
      if (checkAcc === '419400') return '4194'
      if (checkAcc === '464200') return '4642'
      if (checkAcc === '413101') return '4131'  // nepriznati 50% sa pdvom
      if (checkAcc === '419700') return '4197'
      if (checkAcc === '463200') return '4632'
      if (checkAcc === '463201') return '4632'
      if (checkAcc === '410100') return '4101'
      if (checkAcc === '406000') return '4060'
      if (checkAcc === '465200') return '4652'
      if (checkAcc === '410200') return '4102'
    },
    async refreshAllPartners() {
      //await checkPartners(1)
      await newUpdates(2)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
    },
    savePartners() {
      if (this.newPartners.length > 0) {
        this.newPartners.map((partner) => {
          this.saveParner(partner)
          console.log('Ide slijedeci ')
        })
      }
    },
    async saveParner(partner) {
      console.log('Snimam novog ', partner)
      await PartnerService.post(partner)
      .then (async(res) => {
        console.log('New partner ', res)
        await newUpdates(2)
        await testLastUpdate()
      })
      .catch(err => {
        console.log('Error New partner ', err)
      }) 
    },
    prepareData() {
      let firstTime = true
      this.ml.map(head => {
        if (head.konto) {
          this.mlitem = defMainLedgerDetail()
          this.mlitem = {...this.mlitem, ...defMainLedgerDetailFill()}
          if ((parseInt(head.urabroj) !== this.mainledger.ConnId)  ||  this.mainledger.ConnId === undefined) {
            if (firstTime === false) {
              this.allHeads.push(this.mainledger)
              this.createNewHead(head)
            } else {
              firstTime = false
              this.createNewHead(head)
            }
          }

          if (head.konto) {
            if (head.konto.substr(0,3) === '220' || head.konto.substr(0,3) === '120' || head.konto.substr(0,3) === '221') {
              this.mainledger.InvoiceAmount = head.pot && head.pot !== 0 ? parseNum(head.pot, 2) : parseNum(head.dug,2)
              this.mainledger.InvoiceForeginAmount = this.mainledger.InvoiceAmount / 7.5345
            }
            if (head.konto.substr(0,3) === '140' || head.konto.substr(0,3) === '240') {
              const vatAm = head.pot !== 0 ? head.pot : head.dug
              this.mainledger.VAT1Base = vatAm ? vatAm / 0.25 : 0
              this.mainledger.VAT1Amount = vatAm ? (vatAm) : 0
              this.mainledger.VAT1PerCanUse = 100
              this.mainledger.VAT1CanUse = vatAm ? vatAm : 0
              //this.mainledger.VAT1CanTUse = this.mainledger.VAT1CanTUse ? (this.mainledger.VAT1CanTUse) : 0
            }
          }
        }
        
      })
      this.allHeads.push(this.mainledger)
      
    },
    async readexcel() {
      try {
        this.showDialog = true
        const {data} = await MainLedgerService.readexcel(this.mainQuery)
        this.toVerify = []

        const query = {}
        if (this.$store.state.companyid) {
          query.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          query.BusinessYear = this.$store.state.businessYear
        }
        if (this.$store.state.documentActiv) {
          query.documentName = this.$store.state.documentActiv
        }
        if (this.$store.state.bookTypeSelected) {
          query.BookTypeID = this.$store.state.bookTypeSelected.value ? this.$store.state.bookTypeSelected.value : ''
        }

        const res = await MainLedgerService.index(query)

        const allInv = res && res.data && res.data.documents ? res.data.documents : []

        // const filterItems = (needle) => {
        //      let query = null
        //      if (needle) {
        //        query = needle.toLowerCase();
        //       }
        //       return this.partners.filter(item => item.partnerName.toLowerCase().indexOf(query) >= 0);
        //     }

        this.ml = data.jObj && data.jObj.Invoice ? data.jObj.Invoice : []
        //console.log('procitana datoteka xml ml', this.ml)

        let id = 0
        if (this.ml) {
          this.ml.map(item => {

                // ----------------------------------------------------  za KUPCA 
                const aCp = item['cac:AccountingCustomerParty']
                const cParty = aCp['cac:Party']
                let cName = null
                let cName2 = null
                let partnerVATID = null
                const newObj= {}
                newObj.VAT1Amount = 0
                newObj.VAT1PerCanUse = 100
                newObj.VAT1CanUse = 0
                newObj.VAT1CanTUse = 0
                newObj.VAT1Base = 0

                newObj.VAT2Amount = 0
                newObj.VAT2PerCanUse = 100
                newObj.VAT2CanUse = 0
                newObj.VAT2CanTUse = 0
                newObj.VAT2Base = 0

                newObj.VAT3Amount = 0
                newObj.VAT3PerCanUse = 100
                newObj.VAT3CanUse = 0
                newObj.VAT3CanTUse = 0
                newObj.VAT3Base = 0
                

                const cPartyName = cParty['cac:PartyName'] ? cParty['cac:PartyName'] : null
                if (cPartyName) {
                  cName = cPartyName['cbc:Name'] ? cPartyName['cbc:Name'] : null
                }

                const cPartyName2 = cParty['cac:PartyLegalEntity']
                if (cPartyName2) {
                  partnerVATID = cPartyName2['cbc:CompanyID']
                  cName2 = cPartyName2['cbc:RegistrationName']
                }
                const partnerName = cName && cName.length > 0 ? cName : (cName2 && cName2.length > 0 ? cName2 :'')

                const cPartyTaxScheme = cParty['cac:PartyTaxScheme']
                partnerVATID = typeof cPartyTaxScheme['cbc:CompanyID'] === 'string' ? cPartyTaxScheme['cbc:CompanyID'].trim().substr(2,11) : cPartyTaxScheme['cbc:CompanyID']
                const PostalAddress = cParty['cac:PostalAddress']
                const partnerCity = PostalAddress['cbc:CityName']
                const partnerStreet = PostalAddress['cbc:StreetName']
                const partnerPostcode = PostalAddress['cbc:PostalZone']

                // ----------------------------------------------------- Dobavljac 
               // const aSp = item['cac:AccountingSupplierParty']
               //   const Party = aSp['cac:Party']
                    //const PartyName = Party['cac:PartyName'] ? Party['cac:PartyName'] : null
                    // let Name = null
                    // let Name2 = null
                    // if (PartyName) {
                    //   Name = PartyName['cbc:Name'] ? PartyName['cbc:Name'] : null
                    // }
                    // const PartyName2 = Party['cac:PartyLegalEntity']
                    // if (PartyName2) {
                    //   Name2 = PartyName2['cbc:RegistrationName']
                    // }  
                    
                    //const PartyTaxScheme = Party['cac:PartyTaxScheme']
                    //  this.mainledger.partnerVATID = typeof PartyTaxScheme['cbc:CompanyID'] === 'string' ? PartyTaxScheme['cbc:CompanyID'].trim().substr(2,11) : PartyTaxScheme['cbc:CompanyID']

                // ----------------------------------------------------- Novci
                const LegalMonetaryTotal = item['cac:LegalMonetaryTotal']

                  let haveAllAmount = LegalMonetaryTotal['cbc:PayableAmount'] ? LegalMonetaryTotal['cbc:PayableAmount'] : 0
                  if (haveAllAmount === 0) {
                    haveAllAmount = LegalMonetaryTotal['cbc:TaxInclusiveAmount'] ? LegalMonetaryTotal['cbc:TaxInclusiveAmount'] : 0
                  }
                const InvoiceAmount = haveAllAmount

                const tax = item['cac:TaxTotal']
                  const taxSubtotal = tax['cac:TaxSubtotal']
                  const haveMoreTax = Array.isArray(taxSubtotal)
                  if (haveMoreTax) {
                    taxSubtotal.map(taxM => {
                      const taxCategory = taxM['cac:TaxCategory']
                        const taxVATPer = taxCategory['cbc:Percent']
                      const TaxAmount = taxM['cbc:TaxAmount']
                      const TaxableAmount = taxM['cbc:TaxableAmount']
                      if (taxVATPer === 25) {
                        newObj.VAT1Amount = round(TaxAmount,2)
                        newObj.VAT1PerCanUse = 100
                        newObj.VAT1CanUse = round(TaxAmount,2)
                        newObj.VAT1CanTUse = 0
                        newObj.VAT1Base = round(TaxableAmount ,2)
                      }
                      if (taxVATPer === 13) {
                        newObj.VAT2Amount = round(TaxAmount,2)
                        newObj.VAT2PerCanUse = 100
                        newObj.VAT2CanUse = round(TaxAmount,2)
                        newObj.VAT2CanTUse = 0
                        newObj.VAT2Base = round(TaxableAmount ,2)
                      }
                      if (taxVATPer === 5) {
                        newObj.VAT3Amount = round(TaxAmount,2)
                        newObj.VAT3PerCanUse = 100
                        newObj.VAT3CanUse = round(TaxAmount,2)
                        newObj.VAT3CanTUse = 0
                        newObj.VAT3Base = round(TaxableAmount ,2)
                      }
                    })
                  }
                  if (!haveMoreTax) {

                    const taxCategory = taxSubtotal['cac:TaxCategory']
                      const taxVATPer = taxCategory['cbc:Percent']
                    const TaxAmount = taxSubtotal['cbc:TaxAmount']
                    const TaxableAmount = taxSubtotal['cbc:TaxableAmount']

                    if (taxVATPer === 25) {
                      newObj.VAT1Amount = round(TaxAmount,2)
                      newObj.VAT1PerCanUse = 100
                      newObj.VAT1CanUse = round(TaxAmount,2)
                      newObj.VAT1CanTUse = 0
                      newObj.VAT1Base = round(TaxableAmount ,2)
                    }
                    if (taxVATPer === 13) {
                      newObj.VAT2Amount = round(TaxAmount,2)
                      newObj.VAT2PerCanUse = 100
                      newObj.VAT2CanUse = round(TaxAmount,2)
                      newObj.VAT2CanTUse = 0
                      newObj.VAT2Base = round(TaxableAmount ,2)
                    }
                    if (taxVATPer === 5) {
                      newObj.VAT3Amount = round(TaxAmount,2)
                      newObj.VAT3PerCanUse = 100
                      newObj.VAT3CanUse = round(TaxAmount,2)
                      newObj.VAT3CanTUse = 0
                      newObj.VAT3Base = round(TaxableAmount ,2)
                    }
                  }
                   
                  //console.log('Name', Name)

                // ---------------------------------------------------------   Datumi
                const InvoiceNumber = item['cbc:ID'] ? item['cbc:ID'] : ''
                const BookingDate = item['cbc:TaxPointDate'] ? item['cbc:TaxPointDate'] : new Date()
                //const InvoiceDate = inv['cbc:IssueDate'] ? inv['cbc:IssueDate'] : new Date()
                const InvoicePaymentDeadlineDate = item['cbc:DueDate'] ? item['cbc:DueDate'] : new Date()

            id += 1
            const irabroj = InvoiceNumber ? InvoiceNumber.substr(0, InvoiceNumber.indexOf('-V1')) : InvoiceNumber
            const documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-' + this.$store.state.documentActiv + '-' + irabroj 
            
            const haveIT = allInv.find(inv => inv.documentIdGeneral === documentIdGeneral)
            if (!haveIT) {
              newObj.id = id
              newObj.irabroj = irabroj
              newObj.documentIdGeneral = documentIdGeneral
              newObj.originalBroj = InvoiceNumber
              newObj.partnerIm = partnerName
              newObj.InvoiceAmount = InvoiceAmount && InvoiceAmount !== 0 ? parseNum(InvoiceAmount, 2) : 0
              newObj.InvoiceForeginAmount = newObj.InvoiceAmount / 1

              newObj.saved = false
              newObj.partnerOK = false

              const haveFiltered = this.partners.filter(partner => partner.partnerVATID === String(partnerVATID))
              const haveP = haveFiltered && haveFiltered.length > 0 ? haveFiltered[0] : null

              newObj.partnerId = null
              newObj.partnerName = null
              if (haveP){
                newObj.partnerId =  haveP.partnerId ? haveP.partnerId : ''
                newObj.partnerName = haveP.partnerName ? haveP.partnerName : ''
                newObj.partnerOK = true
              }
              
              newObj.partnerVATID = partnerVATID
              newObj.partnerCity =  partnerCity 
              newObj.partnerStreet =  partnerStreet 
              newObj.partnerPostcode = partnerPostcode 

              newObj.fa_datum = dayjs(BookingDate).format('YYYY-MM-DD')
              newObj.racrokpl = dayjs(InvoicePaymentDeadlineDate).format('YYYY-MM-DD')
              newObj.fa_broj = InvoiceNumber ? InvoiceNumber.substring(0, InvoiceNumber.indexOf('-V1')) : InvoiceNumber

              this.toVerify.push(newObj)
            }
          })
        }
        this.showDialog = false
        console.log('imam procitani json ')
      }
      catch(err) {
        this.showDialog = false
        this.locError = 'Greska u citanju snimljene datoteke!'
        this.text = 'Error ' + err
        this.color = 'red'
        this.snackbar = true
        alert(this.locError + err)
      }
    },
    async readpulsml() {
      try {
        const {data} = await MainLedgerService.readpulsml(this.mainQuery)
          this.ml = data.allData && data.allData.items ? data.allData.items : []
          if (this.ml) {
            this.ml.sort(dynamicSort("urabroj"))
            this.ml.map(head => {
              if (head.oib && head.oib.length > 5) {
                const newObj = {}
                newObj.oib = head.oib
                newObj.firma = head.firma
                const haveIt = this.partners.find(partner => partner.partnerVATID === head.oib)
                if (haveIt) {
                  this.oldPartners += 1
                } else {
                  // already market to add in partners
                  const markedIt = this.newPartners.find(partner => partner.partnerVATID === head.oib)
                  if (!markedIt) {
                    const addeditPartner = {...defNewpartner()}
                    addeditPartner.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
                    addeditPartner.email = this.$store.state.user.email ? this.$store.state.user.email : ''
                    addeditPartner.First = this.$store.state.user.First ? this.$store.state.user.First : ''
                    addeditPartner.Last = this.$store.state.user.Last ? this.$store.state.user.Last : ''
                    addeditPartner.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
                    addeditPartner.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
                    addeditPartner.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
                    addeditPartner.partnerName = head.firma
                    addeditPartner.partnerNameShort = head.firma.replace(/[\W_]/g, '')
                    addeditPartner.partnerNameDatabase = head.firma.replace(/[\W_]/g, '')
                    addeditPartner.partnerVATID = head.oib
                    this.newPartners.push(addeditPartner)
                  }
                }
              }
            })

          }      
      } catch (error) {
        this.locError = 'Greska u citanju snimljene datoteke!'
        this.text = 'Error ' + error
        this.color = 'red'
        this.snackbar = true
        alert(this.locError)
      }
    },
    async syncBooks () {
      try {
        await MainLedgerService.syncbooks(this.mainQuery)
        this.$refs.init.defaultInit()
      } catch (err) {
        // eslint-disable-next-line
        console.log(' Error ' + err)
        this.text = 'Error!' + err
        this.color = 'red'
        this.snackbar = true
        this.timeout = 4000
      }
    },
     async newdocument () {
      try {
        if (this.bookTypeSelected) {
          this.$store.dispatch('seteditItem', 0)
          await this.$router.push({
            name: 'mainledgercreate'
          })
        } else {
          this.text = this.lang.BookTypeNotSelected
          this.color = 'red'
          this.snackbar = true
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async payments () {
      try {
        this.$store.dispatch('seteditItem', 0)
        await this.$router.push({
          name: 'mainledgerpay'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
        this.text = 'Cant open payments, error' + err.data
        this.color = 'red'
        this.snackbar = true
      }
    },
    async newBook(val) {
      this.bookTypeSelected.text = val.text
      this.bookTypeSelected.value = val.value
      await this.$store.dispatch('setbookTypeSelected', this.bookTypeSelected)
    }
  },
  computed: {
    ...mapGetters({
      myDocumentActivState: 'NeedDocumentActiv',
      myDocumentSide: 'NeedDocumentSide'
    })
  },
  watch: {
    'bookTypeSelected' (val) {
      if (val) {
        this.newBook(val)
      }
    },
    'myDocumentActivState' () {
      this.setBooks()
    }
  },
  components: {
    MainLedgerList,
    MainLedgerImportExcel,
    BankOrderIndex,
    JustWait
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
