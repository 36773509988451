<template>
 <v-container fluid class="ma-2">
   <v-row no-gutters justify="center" class="pa-0">
    <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
      <v-row>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
          <div class="headline">{{lang.ItemCreate}}</div> 
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
        <v-btn
            class="primary mr-2"
             :disabled="!formIsValid"
            @click="register">
            <span v-if="this.$store.state.language == 'EN'">
            Register item
            </span>
            <span v-if="this.$store.state.language == 'HR'">
            Zapamti artikal
            </span> 
          </v-btn>
          <v-btn
            v-if="!this.newItemAround"
            color="green"
            @click="itemlist">
            {{langC.Back}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="4" xs="12" lg="4" xl="4">
          <v-card outlined>
          <v-card-title primary class="title py-2">{{lang.BasicData}}</v-card-title>
          <v-card-text>
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemID"
              required
              :rules="[required]"
              v-model="item.itemID"
            ></v-text-field>      
          </v-row>
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemName"
              required
              :rules="[required]"
              v-model="item.itemName"
            ></v-text-field>         
          </v-row>
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemNameShort"
              required
              v-model="item.ItemNameShort"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
          <v-flex xs4 class="pr-2">
            <v-text-field
              :label="lang.ItemCode"
              v-model="item.ItemCode"
            ></v-text-field>        
          </v-flex>
          <v-flex xs8>
            <v-text-field
              :label="lang.ItemEANCode"
              v-model="item.ItemEANCode"
            ></v-text-field>
          </v-flex>
          </v-row>    

          <v-row no-gutters>
            <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="ml-0">
              {{lang.GroupId}}
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="4" lg="6" xl="6" v-if="editGroup">
                <v-autocomplete
                  :items="privGroups"
                  v-model="selectGroup"
                  label="Select group"
                  item-text="GroupName"
                  item-value="id"
                  return-object
                  persistent-hint
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" v-if="newItem">
                <v-btn text color="primary" @click="findLatestInGroup" class="mr-1">Zadnja Sifra</v-btn>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" v-if="editGroup && editItem">
                <v-btn text color="primary" @click="updateGroup" class="mr-1">Update</v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="6" xs="4" lg="6" xl="6" v-if="!editGroup">
                <v-text-field
                  :label="lang.GroupName"
                  v-model="item.GroupName"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" v-if="!editGroup">
                <v-btn text color="primary" @click="changeGroup" class="mr-1">Change</v-btn>
              </v-col>
          </v-row>
  
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemDescription"
              v-model="item.itemDescription"
            ></v-text-field>  
          </v-row>
          
          <v-row no-gutters>
            <v-flex xs3 class="pr-2">
              <v-text-field
                :label="lang.ItemUnit"
                v-model="item.itemUnit"
              ></v-text-field>
            </v-flex>
             <v-flex xs3 class="pr-2">
              <v-text-field
                :label="lang.ItemWarranty"
                align="right"
                v-model.number="item.FreeF1"
              ></v-text-field>
            </v-flex> 
            <v-flex xs3>
              <v-text-field
                :label="lang.ItemSize"
                v-model="item.ItemSize"
              ></v-text-field>        
            </v-flex>
          </v-row>
          <v-row no-gutters>
            <v-flex xs3 class="pr-2">
              <v-text-field
                :label="lang.ItemWeightMeasure"
                v-model="item.ItemWeightMeasure"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-2">
                <v-text-field
                  :label="lang.ItemWeight"
                  v-model="item.ItemWeight"
                ></v-text-field>
            </v-flex>
          </v-row>         

          <v-row no-gutters>
            <v-flex xs3 class="pr-2">
              <v-text-field
                :label="lang.ItemDimMeasure"
                v-model="item.ItemDimMeasure"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-2">
                <v-text-field
                  :label="lang.ItemDimLenght"
                  v-model="item.ItemDimLenght"
                ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-2">
                <v-text-field
                  :label="lang.ItemDimHeight"
                  v-model="item.ItemDimHeight"
                ></v-text-field>
            </v-flex>
            <v-flex xs3>
                <v-text-field
                  :label="lang.ItemDimDepth"
                  v-model="item.ItemDimDepth"
                ></v-text-field>
            </v-flex>
          </v-row>

          <v-row no-gutters>
            <v-flex xs3 class="pr-2">
              <v-text-field
                :label="lang.ItemWarehouse"
                v-model="item.ItemWarehouse"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-2">
                <v-text-field
                  :label="lang.ItemWarehouseRow"
                  v-model="item.ItemWarehouseRow"
                ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pr-2">
                <v-text-field
                  :label="lang.ItemWarehouseShelf"
                  v-model="item.ItemWarehouseShelf"
                ></v-text-field>
            </v-flex>
            <v-flex xs3>
                <v-text-field
                  :label="lang.ItemWahrehouseFloor"
                  v-model="item.ItemWahrehouseFloor"
                ></v-text-field>
            </v-flex>
          </v-row> 
                  
          <!-- <v-row no-gutters>
            <v-text-field
              :label="lang.ItemPicture1"
              v-model="item.ItemPicture1"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemPicture2"
              v-model="item.ItemPicture2"
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemPicture3"
              v-model="item.ItemPicture3"
            ></v-text-field>
          </v-row> -->
          <v-row no-gutters>
            <v-text-field
              :label="lang.ItemBelongsToId"
              v-model="item.ItemBelongsToId"
              dense
            ></v-text-field>
          </v-row>      

          <v-row no-gutters>
            <v-flex xs6 class="pr-2">
              <v-checkbox
                v-model="item.itemService"
                :label="lang.ItemService"
              ></v-checkbox>       
            </v-flex>
            <v-flex xs6>
              <v-checkbox
                v-model="item.itemWeb"
                :label="lang.ItemWeb"
              ></v-checkbox>
            </v-flex>
          </v-row>
          <v-row no-gutters>
            <v-flex xs6 class="pr-2">
              <v-checkbox
                v-model="item.itemNotActiv"
                :label="lang.ItemNotActiv"
              ></v-checkbox>       
            </v-flex>
            <v-flex xs6>
              <v-checkbox
                v-model="item.itemHideForever"
                :label="lang.ItemHideForever"
              ></v-checkbox>
            </v-flex>
          </v-row>         
       
          </v-card-text>
        </v-card>
       </v-col>

      <v-col cols="12" sm="12" md="3" xs="12" lg="3" xl="3">
       <v-card outlined>
         <v-card-title primary class="title py-2">{{lang.Price}}</v-card-title>
          <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemPrice"
                class="yellow lighten-4 text-right"
                v-model.number="item.itemPrice"
                :disabled="!fullpriceChange"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-select :label="lang.ItemHomeCurrency" :items="privitems" v-model="item.ItemHomeCurrency" value="item.ItemHomeCurrency"></v-select>
            </v-col>
          </v-row>
         
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemVAT1Percentage"
                align="right"
                v-model.number="item.itemVAT1Percentage"
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <!-- <v-text-field
                :label="lang.ItemVAT1Amount"
                align="right"
                v-model.number="item.itemVAT1Amount"
              ></v-text-field> -->
            </v-col>       
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemDirectDuty1"
                align="right"
                v-model.number="item.itemDirectDuty1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <!-- <v-text-field
                :label="lang.ItemDirectDuty2"
                align="right"
                v-model.number="item.itemDirectDuty2"
              ></v-text-field> -->
            </v-col>       
          </v-row> 
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemPriceWithVAT"
                class="yellow lighten-4 text-right"
                v-model.number="item.itemPriceWithVAT"
                :disabled="fullpriceChange"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
               <v-icon
                  @click="fullpriceSwitch"
                >
                  {{fullpriceChange ? 'mdi-pencil' : 'mdi-check'}}
                </v-icon>
              <!-- <v-btn @click="calculatePrice2">
                {{lang.CalcPrice}}
              </v-btn> -->
            </v-col> 
          </v-row>        

          <br>
          <v-card-title primary class="title py-2">{{lang.SupplierData}}</v-card-title>

          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="pr-2">
              <v-text-field
                :label="lang.ItemSuppCode"
                align="right"
                v-model.number="item.ItemSuppCode"
              ></v-text-field>
            </v-col>     
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
              <v-text-field
                :label="lang.ItemSuppPrice"
                align="right"
                v-model.number="item.itemSuppPrice"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-2">
              <v-text-field
                :label="lang.ItemSuppDiscount"
                align="right"
                v-model.number="item.itemSuppDiscount"
              ></v-text-field>
            </v-col> 
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" >
              <v-text-field
                :label="lang.ItemSuppBasePrice"
                align="right"
                v-model.number="item.itemSuppBasePrice"
              ></v-text-field>
            </v-col>     
          </v-row>         

          

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemMargin"
                align="right"
                v-model.number="item.itemMargin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemMaxDiscount"
                align="right"
                v-model.number="item.itemMaxDiscount"
              ></v-text-field>
            </v-col>       
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemDiscountAmount1"
                align="right"
                v-model.number="item.itemDiscountAmount1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemDiscountPercentage1"
                align="right"
                v-model.number="item.itemDiscountPercentage1"
              ></v-text-field>
            </v-col>       
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemDiscountAmount2"
                align="right"
                v-model.number="item.itemDiscountAmount2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemDiscountPercentage2"
                align="right"
                v-model.number="item.itemDiscountPercentage2"
              ></v-text-field>
            </v-col>       
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemDiscountAmount3"
                align="right"
                v-model.number="item.itemDiscountAmount3"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemDiscountPercentage3"
                align="right"
                v-model.number="item.itemDiscountPercentage3"
              ></v-text-field>
            </v-col>       
          </v-row>      
         </v-card-text>
       </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" xs="12" lg="3" xl="3">
      <v-card outlined>
         <v-card-title primary class="title py-2">{{lang.PriceExp}}</v-card-title>
        <v-card-text>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemForePrice"
                v-model.number="item.itemForePrice"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
              <v-select :label="lang.ItemForeignCurrency" :items="privitems" v-model="item.ItemForeignCurrency" value="item.ItemHomeCurrency"></v-select>
            </v-col>
          </v-row>
         
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemForeVAT1Percentage"
                align="right"
                v-model.number="item.itemForeVAT1Percentage"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <!-- <v-text-field
                :label="lang.ItemForeVAT1Amount"
                align="right"
                v-model.number="item.itemForeVAT1Amount"
              ></v-text-field> -->
            </v-col>       
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemForeDirectDuty1"
                align="right"
                v-model.number="item.itemForeDirectDuty1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemForeDirectDuty2"
                align="right"
                v-model.number="item.itemForeDirectDuty2"
              ></v-text-field>
            </v-col>       
          </v-row> 
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-2">
              <v-text-field
                :label="lang.ItemForePriceWithVAT"
                v-model.number="item.itemForePriceWithVAT"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
              <v-text-field
                :label="lang.ItemExRate"
                align="right"
                v-model.number="item.ItemExRate"
              ></v-text-field>
            </v-col> 
          </v-row> 

          </v-card-text>
        </v-card>
      </v-col>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar>
          
      </v-row>
    </v-col>
   </v-row>
 </v-container>
</template>

<script>

//import GroupService from '@/services/GroupService'
import ItemService from '@/services/ItemService'
import DocumentDetailService from '@/services/DocumentDetailService'
import langEn from './itemDescEn'
import langHr from './itemDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'

export default {
  name: 'itemcreate',
  $validates: true,
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      lang: {},
      langC: {},
      menu: false,
      modal: false,
      msg: '',
      valid: true,
      fullpriceChange: true,
      samoZaProbu: 0,
      item: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        GroupId: 0,
        GroupName: '',
        itemID: '',
        itemName: '',
        ItemNameShort: '',
        ItemCode: '',
        ItemEANCode: '',
        itemGroup: '',
        itemDescription: '',
        itemUnit: '',
        ItemSize: '',
        ItemWeight: 0,
        ItemWeightMeasure: '',
        ItemDimMeasure: '',
        ItemDimLenght: 0,
        ItemDimHeight: 0,
        ItemDimDepth: 0,
        ItemWarehouse: '',
        ItemWarehouseRow: '',
        ItemWarehouseShelf: '',
        ItemWahrehouseFloor: '',
        ItemPicture1: '',
        ItemPicture2: '',
        ItemPicture3: '',
        ItemPicture4: '',
        ItemPicture5: '',
        ItemPicture6: '',
        ItemPicture7: '',
        ItemBelongsToId: 0,
        itemService: false,
        itemWeb: false,
        itemNotActiv: false,
        itemHideForever: false,
        ItemHomeCurrency: 'Kn',
        ItemForeignCurrency: 'EUR',
        ItemExRate: 0,
        itemSuppPrice: 0,
        itemSuppBasePrice: 0,
        itemSuppDiscount: 0,
        ItemSuppCode: '',
        ItemSupppartnerName : '',
        ItemSupppartnerID: 0,
        ItemSupppartnerVATID: '',
        ItemSuppDate : null,
        ItemSuppDoc : '',
        ItemSuppDocNr : '',
        itemMargin: 0,
        itemMaxDiscount: 0,
        itemPrice: 0,
        itemVAT1Percentage: 25,
        itemVAT1Amount: 0,
        itemVAT2Percentage: 0,
        itemVAT2Amount: 0,
        itemDirectDuty1: 0,
        itemDirectDuty2: 0,
        itemPriceWithVAT: 0,
        itemForePrice: 0,
        itemForeVAT1Percentage: 0,
        itemForeVAT1Amount: 0,
        itemForeVAT2Percentage: 0,
        itemForeVAT2Amount: 0,
        itemForeDirectDuty1: 0,
        itemForeDirectDuty2: 0,
        itemForePriceWithVAT: 0,
        itemDiscountAmount1: 0,
        itemDiscountPercentage1: 0,
        itemDiscountAmount2: 0,
        itemDiscountPercentage2: 0,
        itemDiscountAmount3: 0,
        itemDiscountPercentage4: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      error: null,
      localError: false,
      privitems: [
        { text: 'EUR', value: 'EUR' },
        { text: 'Kn', value: 'Kn' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' }
      ],
      privGroups: [],
      selectGroup: {},
      mainQuery: {
        CompanyId: 1
      },
      required: (value) => !!value || 'Required.',
      calcNoVAT: false,
      calcVAT: false,
      itemsLoc: [],
      haveItem: {},
      newItemAround: false,
      editGroup: true,
      origGroup: null,
      editItem: false,
      resData: {},
      newItem: false
    }
  },
  components: {
  },
  async mounted () {
    try {
      if (this.$store.state.language === 'EN') {
        this.lang = langEn
        this.langC = commEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      if (this.$store.state.companyid) {
        this.item.CompanyId = await this.$store.state.companyid
        this.mainQuery.CompanyId = await this.$store.state.companyid
      }
      if (this.$store.state.groupStore) {
        //this.privGroups = (await GroupService.choose(this.mainQuery)).data.groups
        this.privGroups = this.$store.state.groupStore
      }
      if (this.$store.state.editItem === 0) {
        this.newItem = true
        this.item.ItemHomeCurrency = this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR'
        this.item.ItemForeignCurrency = 'EUR'
        this.item.ItemExRate = this.$store.getters.NeedDefExRate
      }
      if (this.$store.state.editItem === 1) {
        const dbItem = await ItemService.show(this.$store.state.itemid)
        this.item = dbItem.data.item
        this.origGroup = this.item.GroupId ? this.item.GroupId : null
        this.editGroup = false
        this.editItem = true
      }
      this.newItemAround = this.$store.state.newItemAround ? this.$store.state.newItemAround : false

    } catch (error) {
      // eslint-disable-next-line
      console.log('error ', error)
    }
  },
  computed: {
    formIsValid () {
      return this.item.GroupId !== '' &&
        this.item.itemID !== '' &&
        this.item.itemName !== ''
    }
  },
  methods: {
    changeGroup() {
      this.editGroup = true
    },
    fullpriceSwitch() {
      this.fullpriceChange = !this.fullpriceChange

      if (this.item.itemPriceWithVAT !== 0 && this.fullpriceChange) {
        const itemNoDuty = this.item.itemPriceWithVAT - this.item.itemDirectDuty1 - this.item.itemDirectDuty2      
        this.item.itemPrice = parseFloat((itemNoDuty / (1 + (this.item.itemVAT1Percentage / 100))).toFixed(2))
      } 
    },
    async findLatestInGroup() {
      let latestID = 0
      const allItems = await ItemService.index(this.mainQuery)
      if (allItems && allItems.data && !allItems.data.errors) {
        if (this.selectGroup && this.selectGroup.id) {
          const groupItems = allItems.data.items.filter(group => group.GroupId === this.selectGroup.id)
          if (groupItems) {
            groupItems.map(gitem => {
              if (parseInt(gitem.itemID) > latestID) {
                latestID = parseInt(gitem.itemID)
              }
            })
          }
        }
        if (!this.selectGroup.id && !allItems.data.errors) {
          allItems.data.items.map(gitem => {
            if (parseInt(gitem.itemID) > latestID) {
              latestID = parseInt(gitem.itemID)
            }
          })
        }
      }
      latestID += 1
      this.item.itemID = latestID
    },
    async updateGroup() {
      this.editGroup = false
      // update documents to new group
      this.mainQuery.CompanyId = this.$store.state.companyid
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      if (this.selectGroup.id) {
        this.mainQuery.GroupId = this.selectGroup.id
        this.mainQuery.itemGroup = this.selectGroup.GroupName
        this.item.GroupId = this.selectGroup.id
        this.item.GroupName = this.selectGroup.GroupName
      }
      if (this.item.itemID) {
        this.mainQuery.itemID = this.item.itemID
      }
      if (this.$store.state.editItem === 1 && this.origGroup) {
        const newGroupUp = {}
        newGroupUp.id = this.item.id
        newGroupUp.GroupId = this.selectGroup.id
        newGroupUp.GroupName = this.selectGroup.GroupName
        await ItemService.put(newGroupUp).then(async(res)=>{
          this.resData = res.data
          await DocumentDetailService.updateitems(this.mainQuery)
          .then(res => {
            this.haveItem = res.item
            this.text = 'OK! Group is updated'
            this.color = 'green'
            this.snackbar = true
          })
          .catch(err => {
            this.error = err
            this.localError = false
            this.text = 'Error!' + err
            this.color = 'red'
            this.snackbar = true
          })
        })
        .catch(err=> {
          this.error = err
          this.localError = false
          this.text = 'Error!' + err
          this.color = 'red'
          this.snackbar = true
        })
        this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
        if (this.itemsLoc) {
          this.$store.dispatch('setActivItems', this.itemsLoc)
        }
      }
    },
    async register () {
      try {
        if (this.$store.state.user.id) {
          this.item.UserId = this.$store.state.user.id
        }
        if (this.$store.state.user.email) {
          this.item.email = this.$store.state.user.email
          this.item.LastUpdateBy = this.$store.state.user.email
        }
        if (this.$store.state.companyid) {
          this.item.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.companyName) {
          this.item.CompanyNameDatabase = this.$store.state.companyName
        }
        this.item.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020

        if (this.selectGroup.id) {
          this.item.GroupId = this.selectGroup.id
          this.item.itemGroup = this.selectGroup.id
        }
        if (this.selectGroup.GroupName) {
          this.item.GroupName = this.selectGroup.GroupName
        }

        this.localError = false
        if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = await this.$store.state.companyid
        }
        if (this.item.itemID) {
          this.mainQuery.itemID = this.item.itemID
        }
        this.localError = true
        if (this.$store.state.editItem === 0) {
            await ItemService.choose(this.mainQuery)            
              .then(res => {
                this.haveItem = res.item
                this.localError = true
                this.text = 'ERROR! Item ID already exist!! Sifra vec postoji!!'
                this.color = 'red'
                this.snackbar = true
                return
              })
              .catch(err => {
                this.error = err.data
                this.localError = false
              })
        }
        if (this.localError === false && this.$store.state.editItem === 0) {
          await ItemService.post(this.item)
          this.text = this.lang.Saved
          this.color = 'green'
          this.snackbar = true
          await newUpdates(1)
          await testLastUpdate()

        }
        if (this.$store.state.editItem === 1) {
          await ItemService.put(this.item)
          this.text = this.lang.Saved
          this.color = 'green'
          this.snackbar = true
          await newUpdates(1)
          await testLastUpdate()
        }
        
        // this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
        // if (this.itemsLoc) {
        //   this.$store.dispatch('setActivItems', this.itemsLoc)
        // }
        if (this.newItemAround) {
          this.$store.dispatch('setSelectedItemId', this.item.itemID)
        }
        // this.$router.push({
        //   name: 'item'
        // })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    async itemlist () {
      try {
        if (this.$store.state.editItem === 1) {
          this.$store.dispatch('seteditItem', 0)
        }
        await this.$router.push({
          name: 'item'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    initValue () {
      this.item.itemPrice = this.item.itemPrice ? parseFloat(this.item.itemPrice) : 0
      this.item.itemVAT1Percentage = this.item.itemVAT1Percentage ? parseFloat(this.item.itemVAT1Percentage) : 0
      this.item.itemDirectDuty1 = this.item.itemDirectDuty1 ? parseFloat(this.item.itemDirectDuty1) : 0
      this.item.itemDirectDuty2 = this.item.itemDirectDuty2 ? parseFloat(this.item.itemDirectDuty2) : 0
      this.item.itemPriceWithVAT = this.item.itemPriceWithVAT ? parseFloat(this.item.itemPriceWithVAT) : 0
    },
    calculatePrice () {
      this.initValue()
      this.item.itemPriceWithVAT = this.item.itemPrice + this.item.itemDirectDuty1 + this.item.itemDirectDuty2 + (this.item.itemPrice * (this.item.itemVAT1Percentage / 100))
      this.item.itemPriceWithVAT = this.item.itemPriceWithVAT ? parseFloat((this.item.itemPriceWithVAT).toFixed(2)) : 0
    },
    calculatePrice2 () {
      this.initValue()
      if (this.item.itemPriceWithVAT !== 0) {
        const itemNoDuty = this.item.itemPriceWithVAT - this.item.itemDirectDuty1 - this.item.itemDirectDuty2      
        this.item.itemPrice = parseFloat((itemNoDuty / (1 + (this.item.itemVAT1Percentage / 100))).toFixed(2))
      }      
    }
  },
  watch: {
    'item.itemPrice' () {
      this.calculatePrice()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
