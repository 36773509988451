import store from '../../store/store'

const defOpzstathead = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''

  defObj.DatumOd = null
  defObj.DatumDo = null
  defObj.OIB = ''
  defObj.VrstaIzvjesca = 0
  defObj.Naziv = ''
  defObj.Mjesto = ''
  defObj.Ulica = ''
  defObj.Broj = ''
  defObj.Email = ''
  defObj.Ime = ''
  defObj.Prezime = ''
  defObj.Telefon = ''
  defObj.Fax = ''
  defObj.Sastavioemail = ''
  defObj.NaDan = null
  defObj.NisuNaplaceniDo = null

  defObj.UkupanIznosRacunaObrasca = 0
  defObj.UkupanIznosPdvObrasca = 0
  defObj.UkupanIznosRacunaSPdvObrasca = 0
  defObj.UkupniPlaceniIznosRacunaObrasca = 0
  defObj.NeplaceniIznosRacunaObrasca = 0
  defObj.OPZUkupanIznosRacunaSPdv = 0
  defObj.OPZUkupanIznosPdv = 0

  defObj.DateOpen = null
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = ''
  defObj.FreeF8 = ''
  defObj.FreeF9 = ''
  defObj.FreeF10 = ''
  defObj.FreeF11 = ''
  defObj.FreeF12 = ''
  defObj.Activ= false
  defObj.Black= false
  defObj.LastUpdateBy = ''
  return defObj
}

const defNewOpzstathead = () => {
  const defNew = {}
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 0
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''

  defNew.VrstaIzvjesca = 1
  defNew.Naziv = store.state.companyActiv.CompanyName ? store.state.companyActiv.CompanyName : ''
  defNew.Mjesto = (store.state.companyActiv.CompanyPostcode ? store.state.companyActiv.CompanyPostcode : '') +(store.state.companyActiv.CompanyCity ? store.state.companyActiv.CompanyCity : '')
  defNew.Ulica = store.state.companyActiv.CompanyStreet ? store.state.companyActiv.CompanyStreet : ''
  defNew.Broj = store.state.companyActiv.CompanyBuildingNameNumber ? store.state.companyActiv.CompanyBuildingNameNumber : ''
  defNew.Email = store.state.companyActiv.Companyemail ? store.state.companyActiv.Companyemail : ''
  defNew.OIB = store.state.companyActiv.CompanyVATID ? store.state.companyActiv.CompanyVATID : ''

  defNew.Sastavioemail = store.state.user.email ? store.state.user.email : ''
  defNew.Ime = store.state.user.First ? store.state.user.First : ''
  defNew.Prezime = store.state.user.Last ? store.state.user.Last : ''
  defNew.DatumOd = new Date('2022-01-01')
  defNew.DatumDo = new Date('2022-12-31')
  defNew.NaDan = new Date('2022-12-31')
  defNew.NisuNaplaceniDo = new Date('2022-12-31')

  return defNew
}

const defOpzstatitems = () => {
  const defObj = {}
  defObj.OpzstatheadID = 0
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EvidenceType = 0
  defObj.hashtag = ''
  defObj.eemail = ''
  defObj.phone = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.Gender = ''
  defObj.VatID = ''

  defObj.K1 = 0
  defObj.K2 = 0
  defObj.K3 = ''
  defObj.K4 = ''
  defObj.K5 = 0
  defObj.K6 = 0
  defObj.K7 = 0
  defObj.K8 = 0
  defObj.K9 = 0

  defObj.R1 = 0
  defObj.R2 = ''
  defObj.R3 = null
  defObj.R4 = null
  defObj.R5 = 0
  defObj.R6 = 0
  defObj.R7 = 0
  defObj.R8 = 0
  defObj.R9 = 0
  defObj.R10 = 0

  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = ''
  defObj.FreeF8 = ''
  defObj.FreeF9 = ''
  defObj.FreeF10 = ''
  defObj.FreeF11 = ''
  defObj.FreeF12 = ''
  defObj.Activ = false
  defObj.Black = false
  defObj.EmployeeID1 = ''
  defObj.EmployeeID2 = ''
  defObj.EmployeeID3 = ''
  defObj.EmployeeID4 = ''
  defObj.originalname = ''
  defObj.filename = ''
  defObj.destination = ''
  defObj.path = ''
  defObj.typeDoc = ''
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  return defObj
}

const defNewOpzstatitems = () => {
  const defNew = {}
  defNew.JoppdAID = store.state.documentid  ? store.state.documentid  : 0
  defNew.CompanyId = store.state.companyid ? store.state.companyid : 0
  defNew.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defNew.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defNew.UserId = store.state.user.id ? store.state.user.id : ''
  defNew.email = store.state.user.email ? store.state.user.email : ''
  defNew.First = store.state.user.First ? store.state.user.First : ''
  defNew.Last = store.state.user.Last ? store.state.user.Last : ''
  defNew.EvidenceType = 1
  defNew.OpzstatheadID = store.state.documentid
  defNew.Order = store.state.documentActivHead.Order
  defNew.Month = store.state.documentActivHead.Month
  defNew.Year = store.state.documentActivHead.Year
  return defNew
}

export {
  defOpzstathead,
  defNewOpzstathead,
  defOpzstatitems,
  defNewOpzstatitems,
}