<template>

  <v-container fluid grid-list-md >
    <v-layout row>
      <v-flex xs6 sm6 md6 lg6 xl6>
        <h1>{{this.$store.state.documentActivLoc}}</h1>
      </v-flex>
      <v-flex xs5 sm5 md65 lg5 xl5 class="text-right">
        <span v-if="showCreateInv"><v-btn text @click="createInv1">{{lang.createInv}}</v-btn></span>
        <v-btn text @click="warehouseSelected">{{currWh}}</v-btn>
        <span v-if="$store.state.documentActiv !== 'ServiceNotes'">
          <v-btn text @click="openStatus">{{lang.documentTypesHeader}}</v-btn>
        </span>
      </v-flex>
      <v-flex xs1 sm1 md1 lg1 xl1 class="text-right">
          <v-btn fab dark fixed right class="indigo"  @click="newdocument">
              <v-icon dark>mdi-plus</v-icon>
          </v-btn>
      </v-flex>
    </v-layout>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <Documentlist :key="key" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-btn color="primary" @click="digitalDoc" class="mr-2 mb-4" >
            {{lang.DigitalAr}}
          </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="showDigitalDoc">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <DigitalDocFullList />
      </v-col>
    </v-row>
    <v-row>

    </v-row>
    <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="timeout"
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    <div v-if="showWarehouse">
      <WareHouseSelect v-on:closedDialog="closedDialog2"/>
    </div>
    <div v-if="showCreateInvModal">
      <v-dialog
        v-model="dialog"
        width="auto"
      >
        <v-card>
          <v-card-title class="headline">{{lang.createInv2}}</v-card-title>
          <v-card-text>
             <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                {{lang.DocumentDate}}: <input type="date" v-model="inv_documentDate" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" >
                {{lang.documentTime}}: <input type="time" v-model="inv_documentTime" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                {{lang.StartDate}}: <input type="date" v-model="inv_documentInvoiceStartDate" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                {{lang.DeadLine}}: <input type="date" v-model="inv_documentInvoiceEndDate" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field class="pr-3"
                  label="Dodatak opisu:"
                  v-model="inv_Description"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                <v-btn color="primary"  @click="dialogSt">Kreiraj</v-btn>
              </v-col>
               <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
                ></v-progress-circular>
              <v-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                <v-btn color="red"  @click="createInv1">{{langC.Cancel}}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
               <v-progress-linear v-if="this.saving"
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Documentlist from './DocumentList'
import DigitalDocFullList from '@/components/DigitalDoc/DigitalDocFullList'
import WareHouseSelect from '../WareHouse/WareHouseSelect'
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
// import DocumentTest2D from './DocumentTest2D'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

export default {
  name: 'document',
  data () {
    return {
      saving: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      dialog: false,
      msg: '',
      documentName: '',
      documentNameLoc: '',
      routeto: 1,
      lang: {},
      langC: {},
      showDigitalDoc: false,
      showWarehouse: false,
      currWh: '',
      localWH: 1,
      key: 1,
      showCreateInv: false,
      showCreateInvModal: false,
      inv_documentDate: null,
      inv_documentTime: null,
      inv_documentInvoiceStartDate: null,
      inv_documentInvoiceEndDate: null,
      inv_Description: '',
      banks: [],
      mainQuery: {},
      UUID: null,
      QRcodeLink: null
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }

    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.documentActiv === 'WorkingOrder') {
      this.routeto = 2
    }
    if (this.$store.state.documentActiv === 'ContractSales') {
      this.showCreateInv = true
    }
    this.localWH  = this.$store.state.warehousemarkid ? 'Akt.skl. ' + this.$store.state.warehousemarkid : 1
    this.currWh = this.localWH
    this.inv_documentDate = dayjs(new Date()).format('YYYY-MM-DD')
    this.inv_documentTime = dayjs(new Date()).format('HH:mm')
    this.inv_documentInvoiceStartDate = dayjs(new Date()).format('YYYY-MM-DD')
    this.inv_documentInvoiceEndDate = dayjs(new Date()).add(15, 'days').format('YYYY-MM-DD')
  },
  methods: {
    createInv1() {
      this.showCreateInvModal = !this.showCreateInvModal
      this.dialog = !this.dialog
    },
    warehouseSelected() {
      this.showWarehouse = !this.showWarehouse
    },
    closedDialog2() {
      this.showWarehouse = false
    },
    async dialogSt() {
      this.QRcodeLink = null
      this.QRcodeLink = uuidv4()
      const emptArr = []
      const newHeads = [...emptArr]
      const newDetails = [...emptArr]

      this.mainQuery.CompanyId = await this.$store.state.companyid
      // last NR
      this.mainQuery.documentName = 'InvoiceSales'
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
      let lastNr = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)

      // doc details
      delete this.mainQuery.BusinessYear
      this.mainQuery.documentName = 'ContractSales'
      const docDetails = (await DocumentDetailService.alldocdetails(this.mainQuery)).data.docdetails

      this.saving = true
      await DocumentService.docsall(this.mainQuery).then(async(res) => {
        const heads = res.data.documents ? res.data.documents : []

        if (!heads) {
          this.dialog = false
          this.text = 'Error! List of contract are empty! Greška! Nema ugovora za kreiranje računa!'
          this.color = 'red'
          this.snackbar = true
          this.timeout = 4000
        }
        if (heads) {

          heads.map(head => {
            if (head.validContract) {
              const nI = {...head}
              delete nI.id
              nI.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              nI.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
              nI.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
              nI.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
              nI.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
              nI.documentName = 'InvoiceSales'
              nI.documentAnalysis = 1
              nI.documentSide = 3
              nI.documentDescription5 = this.$store.state.companyActiv.CompanyInvoiceMemo
              nI.documentDate = this.inv_documentDate
              nI.documentTime = this.inv_documentTime
              nI.documentInvoiceStartDate = this.inv_documentInvoiceStartDate
              nI.documentInvoiceEndDate = this.inv_documentInvoiceEndDate

              nI.documentId = lastNr
              nI.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-InvoiceSales-' + lastNr + '-' + (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)

              // uuid
              this.UUID = uuidv4()
              nI.UUID = this.UUID
              nI.QRcodeLink = this.QRcodeLink
              newHeads.push(nI)
              lastNr += 1

              const filDet = docDetails.filter(items => items.databaseDocID === parseInt(head.id))
              if (filDet) {

                filDet.map(itemD => {
                  const nd = {...itemD}
                  delete nd.id
                  delete nd.databaseDocID
                  nd.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2023
                  nd.documentName = 'InvoiceSales'
                  nd.documentAnalysis = 1
                  nd.documentSide = 3
                  nd.UUID = this.UUID
                  newDetails.push(nd)
                })
              }
            }
          })


          // if newHeads bulkcreate Heads route
          if (newHeads && newHeads.length > 0) {
            await DocumentService.saveinvoices(newHeads)
                .then(async(response) => {
                  const query2 = {}
                  query2.CompanyId = this.$store.state.companyid
                  query2.QRcodeLink = this.QRcodeLink
                  //const savedHeads = (await DocumentService.choose(query2)).data.documents

                  await DocumentService.findallbyuuid(query2)
                  .then(async(res3) => {
                    const savedHeads = res3 && res3.data && res3.data.documents ? res3.data.documents : []

                    if (savedHeads) {
                      newDetails.map(detail => {
                        const newHead = savedHeads.find(head => head.UUID === detail.UUID)
                        if (newHead) {
                          detail.databaseDocID = newHead.id
                          detail.documentId = parseInt(newHead.documentId)
                          detail.documentIdGeneral = newHead.documentIdGeneral
                          detail.itemName = detail.itemName + (this.inv_Description ? ' ' + this.inv_Description : '')
                        }
                      })
                    }
                    await DocumentDetailService.saveallitems(newDetails)
                  })
                  .catch(err2 => {
                    console.log('err2', err2)
                  })


                  this.dialog = false
                  this.text = this.lang.DocumentSavedOK + response.statusText
                  this.color = 'green'
                  this.snackbar = true
                  this.timeout = 2000
                })
                .catch(err => {
                  this.dialog = false
                  this.text = 'Error post! Greška u snimanju!' + err
                  this.color = 'red'
                  this.snackbar = true
                  this.timeout = 4000
                })
          }
        }

      })
      .catch(err => {
        this.havealert = !this.havealert
        this.havemssgalert = 'Error' + err
      })

      this.saving = false

    },
    async newdocument () {
      try {
        this.$store.dispatch('setDocumentActivHead', {})
        this.$store.dispatch('setDocumentActivItems', [])
        if (this.$store.state.user && this.$store.state.user.vehicles && this.$store.state.user.vehicles === true && this.$store.state.documentActiv === 'ServiceNotes') {
          await this.$router.push({
            name: 'documentcreateservice'
          })
        } else {
          await this.$router.push({
            name: 'documentcreate'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    openStatus() {
      this.$router.push({name: 'documentstatus'})
    },
    digitalDoc() {
      this.showDigitalDoc = !this.showDigitalDoc
    },
    newWH() {
      this.currWh  = this.$store.state.warehousemarkid ? 'Akt.skl. ' + this.$store.state.warehousemarkid : 1
      const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
      this.showWarehouse = false
      if (side === 1 || side === 2) {
        this.key += 1
      }
    }
  },
  computed: {
    ...mapGetters({
      wsmarkId: 'NeedWareHouseMarkId',
    }),
  },
  watch: {
    'wsmarkId' () {
      this.newWH()
    },
  },
  components: {
    Documentlist,
    DigitalDocFullList,
    WareHouseSelect
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
